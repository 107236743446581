import { EN_LANG, TEXT_TRIM_REGEX } from 'constants';
import { isEmpty, isString } from 'lodash';

export const objectUtils = {
  isObject: (obj) => typeof obj === 'object',
  isObjectEmpty: (obj) => {
    return obj && Object.keys(obj)?.length ? false : true;
  },
  deletePropIsFalsy: (obj) => {
    for (let key in obj) {
      if (!obj[key]) {
        delete obj[key];
      }
    }

    return obj;
  },
  convertToKeyValue: (value) => ({
    key: value,
    value: value,
  }),
  convertToArray: (obj) => Object.entries(obj),
};

export const arrayUtils = {
  isArrayEmpty: (arr) => {
    return Array.isArray(arr) && arr?.length ? false : true;
  },
  isArrayNumber: (array) => {
    if (Array.isArray(array)) {
      const elementIsNaN = array.find((e) => !numberUtils.isNumber(e));

      if (!elementIsNaN) return true;

      return false;
    }

    return false;
  },
  // REMOVE/DELETE
  deleteItem: ({ array, item }) => {
    const pos = arrayUtils.getItemPosition({ array, item });
    if (pos > -1) {
      return [...array.slice(0, pos), ...array.slice(pos + 1)];
    }
    return array;
  },
  removeElement(arr, value) {
    return arr.filter((v) => v !== value);
  },
  removeItemInArrayObject: (array, value, prop) => {
    const removeIndex = array.findIndex((item) => item[prop] === value);

    return [...array.slice(0, removeIndex), ...array.slice(removeIndex + 1)];
  },
  removeDuplicateInArrayObject: (arr, prop) => {
    const distinct = function (arr, selector) {
      if (selector === 'undefined') {
        return [...new Set(this)];
      }

      let found = new Set();
      return arr?.filter((element) => {
        if (found.has(selector(element))) {
          return false;
        } else {
          found.add(selector(element));
          return true;
        }
      });
    };

    return distinct(arr, (obj) => obj[prop]);
  },
  // REPLACE
  replaceItem: ({ array, item, prop }) => {
    const pos = arrayUtils.getItemPosition({ array, item, prop });
    if (pos > -1) {
      return [...array.slice(0, pos), item, ...array.slice(pos + 1)];
    }
    return [...array, item];
  },
  replaceItemInArray: (array, item, pos) => {
    return [...array.slice(0, pos), item, ...array.slice(pos + 1)];
  },
  replaceItemInArrayObject: ({ array, item, prop, subProp1 }) => {
    const getPos = () => {
      if (!prop) return array.findIndex((i) => i === item);
      if (!subProp1) return array.findIndex((i) => i?.[prop] === item?.[prop]);
      return array.findIndex(
        (i) => i?.[prop]?.[subProp1] === item?.[prop]?.[subProp1]
      );
    };
    const pos = getPos();
    if (pos > -1) {
      return [...array.slice(0, pos), item, ...array.slice(pos + 1)];
    }
    return [...array, item];
  },
  // UTILS
  chunk: (arr, length) =>
    arr.reduce(
      (c, n) => {
        c[c.length - 1].length >= length
          ? c.push([n])
          : c[c.length - 1].push(n);
        return c;
      },
      [[]]
    ),
  otherThanNull: (array) => {
    return array?.some((el) => el !== null);
  },
  max: (arrayNumber) => {
    Array.prototype.max = function () {
      return Math.max.apply(null, this);
    };

    Array.prototype.min = function () {
      return Math.min.apply(null, this);
    };

    if (arrayUtils.isArrayNumber(arrayNumber)) return arrayNumber.max();

    return undefined;
  },
  filterFromAnotherArrayByProp: (rootArr, arr, prop) =>
    rootArr.filter((ra) => {
      const itemArrExistInRootArr = arr.find((a) => a[prop] === ra[prop]);

      return !itemArrExistInRootArr;
    }),
  reverse: (array) => array.reverse(),
  sortArrayObject: (array, prop, type = 1) => {
    if (type) return array.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
    return arr.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
  },
  getItemPosition: ({ array, item, prop }) => {
    if (isEmpty(prop))
      return array.findIndex((i) => JSON.stringify(i) === JSON.stringify(item));
    return array.findIndex(
      (i) => JSON.stringify(i[prop]) === JSON.stringify(item[prop])
    );
  },
};

export const stringUtils = {
  isString: (fn) => typeof fn === 'string',
  capitalize: (string) => {
    const stringLowerCase = string.toLowerCase();

    return stringLowerCase.charAt(0).toUpperCase() + stringLowerCase.slice(1);
  },
  replaceKeyword: (string, arrayKeyValue, keyPrefix = '$') => {
    let newString = string;

    arrayKeyValue.forEach(
      ({ key, value }) =>
        (newString = newString.replaceAll(`${keyPrefix}${key}`, value))
    );

    return newString;
  },
  getFirstIntegers: (string) => {
    if (!string) return '';

    const firstIntegers = string.replace(/(^\d+)(.+$)/i, '$1');

    if (numberUtils.isNumber(firstIntegers)) return firstIntegers;

    return '';
  },
  replaceAllSpace: (string, char = '') => {
    if (!string) return '';

    return string.replaceAll(' ', char);
  },
  replaceAllCharacter: (string) => {
    if (!string) return '';

    return string.replaceAll(/[- (),+/*.#]/g, '')?.toUpperCase();
  },
  generateRandomString: (length = 163) => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';

    // Create an array of 32-bit unsigned integers
    const randomValues = new Uint32Array(length);

    // Generate random values
    window.crypto.getRandomValues(randomValues);
    randomValues.forEach((value) => {
      result += characters.charAt(value % charactersLength);
    });

    return result;
  },
  replaceBreakLine: (string) =>
    stringUtils.isString(string) ? string.replaceAll(/\\n/g, '\n') : '',
  trimAll: (string) => {
    if (string) {
      const stringSplit = string.match(TEXT_TRIM_REGEX);
      const stringsFilterd = stringSplit.filter((str) => str);
      const stringTransform = stringsFilterd.join(' ');

      return stringTransform;
    }

    return string;
  },
  stringTransform: (string) => {
    if (isString(string)) return stringUtils.replaceBreakLine(string);
    return string;
  },
};

export const domUtils = {
  isReady: typeof window !== 'undefined',
  addDocumentTitle: (title) => {
    document.title = title;
  },
  addHeadRelLink: (relName, href) => {
    let link = document.querySelector(`link[rel~='${relName}']`);

    if (!link) {
      link = document.createElement('link');
      link.rel = `${relName}`;
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    link.href = href;
  },
  moveCursorToEnd: (name, keyAllows) => {
    const handleCursor = () => {
      const ele = document.getElementsByName(name)[0];

      if (ele && ele.nodeName === 'INPUT') {
        const timer = setTimeout(function () {
          ele.selectionStart = ele.selectionEnd = ele.value.length;

          ele.focus();

          clearTimeout(timer);
        }, 0);
      }
    };

    document.addEventListener('keydown', (e) => {
      if (keyAllows?.includes(e.which)) return;

      handleCursor();
    });
  },
  getQueryWithName: (name) => {
    const search = window.location.search || window.parent.location.search;

    const query = new URLSearchParams(search);

    return query.get(name) || EN_LANG;
  },
  changeAddressBarUrlWithoutReloadPage: ({ prefix = '' }) => {
    const newurl =
      window.location.protocol + '//' + window.location.host + prefix;

    window.history.pushState({ path: newurl }, '', newurl);
  },
  replaceUrlWithoutReload: ({ newPageTitle = null, path = '' }) => {
    window.history.replaceState(null, newPageTitle, path);
  },
  replaceUrlWithReload: ({ query = '', path = '' }) => {
    const homeUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    window.location.href = `${homeUrl}${
      path || window.location.pathname || ''
    }${query}`;
  },
  getOffsetWidthByClassName: (className) => {
    if (!domUtils.isReady) return 0;
    const ele = document.getElementsByClassName(className)[0];
    if (ele) return ele?.offsetWidth;
    return 0;
  },
  getParamString: (param = { key: '', value: '' }) => {
    if (!param.key || !param.value) return '';
    return `${param.key}=${param.value}`;
  },
  buildParams: (params) => {
    const paramsLength = params.length;

    let paramsString = '';

    if (paramsLength) {
      for (let i = 0; i < paramsLength; i++) {
        if (i === 0) {
          paramsString = '?';
        }
        paramsString += domUtils.getParamString(params[i]);
        if (i < paramsLength - 1) {
          paramsString += '&';
        }
      }
    }

    return paramsString;
  },
  findParam: (params = [], key = '') => {
    if (!params?.length) return null;
    if (!key) return null;
    return params.find((p) => p.key === key) || null;
  },
  getIframeSrc: (url, params) => {
    if (isEmpty(url)) return '';
    if (isEmpty(params)) return url;
    return `${url}${domUtils.buildParams(params)}`;
  },
};

export const numberUtils = {
  isNumber: (n) => !Number.isNaN(Number(n)),
  isFloat: (n) => {
    return Number(n) === n && n % 1 !== 0;
  },
  mathRound: (value = 0, places = 2) => {
    try {
      var multiplier = Math.pow(10, places);
      return Math.round(value * multiplier) / multiplier;
    } catch (error) {}
    return value || 0;
  },
};

export const functionUtils = {
  isFunction: (fn) => typeof fn === 'function',
};
