import styled from 'styled-components';

const BackdropCommon = ({ children, onClose, styles }) => {
  return (
    <BackdropCommonStyled style={styles} onClick={onClose}>
      {children}
    </BackdropCommonStyled>
  );
};

const BackdropCommonStyled = styled.div`
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: calc(100dvh + 230px);
  background: transparent;
  z-index: 3;
`;

export default BackdropCommon;
