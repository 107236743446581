/* eslint-disable no-unused-vars */
import { LogUtil } from 'components/common/LogUtil';
import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import { fetchSellCurrencies, fetchSellRateCurrency } from 'components/fx/func';
import { ORDER_EMAIL } from 'constants';
import {
  FX_CALCULATOR_FORM_BUY_NAME,
  FX_CALCULATOR_FORM_PAY_NAME,
  PAY_AMOUNT_MAX_LIMIT,
  PAY_AMOUNT_MIN_LIMIT,
} from 'constants/fx';
import { format } from 'helpers';
import Cookies from 'helpers/cookies';
import { checkMaintenance } from 'helpers/maintenance';
import useCommonStore from 'hooks/useCommonStore';
import usePortalModal from 'hooks/usePortalModal';
import { isEmpty } from 'lodash';
import { numberUtils } from 'utils';
import { FX_MAKE_PAYMENT_KEY } from './useFxAddTransaction';

export const initialCalculatorForm = {
  [FX_CALCULATOR_FORM_PAY_NAME]: 0,
  [FX_CALCULATOR_FORM_BUY_NAME]: 0,
  currentCurrencySelected: null,
  sellRate: null,
};

const useFxCalculator = () => {
  const { showPortalModalName } = usePortalModal();
  const { setLoading } = useCommonStore();

  const getCurrencySelected = (currencyCode, currencies) =>
    currencies?.find((c) => c?.currencyCode === currencyCode) || null;

  const getCurrentMinDenomination = (currencyCode, currencies) =>
    currencies?.find((c) => c.currencyCode === currencyCode)?.minDenomination ||
    1;

  const getCalculatorFormInitial = async ({
    setCurrencies,
    information = initialCalculatorForm,
    setInformation,
  }) => {
    try {
      const { currentCurrencySelected } = information || {};

      const currencies = await fetchSellCurrencies();
      setCurrencies(currencies);

      const sellRate = await fetchSellRateCurrency(
        currentCurrencySelected?.currencyCode
      );
      const { rate } = sellRate || {};

      let buyValue = 0;
      let payValue = 0;
      payValue = numberUtils.mathRound(buyValue / rate);

      const newInformation = {
        ...information,
        [FX_CALCULATOR_FORM_PAY_NAME]: payValue,
        [FX_CALCULATOR_FORM_BUY_NAME]: buyValue,
        currentCurrencySelected: getCurrencySelected(
          currentCurrencySelected?.currencyCode,
          currencies
        ),
        sellRate,
      };
      setInformation(newInformation);
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('getCalculatorFormInitial', error);
    }
  };

  const getCalculatorFormByCurrencyChange = async ({
    currencies,
    information = initialCalculatorForm,
    setInformation,
    onError = () => {},
    sellRateLatest = null,
  }) => {
    try {
      const { currentCurrencySelected } = information || {};

      let sellRate = sellRateLatest;
      if (isEmpty(sellRate)) {
        sellRate = await fetchSellRateCurrency(
          currentCurrencySelected?.currencyCode
        );
      }
      const { rate } = sellRate || {};

      const currentMinDenomination = getCurrentMinDenomination(
        currentCurrencySelected?.currencyCode,
        currencies
      );

      let buyValue = information[FX_CALCULATOR_FORM_BUY_NAME] || 0;
      let payValue = information[FX_CALCULATOR_FORM_PAY_NAME] || 0;
      buyValue =
        Math.round((payValue * rate) / currentMinDenomination) *
        currentMinDenomination;
      payValue = numberUtils.mathRound(buyValue / rate);

      const newInformation = {
        ...information,
        [FX_CALCULATOR_FORM_PAY_NAME]: payValue,
        [FX_CALCULATOR_FORM_BUY_NAME]: buyValue,
        currentCurrencySelected: getCurrencySelected(
          currentCurrencySelected?.currencyCode,
          currencies
        ),
        sellRate,
      };
      setInformation(newInformation);
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('getCalculatorFormByCurrencyChange', error);
      onError();
    }
  };

  const getCalculatorFormByRateChange = ({
    payAmount,
    buyAmount,
    currencyCode = '',
    sellRate = null,
    currencies = [],
  }) => {
    let buyValue = buyAmount || 0;
    let payValue = payAmount || 0;

    try {
      if (isEmpty(sellRate) || isEmpty(currencyCode) || isEmpty(currencies)) {
        return {
          payAmount: payValue,
          buyAmount: buyValue,
        };
      }

      const { rate } = sellRate || {};

      const currentMinDenomination = getCurrentMinDenomination(
        currencyCode,
        currencies
      );

      buyValue =
        Math.round((payValue * rate) / currentMinDenomination) *
        currentMinDenomination;
      payValue = numberUtils.mathRound(buyValue / rate);

      return {
        payAmount: payValue,
        buyAmount: buyValue,
      };
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('getCalculatorFormByCurrencyChange', error);
      return {
        payAmount: payValue,
        buyAmount: buyValue,
      };
    }
  };

  const setSellCurrencies = async (setCurrencies) => {
    try {
      const currencies = await fetchSellCurrencies();
      setCurrencies(currencies || null);
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('setSellCurrencies', error);
    }
  };

  const getSellCurrencies = async () => {
    try {
      const currencies = await fetchSellCurrencies();
      return currencies || null;
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('getSellCurrencies', error);
    }
  };

  const getPayAmountLimitErrorMessage = (t, payAmount) => {
    if (payAmount < PAY_AMOUNT_MIN_LIMIT) {
      return t('popup_fx_calculator_pay_amount_min_validation', {
        amount: PAY_AMOUNT_MIN_LIMIT,
      });
    }
    if (payAmount > PAY_AMOUNT_MAX_LIMIT) {
      return t('popup_fx_calculator_pay_amount_max_validation', {
        amount: PAY_AMOUNT_MAX_LIMIT,
      });
    }
    return '';
  };

  const getContentOfSellRateTimeout = (
    sellRates = [],
    orders = [],
    currencies = []
  ) => {
    if (isEmpty(sellRates)) return {};
    let content = {
      desc: `fx_add_transaction_rates_timeout`,
      orders: [],
    };
    sellRates.forEach((sellRate, index) => {
      const { buyAmount, payAmount } = getCalculatorFormByRateChange({
        payAmount: orders?.[index]?.[FX_CALCULATOR_FORM_PAY_NAME],
        buyAmount: orders?.[index]?.[FX_CALCULATOR_FORM_BUY_NAME],
        currencyCode: sellRate?.currencyCode,
        sellRate,
        currencies,
      });
      content = {
        ...content,
        orders: [
          ...content.orders,
          {
            payAmount,
            payCurrencyCode: 'AUD',
            buyAmount,
            buyCurrencyCode: sellRate?.currencyCode,
            rate: `1 AUD = ${format.toAmountStr2(sellRate?.rate || 0)} ${
              sellRate?.currencyCode
            }`,
          },
        ],
      };
    });
    return content;
  };
  const checkSellRateOutdate = ({ sellRate, sellRateLatest }) => {
    if (isEmpty(sellRate) || isEmpty(sellRateLatest)) return false;
    const { rate: rateLatest, rateToken: rateTokenLatest } =
      sellRateLatest || {};
    const { rate, rateToken } = sellRate || {};
    return rateLatest !== rate && rateTokenLatest !== rateToken;
  };
  const refreshCalculatorByExchangeRateOutdate = async ({
    information,
    setInformation,
    currencies,
    callback = () => {},
  }) => {
    try {
      const { currentCurrencySelected, sellRate } = information || {};
      const { currencyCode } = currentCurrencySelected || {};

      let sellRateLatest = await fetchSellRateCurrency(currencyCode);
      /* BEGIN - test */
      // sellRateLatest = {
      //   currency: 'US DOLLAR',
      //   currencyCode: 'USD',
      //   rate: '0.8000',
      //   rateToken:
      //     '6633bb6f01cea61f58e676f54962ef127fa73a5e20c9050246fd2c14e7e98160925069bac8f550511c79366ec19904dc',
      // };
      /* END - test */
      const isSellRateOutdate = checkSellRateOutdate({
        sellRate,
        sellRateLatest,
      });

      if (!isSellRateOutdate) {
        callback();
        return;
      }
      if (isSellRateOutdate) {
        showPortalModalName({
          name: PORTAL_NAME_MODAL_DEFINE.FX_CALCULATOR_MODAL_RATE_TIMEOUT_MODAL,
          content: getContentOfSellRateTimeout(
            [sellRateLatest],
            [information],
            currencies
          ),
          action: () => {
            getCalculatorFormByCurrencyChange({
              currencies,
              information,
              setInformation,
              sellRateLatest,
              onError: () =>
                showPortalModalName({
                  name: PORTAL_NAME_MODAL_DEFINE.FX_CALCULATOR_MODAL_TIMEOUT_MODAL,
                  action: () => {},
                }),
            });
            callback();
          },
        });
      }
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('refreshCalculatorByExchangeRateOutdate', error);
    }
  };

  const handleRateItemOnShowCalculatorModal = async ({
    sellRate,
    callback = () => {},
  }) => {
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);

    const { currencyCode } = sellRate || {};
    setLoading(true);
    const currencies = await getSellCurrencies();
    if (!isEmpty(currencies)) {
      const currentCurrencySelected = getCurrencySelected(
        currencyCode,
        currencies
      );

      const newOrderEdit = {
        ...initialCalculatorForm,
        sellRate,
        currentCurrencySelected,
      };
      callback(newOrderEdit);
    }
  };

  return {
    getCurrencySelected,
    getCurrentMinDenomination,
    getCalculatorFormInitial,
    getCalculatorFormByCurrencyChange,
    setSellCurrencies,
    getSellCurrencies,
    getPayAmountLimitErrorMessage,
    getContentOfSellRateTimeout,
    checkSellRateOutdate,
    refreshCalculatorByExchangeRateOutdate,
    handleRateItemOnShowCalculatorModal,
  };
};

export default useFxCalculator;
