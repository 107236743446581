import useScrollIntoView from 'hooks/useScrollIntoView';
import React, { useRef, useState } from 'react';

const withInputFieldFocus = (Component) => {
  const InputFieldFocusComponent = (props) => {
    const [isFocus, setFocus] = useState(false);

    const focusRef = useRef(null);
    useScrollIntoView(focusRef, isFocus, [isFocus]);

    const newProps = {
      ...props,
    };
    return (
      <Component
        {...newProps}
        ref={focusRef}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    );
  };
  return InputFieldFocusComponent;
};

export default withInputFieldFocus;
