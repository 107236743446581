// import CarouselPrev from 'assets/ver2/icons/carousel-control-prev.svg';
// import CarouselNext from 'assets/ver2/icons/carousel-control-next.svg';
import IndicatorArrow from 'assets/images/indicator_arrow.png';
import VideoThumbHHMT from 'assets/images/youtube_video_thumb_hhmt.png';
import VideoThumbRemox from 'assets/images/youtube_video_thumb_remox.png';
import { format } from 'helpers';
import useSmartLook from 'hooks/smartlook/useSmartLook';
import { useCameraCheck } from 'hooks/useCameraCheck';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { useEffect, useState } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import VideoModal from 'components/common/VideoModal';
import styled from 'styled-components';
import {
  COMMON_PHONE,
  // DESKTOP_MIN_WIDTH,
  HHMT_PHONE,
  HHMT_START_REGISTER_REQUIREDS,
  LINK_REGISTRATION_HHMT_VIDEO_EMBED,
  LINK_REGISTRATION_REMOX_VIDEO_EMBED,
  REMOX_PHONE,
  REMOX_START_REGISTER_REQUIREDS,
} from '../../../../constants';
import useStore from '../../../../hooks/useStore';
import { SET_SHOW_BACKDROP } from '../../../../store/action';
import ButtonCommon from '../../../common/ButtonCommon';
import TooltipCommonMobile from '../../../common/TooltipCommon/mobile';
import AllowCameraAccessPopup from '../../AllowCameraAccessPopup/mobile';

const StartRegisterMobile = ({ setStarted }) => {
  const { t } = useLang();
  const { isTablet } = useDetectDevice();
  const { isHHMTTheme } = useTheme();
  const { dispatch } = useStore();

  // const [index, setIndex] = useState(0);
  const [isOpenAllowCameraAccessPopup, setOpenAllowCameraAccessPopup] =
    useState(true);
  const { checkAllowTakePhoto } = useCameraCheck();
  const { recordAPI } = useSmartLook({ isInit: false });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showIndicator, setShowIndicator] = useState(true);

  // const handleSelect = (selectedIndex) => {
  //   setIndex(selectedIndex);
  // };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    if (scrollTop + windowHeight >= documentHeight) {
      setIsDisabled(false);
      setShowIndicator(false);
    }
  };

  useEffect(() => {
    // Attach scroll listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleOnToggleAllowCameraAccessPopup = () => {
    setOpenAllowCameraAccessPopup(!isOpenAllowCameraAccessPopup);
  };

  const handleOnStartRegister = () => {
    setStarted(true);

    recordAPI.start();
  };
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleOnClick = () => {
    const payload = {
      isShow: true,
      text: isHHMTTheme
        ? t('registration_current_promotion_tc_hhmt')
        : t('registration_current_promotion_tc_remox'),
      zIndex: 1056,
    };
    dispatch({ type: SET_SHOW_BACKDROP, payload });
  };

  const terms = isHHMTTheme
    ? t('registration_current_promotion_tc_hhmt')
    : t('registration_current_promotion_tc_remox');
  const hasTerms =
    terms !==
    ('registration_current_promotion_tc_hhmt' ||
      'registration_current_promotion_tc_remox')
      ? true
      : false;

  return (
    <StartRegisterMobileStyled>
      {!checkAllowTakePhoto && isOpenAllowCameraAccessPopup && (
        <AllowCameraAccessPopup
          isOpen={!checkAllowTakePhoto && isOpenAllowCameraAccessPopup}
          onClose={handleOnToggleAllowCameraAccessPopup}
        />
      )}
      {isModalOpen && (
        <VideoModal isOpen={isModalOpen} onClose={handleModal}>
          <VideoEmbed>
            <iframe
              width="100%"
              height="300"
              src={
                isHHMTTheme
                  ? t(LINK_REGISTRATION_HHMT_VIDEO_EMBED)
                  : t(LINK_REGISTRATION_REMOX_VIDEO_EMBED)
              }
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </VideoEmbed>
        </VideoModal>
      )}
      <TopSection>
        <h1
          dangerouslySetInnerHTML={{
            __html: t('registration_page_title'),
          }}
        />
        <Promotion
          isHHMTTheme={isHHMTTheme}
          dangerouslySetInnerHTML={{
            __html: isHHMTTheme
              ? t('registration_current_promotion_hhmt')
              : t('registration_current_promotion_remox'),
          }}
        />
        <TCLink onClick={handleOnClick}>{t('registration_tc_link')}</TCLink>
        {hasTerms && (
          <TooltipCommonMobile
            text={{
              __html: isHHMTTheme
                ? t('registration_current_promotion_tc_hhmt')
                : t('registration_current_promotion_tc_remox'),
            }}
            name={terms}
            label={terms}
            isUseImage={false}
          />
        )}
      </TopSection>
      <Paragraph
        isHHMTTheme={isHHMTTheme}
        style={{ marginTop: '24px' }}
        dangerouslySetInnerHTML={{
          __html: t('registration_text_check_below_information'),
        }}
      />
      {/* <CarouselStyled activeIndex={index} onSelect={handleSelect} controls={true} >
        {(isHHMTTheme
          ? HHMT_START_REGISTER_REQUIREDS
          : REMOX_START_REGISTER_REQUIREDS
        ).map((item, i) => (
          <CarouselItem key={i}>
            <ImageStyled>
              <img className="d-block" src={item.imgUrl} alt="carousel-item" />
            </ImageStyled>
            <Carousel.Caption>
              <p>{t(item.label)}</p>
            </Carousel.Caption>
          </CarouselItem>
        ))}
      </CarouselStyled> */}
      <ItemRequireWrap>
        {(isHHMTTheme
          ? HHMT_START_REGISTER_REQUIREDS
          : REMOX_START_REGISTER_REQUIREDS
        ).map((item, i) => (
          <ItemRequired key={i}>
            <ImageStyled>
              <img className="d-block" src={item.imgUrl} alt="carousel-item" />
            </ImageStyled>
            <ItemLabel>{t(item.label)}</ItemLabel>
          </ItemRequired>
        ))}
      </ItemRequireWrap>
      <Video onClick={handleModal}>
        <img src={isHHMTTheme ? VideoThumbHHMT : VideoThumbRemox} />
        {t('registration_text_watch_video_web')}
      </Video>
      <CallUs style={{ width: isTablet && '528px' }}>
        {t('registration_text_call_us')}{' '}
        <span>
          <a href={`tel:${isHHMTTheme ? HHMT_PHONE : REMOX_PHONE}`}>
            {isHHMTTheme
              ? format.toMobileNumberStr(HHMT_PHONE)
              : format.toMobileNumberStr(REMOX_PHONE)}
          </a>{' '}
          -{' '}
          <a href={`tel:${COMMON_PHONE}`}>
            {format.toMobileNumberStr(COMMON_PHONE)}
          </a>
        </span>
      </CallUs>
      {showIndicator && <Indicator />}
      <StartFixed>
        <ButtonCommon
          value={t('button_start')}
          onClick={handleOnStartRegister}
          styles={{
            margin: '0px',
            width: '100%',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
          isDisabled={isDisabled}
        />
      </StartFixed>
    </StartRegisterMobileStyled>
  );
};

const StartRegisterMobileStyled = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  padding-bottom: 86px;
`;

const TopSection = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  width: 100dvw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f5f7fb;
  padding: 16px;
  margin-bottom: 24px;

  h1 {
    font-size: 16px;
    font-weight: 700;
    padding: 0;
    margin: 0 0 4px 0;
    text-align: left;
  }
`;
const Promotion = styled.div`
  width: 100%;
  margin-bottom: 4px;

  span {
    color: ${(props) =>
      props.isHHMTTheme ? 'var(--ds-c-yellow)' : 'var(--ds-c-red)'};
  }
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  width: 100%;
  padding: 0 16px;
  text-align: center;
  margin-bottom: 8px;

  span {
    color: ${(props) =>
      props.isHHMTTheme ? 'var(--ds-c-yellow)' : 'var(--ds-c-sky)'};
  }
`;

const TCLink = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: var(--ds-c-blue-hyperlink-default);
`;

// const Terms = styled.p`
//   font-family: var(--ff-primary);
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 20px;
//   color: var(--ds-c-grey-dark);
//   font-style: italic;
//   margin-bottom: 8px;

//   span {
//     color: ${(props) =>
//       props.isHHMTTheme ? 'var(--ds-c-yellow)' : 'var(--ds-c-sky)'};
//   }
// `;

const CallUs = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin: 24px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  & span {
    margin: 0;
  }

  & a {
    color: #002ff1;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      color: var(--ds-c-blue) !important;
    }
  }
`;

// const CarouselStyled = styled(Carousel)`
//   width: 100%;
//   height: 200px;
//   background-color: var(--ds-c-white);

//   & .carousel-inner {
//     height: 200px;
//   }

//   .carousel-control-prev,
//   .carousel-control-next {
//     width: 24px;
//   }

//   .carousel-control-prev-icon,
//   .carousel-control-next-icon {
//     background-size: contain;
//     background-repeat: no-repeat;
//     width: 24px;
//     height: 24px;
//   }

//   .carousel-control-prev-icon {
//     background-image: url(${CarouselPrev});
//   }

//   .carousel-control-next-icon {
//     background-image: url(${CarouselNext});
//   }

//   & .carousel-indicators {
//     margin-bottom: 0;
//     bottom: -20px;

//     button {
//       background: var(--bg-primary-disabled) !important;
//       border-radius: 50%;
//       width: 8px;
//       height: 8px;
//       border: none;
//     }

//     & .active {
//       background: var(--bg-primary) !important;
//     }
//   }
// `;
// const CarouselItem = styled(Carousel.Item)`
//   height: 100%;
//   background-color: var(--ds-c-white);

//   & .carousel-caption {
//     bottom: 0px;
//     top: 108px;
//     padding: 0;

//     p {
//       font-family: var(--ff-primary);
//       font-style: normal;
//       font-weight: 500;
//       font-size: 16px;
//       line-height: 20px;
//       text-align: center;

//       color: var(--ds-c-grey-dark);
//       margin-bottom: 0px;
//     }
//   }
// `;
// const ImageStyled = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-top: 16px;

//   img {
//     width: 80px;
//     height: 80px;
//   }
// `;

const ItemRequireWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  gap: 20px;

  box-sizing: border-box;
  width: 100%;
  padding: 16px;
`;
const ItemRequired = styled.div`
  width: 300px;
  height: auto;
  background-color: var(--ds-c-white);
`;
const ImageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;

  img {
    width: 70px;
    height: 70px;
  }
`;
const ItemLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 300px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 0px;
  margin-inline: auto;
  padding: 0;
`;

const VideoEmbed = styled.div`
  width: 96dvw;
`;

const Video = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  padding: 5px 16px;
  box-sizing: border-box;
  margin: 0 auto 0 auto;
  background-color: rgba(220, 227, 241, 0.3);

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--ds-c-blue-hyperlink-default);
  cursor: pointer;

  img {
    height: 50px;
  }
`;

const Indicator = styled.div`
  position: fixed;
  bottom: 76px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 60px;
  background: url(${IndicatorArrow}) bottom center no-repeat;
  z-index: 1000;
`;

const StartFixed = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: 2;
  background: var(--ds-c-white);
  width: 100%;
  // margin-inline: -24px;
  padding: 16px 24px;
  border-top: 1px solid var(--ds-c-grey-disabled);
`;

export default StartRegisterMobile;
