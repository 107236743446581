import { api } from 'api';
import { checkFormInputValidation } from 'components/common/func';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import {
  DESKTOP_MIN_WIDTH,
  FORGOT_PASSWORD_EMAIL_NAME,
  FORGOT_PASSWORD_FIELD,
  LOGIN_PREFIX,
  RESET_FORGOT_PASSWORD_INFORMATION,
} from '../../constants';
import ButtonCommon from '../common/ButtonCommon';
import FormInputValidation from './components/FormInputValidation';

import { checkMaintenance } from 'helpers/maintenance';
import FormSendEmailSuccess from './components/FormSendEmailSuccess';

const initialField = RESET_FORGOT_PASSWORD_INFORMATION;

const FormForgotPassword = () => {
  const { t } = useLang();
  const { dispatch } = useStore();

  const [information, setInformation] = useState(initialField);
  const [validation, setValidation] = useState(initialField);
  const [isSendEmailSuccess, setSendEmailSuccess] = useState(false);

  const handleSubmit = async () => {
    let newValidation = { ...validation };

    newValidation = checkFormInputValidation(
      information[FORGOT_PASSWORD_FIELD.name],
      FORGOT_PASSWORD_FIELD,
      newValidation
    );

    setValidation(newValidation);

    const isAllowSubmit =
      Object.values(information).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      try {
        const { data } = await api.forgotPassword(
          information[FORGOT_PASSWORD_EMAIL_NAME]
        );

        if (data?.success) {
          setSendEmailSuccess(true);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      } catch (error) {
        checkMaintenance(error);
        setSendEmailSuccess(true);

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    }
  };

  if (isSendEmailSuccess) {
    return <FormSendEmailSuccess information={information} />;
  }
  return (
    <FormForgotPasswordWrap>
      <TitleH3>{t('forgot_password_text_need_help')}</TitleH3>
      <Paragraph>{t('forgot_password_text_solution')}</Paragraph>
      <FormForgotPasswordStyled>
        <FormInputValidation
          key={FORGOT_PASSWORD_FIELD.name}
          field={FORGOT_PASSWORD_FIELD}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
        />
        <ResetSubmit>
          <LinkBackToLoginStyled to={LOGIN_PREFIX}>
            {t('button_back_to_login')}
          </LinkBackToLoginStyled>
          <ButtonCommon
            value={t('button_reset_your_password')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            styles={{
              margin: '0px',
              width: '200px',
              float: 'right',
            }}
            onClick={handleSubmit}
            isUseKeyDown
            isFill={true}
          />
        </ResetSubmit>
      </FormForgotPasswordStyled>
    </FormForgotPasswordWrap>
  );
};

const FormForgotPasswordWrap = styled.div`
  padding: 40px;
  background: var(--ds-c-white);
  box-shadow: 0px 4px 15px rgb(0 0 0 / 12%);
  border-radius: 40px;

  @media screen and (min-width: 1280px) {
    width: 437px;
    height: fit-content;
  }

  @media screen and (min-width: 1440px) {
    width: 517px;
    height: fit-content;
  }
`;

const FormForgotPasswordStyled = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 99px);
`;

const ResetSubmit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    width: 200px;
  }
`;

const LinkBackToLoginStyled = styled(Link)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;

  color: var(--ds-c-blue);
  display: block;

  &:hover {
    color: var(--ds-c-blue-hover) !important;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 24px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const TitleH3 = styled.h3`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;
`;

export default FormForgotPassword;
