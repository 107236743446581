/* eslint-disable no-unused-vars */
import { api } from 'api';
import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import {
  CloseIcon,
  Title,
  TitleCancel,
} from 'components/FormAddTransaction/components/FormEditAddress/mobile/ver2';
import {
  getBankAddVerification,
  getFullNameReceiver,
} from 'components/FormEditReceiver/func';
import ButtonCommon from 'components/common/ButtonCommon';
import InformPopup from 'components/common/InformPopup';
import { checkFormInputValidation } from 'components/common/func';
import {
  ADD_BANK_ACCOUNT_HOLDER_NAME,
  ADD_BANK_ACCOUNT_NUMBER_NAME,
  ADD_BANK_BSB_NAME,
  ADD_BANK_INPUT_FIELDS_BUSINESS,
  ADD_BANK_INPUT_FIELDS_INDIVIDUAL,
  ADD_BANK_NAME,
  ADD_BANK_NAME_FIELD,
  ADD_BANK_SWIFT_NAME,
  BUSINESS_OF_RECEIVER_TYPE,
  CREATE_BUSINESS_RECEIVER_TYPE,
  CREATE_INDIVIDUAL_RECEIVER_TYPE,
  DESKTOP_MIN_WIDTH,
  EDIT_RECEIVER_COUNTRY_NAME,
  EDIT_RECEIVER_FULLNAME_NAME,
  EDIT_RECEIVER_GENDER_NAME,
  FEMALE,
  MALE,
  MOBILE_MIN_WIDTH,
  RECEIVERS_ADD_SUFIX,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
  TABLET_MAX_WIDTH,
} from 'constants';
import { CloseBlueIcon } from 'constants/icon';
import { checkMaintenance } from 'helpers/maintenance';
import useFormAddReceiverStore from 'hooks/form-add-receiver/useFormAddReceiverStore';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import useReceiver from 'hooks/receiver/useReceiver';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import BankNumberAccountPopup from '../BankNumberAccountPopup';
import CustomBankNameDropdown from '../CustomBankNameDropdown';
import FormInputHolderNameValidation from '../FormInputHolderNameValidation';
import FormInputValidationWithFormatAndRegex from '../FormInputValidationWithFormatAndRegex';

export const formAddBankAccountClassName = 'form-add-bank-account';

const initialFormBank =
  RESET_EDIT_RECEIVER_INFORMATION_STORE.accountDetails.bank;

const FormAddNewBankAccount = ({
  isOpen,
  isEdit,
  countryConfig,
  currentReceiver,
  information,
  setInformation,
  bankInformation,
  setBankInformation,
  bankValidation,
  setBankValidation,
  onClick,
  onClose = () => {},
}) => {
  const { t } = useLang();
  const { isDesktop, isMobile, isTablet, isAndroid } = useDetectDevice();
  const { state, dispatch } = useStore();
  const { isLoading, editReceiverInformation } = state || {};
  const { personalDetails } = editReceiverInformation || {};

  const [banks, setBanks] = useState(null);
  const [currentBankSelected, setCurrentBankSelected] = useState(null);
  const [isOpenBankNumberAccountPopup, setOpenBankNumberAccountPopup] =
    useState(false);
  const [isOpenNoticePopup, setOpenNoticePopup] = useState(false);
  const [accountNumberMaskStr, setAccountNumberMaskStr] = useState('');
  const [bsbNumberMaskStr, setBsbNumberMaskStr] = useState('');
  const [swiftNumberMaskStr, setSwiftNumberMaskStr] = useState('');
  const [isSaveDisableInit, setSaveDisableInit] = useState(true);

  const navigate = useNavigate();

  const { setCurrentBusinessReceiver } = useFormAddTransactionStore();
  const {
    resetEditReceiverInformation,
    resetAddReceiverInformation,
    setEditReceiverCurrentStep,
  } = useFormAddReceiverStore();

  const { isCreateBusinessReceiver, isCreateIndividualReceiver } =
    useReceiver();

  const config = useGetConfig({ countryConfig });
  const { accountHolder, accountNumber, accountSwift, accountBsb } = config;

  const isApiEnabled = accountNumber?.apiEnabled;

  let { lastName, firstName, gender } = currentReceiver || {};
  gender = gender || personalDetails?.[EDIT_RECEIVER_GENDER_NAME];

  const isReceiverOfBusinessType =
    gender === BUSINESS_OF_RECEIVER_TYPE || isCreateBusinessReceiver;
  const isReceiverOfIndividualType =
    gender === MALE || gender === FEMALE || isCreateIndividualReceiver;

  const receiverFullName = (
    getFullNameReceiver({
      isReceiverOfBusinessType,
      isReceiverOfIndividualType,
      lastName,
      firstName,
      countryCode: countryConfig?.countryCode,
    }) || personalDetails?.[EDIT_RECEIVER_FULLNAME_NAME]
  )?.toUpperCase();
  const accountHolderName = isEdit
    ? bankInformation[ADD_BANK_ACCOUNT_HOLDER_NAME]
    : receiverFullName || bankInformation[ADD_BANK_ACCOUNT_HOLDER_NAME];

  const isSaveDisabled =
    isLoading ||
    !currentBankSelected ||
    (accountHolder?.visible &&
      (!bankInformation[ADD_BANK_ACCOUNT_HOLDER_NAME] ||
        bankValidation[ADD_BANK_ACCOUNT_HOLDER_NAME])) ||
    (accountNumber?.visible &&
      ((accountNumber.required &&
        !bankInformation[ADD_BANK_ACCOUNT_NUMBER_NAME]) ||
        bankValidation[ADD_BANK_ACCOUNT_NUMBER_NAME])) ||
    (accountSwift?.visible &&
      ((accountSwift.required && !bankInformation[ADD_BANK_SWIFT_NAME]) ||
        bankValidation[ADD_BANK_SWIFT_NAME])) ||
    (accountBsb?.visible &&
      ((accountBsb.required && !bankInformation[ADD_BANK_BSB_NAME]) ||
        bankValidation[ADD_BANK_BSB_NAME])) ||
    isSaveDisableInit;

  const ADD_BANK_INPUT_FIELDS = isReceiverOfIndividualType
    ? ADD_BANK_INPUT_FIELDS_INDIVIDUAL
    : ADD_BANK_INPUT_FIELDS_BUSINESS;

  useEffect(() => {
    return () => {
      if (!isEdit && !isCreateBusinessReceiver && !isCreateIndividualReceiver) {
        setCurrentBankSelected(null);

        setBankInformation(initialFormBank);

        setBankValidation(initialFormBank);

        setAccountNumberMaskStr('');

        setSwiftNumberMaskStr('');

        setBsbNumberMaskStr('');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    return () => {
      setBankValidation(initialFormBank);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBanksInCountry = async (countryCode) => {
    try {
      const { data } = await api.getBanks(countryCode);

      if (data) {
        setBanks(data);
      }
    } catch (error) {
      checkMaintenance(error);
      console.error(error?.message);
    }
  };
  useEffect(() => {
    if (countryConfig) {
      fetchBanksInCountry(countryConfig?.countryCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryConfig]);

  const getCurrentBankSelectedInitial = () => {
    if (banks) {
      const currentBank = banks?.find(
        (b) => b.name === bankInformation[ADD_BANK_NAME]
      );
      if (currentBank) {
        setCurrentBankSelected(currentBank);

        const newBankInformation = {
          ...bankInformation,
          [ADD_BANK_ACCOUNT_HOLDER_NAME]: isEdit
            ? accountHolderName
            : receiverFullName || accountHolderName,
        };
        setBankInformation(newBankInformation);
      }
    }
  };
  useEffect(() => {
    const isAutoFill = Boolean(
      (isEdit || isCreateBusinessReceiver || isCreateIndividualReceiver) &&
        bankInformation?.[ADD_BANK_NAME] &&
        bankInformation?.[ADD_BANK_ACCOUNT_HOLDER_NAME] &&
        bankInformation?.[ADD_BANK_ACCOUNT_NUMBER_NAME] &&
        banks &&
        isSaveDisableInit
    );
    if (isAutoFill) {
      getCurrentBankSelectedInitial();
      setSaveDisableInit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEdit,
    isCreateBusinessReceiver,
    isCreateIndividualReceiver,
    bankInformation,
    banks,
    isSaveDisableInit,
  ]);

  const checkBankAccountExisted = (bankExisteds, information, validation) => {
    const isExisted = bankExisteds?.find(
      (i) =>
        i.bankName === information[ADD_BANK_NAME] &&
        i.accountNumber === information[ADD_BANK_ACCOUNT_NUMBER_NAME]
    );

    if (isExisted) {
      validation = {
        ...validation,
        [ADD_BANK_ACCOUNT_NUMBER_NAME]: ADD_BANK_INPUT_FIELDS[1].msgExisted,
      };
    } else {
      validation = {
        ...validation,
        [ADD_BANK_ACCOUNT_NUMBER_NAME]: '',
      };
    }

    return validation;
  };

  const handleOnAcceptBankAccountNumberPopup = () => {
    setBankValidation(initialFormBank);

    setOpenBankNumberAccountPopup(false);

    handleSave(bankInformation);
  };

  const handleOnOpenBankAccountNumberPopup = () => {
    setOpenBankNumberAccountPopup(true);
  };

  const handleOnOpenNoticePopup = () => {
    setOpenNoticePopup(true);
  };

  const handleOnEditBankAccountNumberPopup = () => {
    const currentBank = banks?.find(
      (b) => b.name === bankInformation[ADD_BANK_NAME]
    );

    if (currentBank) {
      setCurrentBankSelected(currentBank);
    }

    setBankInformation({
      ...bankInformation,
      [ADD_BANK_NAME]: currentBank?.name,
      [ADD_BANK_ACCOUNT_HOLDER_NAME]: receiverFullName || accountHolderName,
    });

    setOpenBankNumberAccountPopup(false);
  };

  const handleNumberValidation = async (
    value,
    name,
    bankValidation,
    bankInformation
  ) => {
    try {
      let holderName = '';

      if (isApiEnabled) {
        dispatch({ type: SET_SHOW_LOADING, payload: true });

        const { data } = await api.validateAccNumber(
          countryConfig?.countryCode,
          currentBankSelected?.id,
          value,
          firstName || receiverFullName,
          lastName || ''
        );

        if (data) {
          if (data?.valid) {
            holderName = data?.holderName;

            bankValidation = {
              ...bankValidation,
              [name]: '',
            };
          } else {
            holderName = '';

            bankValidation = {
              ...bankValidation,
              [name]: ADD_BANK_INPUT_FIELDS[1].msgNumberInvalid,
            };
          }

          bankInformation = {
            ...bankInformation,
            [ADD_BANK_ACCOUNT_HOLDER_NAME]: holderName,
            bankValidate: data,
          };

          const isBusinessAccount = data?.businessAccount;
          if (data?.nameMatched) {
            if (isReceiverOfBusinessType && !isBusinessAccount) {
              handleOnOpenNoticePopup();
            } else if (isReceiverOfIndividualType && isBusinessAccount) {
              handleOnOpenNoticePopup();
            } else {
              handleSave(bankInformation);
            }
          } else {
            if (isReceiverOfIndividualType) {
              if (isBusinessAccount && holderName) {
                handleOnOpenNoticePopup();
              } else {
                handleOnOpenBankAccountNumberPopup();
              }
            } else if (isReceiverOfBusinessType) {
              if (!isBusinessAccount && holderName) {
                handleOnOpenNoticePopup();
              } else {
                handleOnOpenBankAccountNumberPopup();
              }
            } else {
              bankInformation = {
                ...bankInformation,
                [ADD_BANK_ACCOUNT_HOLDER_NAME]: '',
              };
              setBankInformation(bankInformation);

              setBankValidation(bankValidation);

              dispatch({ type: SET_SHOW_LOADING, payload: false });

              handleOnOpenBankAccountNumberPopup();

              return;
            }
          }

          setBankInformation(bankInformation);

          setBankValidation(bankValidation);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }

        return;
      }

      bankInformation = {
        ...bankInformation,
        [ADD_BANK_ACCOUNT_HOLDER_NAME]: '',
      };
      setBankInformation(bankInformation);

      bankValidation = {
        ...bankValidation,
        [name]: '',
      };
      setBankValidation(bankValidation);

      dispatch({ type: SET_SHOW_LOADING, payload: false });

      handleSave(bankInformation);
    } catch (error) {
      checkMaintenance(error);
      console.error(error?.message);

      bankInformation = {
        ...bankInformation,
        [ADD_BANK_ACCOUNT_HOLDER_NAME]: '',
      };
      setBankInformation(bankInformation);

      bankValidation = {
        ...bankValidation,
        [name]: ADD_BANK_INPUT_FIELDS[1].msgNumberInvalid,
      };
      setBankValidation(bankValidation);

      dispatch({ type: SET_SHOW_LOADING, payload: false });

      if (isApiEnabled) {
        handleOnOpenBankAccountNumberPopup();
      }
    }
  };

  const handleCheckAccountNumber = async () => {
    let newBankInformation = { ...bankInformation };
    let newBankValidation = { ...bankValidation };

    delete newBankInformation['bankValidate'];
    delete newBankValidation['bankValidate'];

    if (!accountHolder?.required) {
      delete newBankInformation[ADD_BANK_ACCOUNT_HOLDER_NAME];
      delete newBankValidation[ADD_BANK_ACCOUNT_HOLDER_NAME];
    }

    if (!accountNumber?.required) {
      delete newBankInformation[ADD_BANK_ACCOUNT_NUMBER_NAME];
      delete newBankValidation[ADD_BANK_ACCOUNT_NUMBER_NAME];
    }

    if (!accountSwift?.required) {
      delete newBankInformation[ADD_BANK_SWIFT_NAME];
      delete newBankValidation[ADD_BANK_SWIFT_NAME];
    }

    if (!accountBsb?.required) {
      delete newBankInformation[ADD_BANK_BSB_NAME];
      delete newBankValidation[ADD_BANK_BSB_NAME];
    }

    const isAllowCheck =
      Object.values(newBankInformation).every((value) => value) &&
      Object.values(newBankValidation).every((value) => !value);

    if (isAllowCheck) {
      handleNumberValidation(
        bankInformation[ADD_BANK_ACCOUNT_NUMBER_NAME],
        ADD_BANK_ACCOUNT_NUMBER_NAME,
        bankValidation,
        bankInformation
      );
    }

    if (!isAllowCheck) {
      if (!currentBankSelected) {
        newBankValidation = {
          ...newBankValidation,
          [ADD_BANK_NAME]: ADD_BANK_NAME_FIELD.msgRequired,
        };
      }

      if (accountHolder?.required) {
        newBankValidation = checkFormInputValidation(
          newBankInformation[ADD_BANK_ACCOUNT_HOLDER_NAME],
          ADD_BANK_INPUT_FIELDS[0],
          newBankValidation
        );
      }

      if (accountNumber?.required) {
        newBankValidation = checkFormInputValidation(
          newBankInformation[ADD_BANK_ACCOUNT_NUMBER_NAME],
          ADD_BANK_INPUT_FIELDS[1],
          newBankValidation
        );

        newBankValidation = checkBankAccountExisted(
          information?.banks,
          newBankInformation,
          newBankValidation
        );
      }

      if (accountSwift?.required) {
        newBankValidation = checkFormInputValidation(
          newBankInformation[ADD_BANK_SWIFT_NAME],
          ADD_BANK_INPUT_FIELDS[2],
          newBankValidation
        );
      }

      if (accountBsb?.required) {
        newBankValidation = checkFormInputValidation(
          newBankInformation[ADD_BANK_BSB_NAME],
          ADD_BANK_INPUT_FIELDS[3],
          newBankValidation
        );
      }

      setBankValidation(newBankValidation);
    }
  };

  const handleSave = (bankInformation) => {
    const { bankValidate } = bankInformation || {};
    let { holderName } = bankValidate || {};
    holderName = holderName || accountHolderName;

    let bankAdd = {
      bankName: bankInformation[ADD_BANK_NAME],
      accountName: holderName?.toUpperCase(),
      accountNumber: bankInformation[ADD_BANK_ACCOUNT_NUMBER_NAME],
      swiftCode: bankInformation[ADD_BANK_SWIFT_NAME] || null,
      branchCode: bankInformation[ADD_BANK_BSB_NAME] || null,
    };

    if (isEdit) {
      // Edit bank
      bankAdd = {
        ...bankAdd,
        id: bankInformation.id,
      };

      onClick && onClick(bankAdd, bankInformation, true);
    } else {
      // Add new bank
      bankAdd = {
        ...bankAdd,
        id: bankInformation.id || Date.now(),
      };

      bankAdd = getBankAddVerification({
        bankValidate,
        bankAdd,
        countryCode: countryConfig?.countryCode,
        receiverFullName: accountHolderName,
      });

      onClick && onClick(bankAdd);

      const newInformation = {
        ...information,
        banks: [...information.banks, bankAdd],
      };

      setInformation(newInformation);
    }

    onClose();
  };

  const renderActionsButton = () => {
    return (
      <>
        <ButtonCommon
          value={t('button_cancel')}
          onClick={onClose}
          styles={{
            margin: '0px',
            marginRight: '16px',
            width: '80px',
          }}
          color="var(--ds-c-blue)"
          background="var(--ds-c-white)"
        />
        <ButtonCommon
          value={t('button_save')}
          onClick={handleCheckAccountNumber}
          styles={{
            margin: '0px',
            width: '80px',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
          isDisabled={isSaveDisabled}
        />
      </>
    );
  };

  const renderActionsButtonMobile = () => {
    return (
      <NextWrap>
        <ButtonCommon
          value={t('button_save')}
          onClick={handleCheckAccountNumber}
          styles={{
            margin: '0px',
            width: '100%',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
          isDisabled={isSaveDisabled}
        />
      </NextWrap>
    );
  };

  const handleOnNoNoticePopup = () => {
    const currentBank = banks?.find(
      (b) => b.name === bankInformation[ADD_BANK_NAME]
    );

    if (currentBank) {
      setCurrentBankSelected(currentBank);
    }

    setBankInformation({
      ...bankInformation,
      [ADD_BANK_NAME]: currentBank?.name,
      [ADD_BANK_ACCOUNT_HOLDER_NAME]: receiverFullName || accountHolderName,
    });

    setOpenNoticePopup(false);
  };
  const handleOnYesNoticePopup = () => {
    const { gender, countryName, country, lastName, firstName } =
      currentReceiver || {};
    const newCurrentReceiver = {
      firstName: personalDetails?.[EDIT_RECEIVER_FULLNAME_NAME] || firstName,
      lastName: '' || lastName,
      country: personalDetails?.[EDIT_RECEIVER_COUNTRY_NAME] || country,
      countryName:
        personalDetails?.[EDIT_RECEIVER_COUNTRY_NAME]?.name || countryName,
      gender: personalDetails?.[EDIT_RECEIVER_GENDER_NAME] || gender,
    };
    setCurrentBusinessReceiver({
      receiver: newCurrentReceiver,
      bank: bankInformation,
    });
    if (isEdit) {
      resetEditReceiverInformation();
    } else {
      resetAddReceiverInformation();
    }
    setEditReceiverCurrentStep(0);
    if (isReceiverOfIndividualType) {
      navigate(`${RECEIVERS_ADD_SUFIX}?type=${CREATE_BUSINESS_RECEIVER_TYPE}`);
      return;
    }
    if (isReceiverOfBusinessType) {
      navigate(
        `${RECEIVERS_ADD_SUFIX}?type=${CREATE_INDIVIDUAL_RECEIVER_TYPE}`
      );
      return;
    }
  };
  const renderNoticePopup = (isOpen) => {
    const getContentOfNoticePopup = () => {
      if (isReceiverOfIndividualType)
        return t('popup_add_receiver_notice_individual_receiver', {
          holderName:
            bankInformation[ADD_BANK_ACCOUNT_HOLDER_NAME]?.toUpperCase(),
          receiverFullName: receiverFullName,
        });

      if (isReceiverOfBusinessType)
        return t('popup_add_receiver_notice_business_receiver', {
          holderName:
            bankInformation[ADD_BANK_ACCOUNT_HOLDER_NAME]?.toUpperCase(),
          receiverFullName: receiverFullName,
        });

      return '';
    };

    const content = getContentOfNoticePopup();

    if (content)
      return (
        <InformPopup
          isOpen={isOpen}
          imageSrc={NoticeIcon}
          imagesStyles={{
            width: '124px',
            height: '100px',
          }}
          content={content}
          isBackdropOverlap={true}
          zIndexBackdropOverlap={'1055!important'}
          buttonLabel1={t('button_no')}
          onClose={handleOnNoNoticePopup}
          buttonLabel2={t('button_yes')}
          onClick={handleOnYesNoticePopup}
        />
      );

    return null;
  };

  const renderModalHeaderBody = () => {
    const value = isEdit
      ? t('popup_edit_bank_account')
      : t('popup_add_bank_account');

    if (isMobile || isTablet)
      return (
        <TitleCancel>
          <Title>{value}</Title>
          <CloseIcon src={CloseBlueIcon} onClick={onClose} />
        </TitleCancel>
      );

    return value;
  };

  return (
    <>
      <style>
        {`
          @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${
          TABLET_MAX_WIDTH - 1
        }px) {
            .add-bank-modal-backdrop {
              top: 70px;
            }

            .modal {
              top: 70px;
              padding-left: 0px;
            }
          }

          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal-backdrop {
              z-index: 1045!important;
            }
          }

          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
            .add-bank-modal-backdrop {
              background: #212121;
              opacity: 0.7!important;
              display: initial!important;
            }
          }
      `}
      </style>

      {isOpenBankNumberAccountPopup && (
        <BankNumberAccountPopup
          isOpen={isOpenBankNumberAccountPopup}
          holderName={bankInformation[ADD_BANK_ACCOUNT_HOLDER_NAME]}
          onAccept={handleOnAcceptBankAccountNumberPopup}
          onEdit={handleOnEditBankAccountNumberPopup}
        />
      )}

      {isOpenNoticePopup && renderNoticePopup(isOpenNoticePopup)}

      <ModalStyled
        show={isOpen}
        aria-labelledby={isDesktop && 'contained-modal-title-vcenter'}
        className={formAddBankAccountClassName}
        backdropClassName="add-bank-modal-backdrop"
        centered={isDesktop}
        animation={isDesktop}
        $isAndroid={isAndroid}
      >
        <ModalHeader>{renderModalHeaderBody()}</ModalHeader>
        <ModalBody>
          <CustomBankNameDropdown
            config={accountNumber}
            banks={banks}
            bankExisteds={information?.banks}
            information={bankInformation}
            setInformation={setBankInformation}
            validation={bankValidation}
            setValidation={setBankValidation}
            currentBankSelected={currentBankSelected}
            setCurrentBankSelected={setCurrentBankSelected}
            checkBankAccountExisted={checkBankAccountExisted}
            setSaveDisableInit={setSaveDisableInit}
            accountHolderName={accountHolderName}
          />
          {bankInformation[ADD_BANK_NAME] && accountHolder?.visible && (
            <FormInputHolderNameValidation
              field={ADD_BANK_INPUT_FIELDS[0]}
              config={accountHolder}
              isConfigRequired={accountHolder?.required}
              information={bankInformation}
              setInformation={setBankInformation}
              validation={bankValidation}
              setValidation={setBankValidation}
              setSaveDisableInit={setSaveDisableInit}
            />
          )}
          {bankInformation[ADD_BANK_NAME] && accountNumber?.visible && (
            <FormInputValidationWithFormatAndRegex
              field={ADD_BANK_INPUT_FIELDS[1]}
              config={accountNumber}
              numberMaskStr={accountNumberMaskStr}
              setNumberMaskStr={setAccountNumberMaskStr}
              countryConfig={countryConfig}
              accountNumberExisteds={information?.banks}
              information={bankInformation}
              setInformation={setBankInformation}
              validation={bankValidation}
              setValidation={setBankValidation}
              checkAccountExisted={checkBankAccountExisted}
              setSaveDisableInit={setSaveDisableInit}
            />
          )}
          {bankInformation[ADD_BANK_NAME] && accountSwift?.visible && (
            <FormInputValidationWithFormatAndRegex
              field={ADD_BANK_INPUT_FIELDS[2]}
              config={accountSwift}
              numberMaskStr={swiftNumberMaskStr}
              setNumberMaskStr={setSwiftNumberMaskStr}
              countryConfig={countryConfig}
              information={bankInformation}
              setInformation={setBankInformation}
              validation={bankValidation}
              setValidation={setBankValidation}
              setSaveDisableInit={setSaveDisableInit}
            />
          )}
          {bankInformation[ADD_BANK_NAME] && accountBsb?.visible && (
            <FormInputValidationWithFormatAndRegex
              field={ADD_BANK_INPUT_FIELDS[3]}
              config={accountBsb}
              numberMaskStr={bsbNumberMaskStr}
              setNumberMaskStr={setBsbNumberMaskStr}
              countryConfig={countryConfig}
              information={bankInformation}
              setInformation={setBankInformation}
              validation={bankValidation}
              setValidation={setBankValidation}
              setSaveDisableInit={setSaveDisableInit}
            />
          )}
        </ModalBody>
        <ModalFooter>
          {isDesktop ? renderActionsButton() : renderActionsButtonMobile()}
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    & .modal-dialog {
      position: absolute;
      top: 0px;
      margin: 0;
      height: ${(props) => (props.$isAndroid ? '150dvh' : '120dvh')};
      width: 100%;
      max-width: 100%;
      transform: none;

      & .modal-content {
        padding: 0px 16px;
        border-radius: 0px;
        box-shadow: none;
        background: var(--ds-c-white);
        width: 100%;
        height: 100%;
        margin-inline: auto;
        border: none;
      }
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-dialog {
      transform: none;
    }
    & .modal-content {
      padding: 24px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 470px;
      height: fit-content;
      margin-inline: auto;
    }
  }
`;
const ModalHeader = styled(Modal.Header)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #7a8189;

  padding: 0;
  margin-bottom: 0px;
  display: block;
  border: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    color: var(--ds-c-green-default);
    font-weight: 700;
    margin-bottom: 16px;
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 0px;
`;
const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: end;
  margin-inline: -24px;
  padding-inline: 24px;
  padding-block: 16px;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding-block: unset;
    padding-inline: unset;
    margin-inline: unset;
    border-top: none;
  }
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  background: var(--ds-c-white);
  margin-block: 0px;
  position: fixed;
  left: 24px;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-inline: unset;
    padding-inline: unset;
    height: 40px;
    left: unset;
    border-top: none;
  }
`;

export default FormAddNewBankAccount;
