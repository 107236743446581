import { api } from 'api';
import { getFullNameReceiver } from 'components/FormEditReceiver/func';
import {
  ADD_TRANSACTION_METHOD_BANK_NAME,
  ADD_TRANSACTION_METHOD_EWALLET_NAME,
  ADD_TRANSACTION_METHOD_HOME_NAME,
  ADD_TRANSACTION_METHOD_PICKUP_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_STEP_REFERENCE,
  BUSINESS_OF_RECEIVER_TYPE,
  FEMALE,
  MALE,
  METHOD_BANK,
  METHOD_EWALLET,
  METHOD_HOME,
  METHOD_PICKUP,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
} from 'constants';
import { checkMaintenance } from 'helpers/maintenance';
import { isEmpty } from 'lodash';
import { dispatchStore, stateStore } from 'store';
import {
  SET_ADD_TRANSACTION_INFORMATION,
  SET_CURRENT_RECEIVER,
  SET_EDIT_RECEIVER_INFORMATION,
} from 'store/action';
import { arrayUtils, stringUtils } from 'utils';

export const TRANSACTION_PAYLOAD_INIT = {
  refNumber: null,
  status: null,
  purpose: null,
  source: null,
  job: null,
  prevJob: null,
  message: '',
  consent: true,
  subTotal: 0,
  surcharge: 0,
  total: 0,
  totalPay: 0,
  receiverId: null,
  payout: null,
  remittance: null,
  fee: 0,
  paymentMethod: null,
  discountAmount: 0,
  discount: null,
  isMgTranx: false,
  mgFeeInfo: null,
  gffpResponse: null,
  relationship: null,
  senderIntendedUseOfMGIServices: null,
  birthCountryCode: null,
};

export const fetchReceiverInformation = async (
  token,
  receiverId,
  information,
  currentStep,
  bankId,
  ewalletId
) => {
  try {
    const { data: dataReceiver } = await api.getReceiver(token, receiverId);

    if (dataReceiver) {
      const {
        firstname,
        lastname,
        gender,
        mobile,
        phone,
        address,
        banks,
        ewallets,
        favorited,
        relationship,
      } = dataReceiver;

      const newInformation = {
        receiverId,
        firstName: firstname,
        lastName: lastname,
        fullName: `${firstname} ${lastname}`,
        country: address?.country,
        countryName: address?.country?.name,
        gender,
        address,
        fullAddress: address?.fullAddress,
        mobile,
        mobileNumber: mobile?.number,
        phone,
        phoneNumber: phone?.number,
        banks,
        ewallets,
        favorited,
        relationship,
      };

      dispatchStore({ type: SET_CURRENT_RECEIVER, payload: newInformation });

      dispatchStore({
        type: SET_EDIT_RECEIVER_INFORMATION,
        payload: {
          ...RESET_EDIT_RECEIVER_INFORMATION_STORE,
          residentialAddress: {
            ...RESET_EDIT_RECEIVER_INFORMATION_STORE.residentialAddress,
            isEditReceiverEnterAddressManuallySelected: address.manual,
          },
        },
      });

      let transactionInformationPayload = {
        ...stateStore.addTransactionInformation,
        [ADD_TRANSACTION_STEP_REFERENCE[currentStep]]: {
          ...information,
          currentPayoutItemSelectedIndex:
            information.currentPayoutItemSelectedIndex,
          [ADD_TRANSACTION_PAYOUT_METHOD_NAME]:
            information[ADD_TRANSACTION_PAYOUT_METHOD_NAME],
        },
      };

      /* BEGIN: delete bank/ewallet */
      if (
        bankId &&
        information[ADD_TRANSACTION_METHOD_BANK_NAME]?.bank?.id === bankId
      ) {
        transactionInformationPayload = {
          ...transactionInformationPayload,
          [ADD_TRANSACTION_STEP_REFERENCE[currentStep]]: {
            ...information,
            [ADD_TRANSACTION_METHOD_BANK_NAME]: null,
            [ADD_TRANSACTION_PAYOUT_METHOD_NAME]:
              information[ADD_TRANSACTION_PAYOUT_METHOD_NAME],
            currentPayoutItemSelectedIndex: -1,
          },
        };
      }

      if (
        ewalletId &&
        information[ADD_TRANSACTION_METHOD_EWALLET_NAME]?.ewallet?.id ===
          ewalletId
      ) {
        transactionInformationPayload = {
          ...transactionInformationPayload,
          [ADD_TRANSACTION_STEP_REFERENCE[currentStep]]: {
            ...information,
            [ADD_TRANSACTION_METHOD_EWALLET_NAME]: null,
            [ADD_TRANSACTION_PAYOUT_METHOD_NAME]:
              information[ADD_TRANSACTION_PAYOUT_METHOD_NAME],
            currentPayoutItemSelectedIndex: -1,
          },
        };
      }
      /* END: delete bank/ewallet */

      dispatchStore({
        type: SET_ADD_TRANSACTION_INFORMATION,
        payload: transactionInformationPayload,
      });
    }
  } catch (error) {
    checkMaintenance(error);
    console.error(error?.message);
  }
};

export const fetchReceiverRecordInformation = async (token, refNumber) => {
  try {
    const { data: dataReceiver } = await api.getReceiverRecord(
      token,
      refNumber
    );

    if (dataReceiver) {
      const {
        id,
        firstname,
        lastname,
        gender,
        mobile,
        phone,
        address,
        banks,
        ewallets,
        favorited,
        relationship,
      } = dataReceiver;

      const isReceiverOfIndividualType = gender === MALE || gender === FEMALE;
      const isReceiverOfBusinessType = gender === BUSINESS_OF_RECEIVER_TYPE;

      const newInformation = {
        receiverId: id,
        firstName: firstname,
        lastName: lastname,
        fullName: getFullNameReceiver({
          isReceiverOfIndividualType,
          isReceiverOfBusinessType,
          lastName: lastname,
          firstName: firstname,
          countryCode: address?.country?.code,
        }),
        country: address?.country,
        countryName: address?.country?.name,
        gender,
        address,
        fullAddress: address?.fullAddress,
        mobile,
        mobileNumber: mobile?.number,
        phone,
        phoneNumber: phone?.number,
        banks,
        ewallets,
        favorited,
        relationship,
      };

      dispatchStore({ type: SET_CURRENT_RECEIVER, payload: newInformation });
    }
  } catch (error) {
    checkMaintenance(error);
    console.error(error?.message);
  }
};

export const fetchListPickupOffice = async (
  countryCode,
  setListPickupOffice
) => {
  try {
    const { data } = await api.getPickupOffices(countryCode);

    if (data) {
      setListPickupOffice && setListPickupOffice(data);
    }
  } catch (error) {
    checkMaintenance(error);
    console.error(error?.message);
  }
};

export const isIdSelected = (cId, sId) => {
  return sId && cId === sId;
};

export const getCurrenciesSupportForPayoutMethod = (dmList, dmCode) => {
  const dmConfig = dmList?.filter((dm) => dm.code === dmCode)?.[0];

  return dmConfig?.currencies || [];
};
export const getReceiveLimitsWithPayoutMethod = ({
  dmCode,
  pickupOfficeId,
  isReceiverOfBusinessType,
  isReceiverOfIndividualType,
  dmList,
  receiveLimits,
}) => {
  const receiveLimitConfig = receiveLimits?.filter(
    (rl) =>
      rl.dmCode === dmCode &&
      (pickupOfficeId ? rl.pickupOfficeId === pickupOfficeId : true)
  );
  const currenciesDmList = getCurrenciesSupportForPayoutMethod(dmList, dmCode);
  const currenciesReceiveLimit = arrayUtils.removeDuplicateInArrayObject(
    receiveLimitConfig,
    'currencyCode'
  );
  const currenciesSupport = [];

  const checkCurrencySupport = ({ currencyDmList, currencyReceiveLimit }) => {
    if (isReceiverOfBusinessType && dmCode === METHOD_BANK) {
      return (
        currencyReceiveLimit?.currencyCode === currencyDmList?.code &&
        currencyDmList?.businessReceiver
      );
    }

    if (isReceiverOfIndividualType && dmCode === METHOD_BANK) {
      return (
        currencyReceiveLimit?.currencyCode === currencyDmList?.code &&
        currencyDmList?.individualReceiver
      );
    }

    return currencyReceiveLimit?.currencyCode === currencyDmList?.code;
  };
  if (isEmpty(currenciesDmList)) return [];
  currenciesDmList?.forEach((currencyDmList) => {
    const currencyReceiveLimitSupport =
      currenciesReceiveLimit?.find((currencyReceiveLimit) =>
        checkCurrencySupport({ currencyDmList, currencyReceiveLimit })
      ) || null;
    if (currencyReceiveLimitSupport) {
      const currencySupport = {
        ...currencyDmList,
        ...currencyReceiveLimitSupport,
      };
      currenciesSupport.push(currencySupport);
    }
  });

  return !isEmpty(currenciesSupport) ? currenciesSupport : [];
};

export const fetchRate = async ({
  countryCode,
  currency,
  setRate,
  rateProp,
  currencyProp,
  information,
  setInformation,
  callback,
}) => {
  try {
    const payload = {
      countryCode: currency?.countryCode || countryCode,
      currencyCode: currency?.currencyCode,
      dmCode: currency?.dmCode,
    };

    const { data } = await api.lookupRate(
      payload.countryCode,
      payload.dmCode,
      payload.currencyCode
    );

    if (data) {
      setRate(data.rateValue);

      if (information && setInformation) {
        const newInformation = {
          ...information,
          ...(rateProp && { [rateProp]: data }),
          ...(currencyProp && { [currencyProp]: currency }),
        };
        setInformation(newInformation);
      }

      const payloadStore = {
        ...stateStore.addTransactionInformation,
        remittance: {
          ...stateStore.addTransactionInformation.remittance,
          ...(rateProp && { [rateProp]: data }),
          ...(currencyProp && { [currencyProp]: currency }),
        },
      };
      dispatchStore({
        type: SET_ADD_TRANSACTION_INFORMATION,
        payload: payloadStore,
      });

      callback && callback();
    }
  } catch (error) {
    checkMaintenance(error);
    console.error(error?.message);
  }
};

export const getPayoutItem = (information, payoutMethod) => {
  if (payoutMethod === METHOD_HOME) {
    return information[ADD_TRANSACTION_METHOD_HOME_NAME];
  }

  if (payoutMethod === METHOD_PICKUP) {
    return information[ADD_TRANSACTION_METHOD_PICKUP_NAME];
  }

  if (payoutMethod === METHOD_BANK) {
    return information[ADD_TRANSACTION_METHOD_BANK_NAME];
  }

  if (payoutMethod === METHOD_EWALLET) {
    return information[ADD_TRANSACTION_METHOD_EWALLET_NAME];
  }

  return null;
};

export const getCurrencyCode = (currencySelected, currencyCode) => {
  if (currencySelected === currencyCode) return currencySelected;

  return '';
};

export const getCurrenciesSupportString = ({
  t,
  countryConfig,
  receiveLimits,
}) => {
  if (!countryConfig || isEmpty(receiveLimits) || !t) return '';

  const length = receiveLimits?.length || 0;
  if (length > 1) return `(${receiveLimits.map((rl) => rl.code).join(' / ')})`;
  return stringUtils.replaceKeyword(t('label_currency_only'), [
    {
      key: 'currency',
      value: receiveLimits?.[0]?.code,
    },
  ]);
};

export const checkReceiverDoNotSupportSendMoneyOnline = ({
  receiver,
  countryConfig,
}) => {
  return (
    !isEmpty(receiver) &&
    !isEmpty(countryConfig) &&
    !countryConfig?.dmList?.length
  );
};

export const checkBusinessReceiverDoNotSupportSendMoneyOnline = ({
  t,
  receiver,
  countryConfig,
  dmCode = METHOD_BANK,
}) => {
  const isReceiverOfBusinessType =
    !isEmpty(receiver) && receiver?.gender === BUSINESS_OF_RECEIVER_TYPE;
  const isReceiverOfIndividualType =
    !isEmpty(receiver) &&
    (receiver?.gender === MALE || receiver?.gender === FEMALE);

  const receiveLimits = getReceiveLimitsWithPayoutMethod({
    dmCode: dmCode,
    receiveLimits: countryConfig?.receiveLimits,
    isReceiverOfBusinessType,
    isReceiverOfIndividualType,
    dmList: countryConfig?.dmList,
  });
  const currenciesSupportText = getCurrenciesSupportString({
    t,
    countryConfig,
    receiveLimits,
  });

  return (
    !isEmpty(countryConfig) &&
    isReceiverOfBusinessType &&
    !currenciesSupportText
  );
};
