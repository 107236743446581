/* eslint-disable no-unused-vars */
import { api } from 'api';
import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import InformPopup from 'components/common/InformPopup';
import {
  ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_RECEIVER_GET_NAME,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  PROMOTION,
  VOUCHER,
} from 'constants';
import { filterDiscountsAvailable } from 'helpers';
import { checkMaintenance } from 'helpers/maintenance';
import { isArray, isEmpty } from 'lodash';
import { dispatchStore, stateStore } from 'store';
import {
  SET_ADD_TRANSACTION_INFORMATION,
  SET_SHOW_LOADING,
} from 'store/action';
import { arrayUtils, numberUtils } from 'utils';

export const fetchAllDiscounts = async (token, cb) => {
  try {
    const { data } = await api.getAllDiscounts(token);

    if (data && !arrayUtils.isArrayEmpty(data)) {
      cb(data);
    }
  } catch (error) {
    checkMaintenance(error);
    console.error(error?.message);
  }
};

export const fetchAllEvents = async (token, cb) => {
  try {
    const { data } = await api.getAllEvents(token);

    if (data && !arrayUtils.isArrayEmpty(data)) {
      cb(data);
    }
  } catch (error) {
    checkMaintenance(error);
    console.error(error?.message);
  }
};

export const fetchDiscountsAvailable = async ({
  token,
  countryCode,
  payoutMethod,
  currencyCode,
  isOnlyCurrency,
  sendAmount,
  receiverAmount,
  paymentMethod = '',
}) => {
  try {
    const { data } = await api.getDiscountsActive(token);

    const feeAmount =
      stateStore.addTransactionInformation.remittance.feeAmount?.feeAmount || 0;

    const discountsAvailable = filterDiscountsAvailable({
      discounts: data,
      countryCode,
      deliveryMethod: payoutMethod,
      currencyCode,
      isOnlyCurrency,
      sendAmount,
      receiverAmount,
      feeAmount,
      paymentMethod,
    });

    const discountsInactive = arrayUtils.filterFromAnotherArrayByProp(
      data,
      discountsAvailable,
      'code'
    );

    if (data && !arrayUtils.isArrayEmpty(data)) {
      dispatchStore({
        type: SET_ADD_TRANSACTION_INFORMATION,
        payload: {
          ...stateStore?.addTransactionInformation,
          paymentMethod: {
            ...stateStore?.addTransactionInformation?.paymentMethod,
            discountsAvailable,
            discountsInactive,
            discountApplied: null,
          },
        },
      });
    }

    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
  } catch (error) {
    checkMaintenance(error);
    console.error(error?.message);

    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
  }
};

export const fetchDiscountsActive = async ({
  token,
  countryCode,
  payoutMethod,
  currencyCode,
  isOnlyCurrency,
  sendAmount,
  receiverAmount,
  paymentMethod = '',
}) => {
  try {
    const { data } = await api.getDiscountsActive(token);

    if (data && !arrayUtils.isArrayEmpty(data)) {
      dispatchStore({
        type: SET_ADD_TRANSACTION_INFORMATION,
        payload: {
          ...stateStore.addTransactionInformation,
          paymentMethod: {
            ...stateStore.addTransactionInformation.paymentMethod,
            discountsAvailable: filterDiscountsAvailable({
              discounts: data,
              countryCode,
              deliveryMethod: payoutMethod,
              currencyCode,
              isOnlyCurrency,
              sendAmount,
              receiverAmount,
              paymentMethod,
            }),
            discountApplied: null,
          },
        },
      });
    }

    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
  } catch (error) {
    checkMaintenance(error);
    console.error(error?.message);
    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
  }
};

export const fetchDiscountsActiveWithoutPaymentMethod = async ({
  token,
  countryCode,
  payoutMethod,
  currencyCode,
  isOnlyCurrency,
  sendAmount,
  receiverAmount,
}) => {
  fetchDiscountsActive({
    token,
    countryCode,
    payoutMethod,
    currencyCode,
    isOnlyCurrency,
    sendAmount,
    receiverAmount,
    paymentMethod: '',
  });
};

export const checkDiscountAppliedValid = async (
  token,
  {
    code,
    countryCode,
    currencies,
    deliveryMethod,
    paymentMethod,
    receivingAmount,
    txnAmount,
    txnFee,
  },
  onApplyError,
  onApplySuccess = () => {}
) => {
  dispatchStore({ type: SET_SHOW_LOADING, payload: true });
  try {
    const { data } = await api.applyDiscount({
      token,
      code,
      countryCode,
      currencies,
      deliveryMethod,
      paymentMethod,
      receivingAmount,
      txnAmount,
      txnFee,
    });

    if (data) {
      const statePayload = {
        ...stateStore.addTransactionInformation,
        paymentMethod: {
          ...stateStore.addTransactionInformation.paymentMethod,
          discountApplied: data,
        },
      };

      dispatchStore({
        type: SET_ADD_TRANSACTION_INFORMATION,
        payload: statePayload,
      });

      dispatchStore({ type: SET_SHOW_LOADING, payload: false });

      onApplySuccess();
    }

    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
  } catch (error) {
    checkMaintenance(error);

    const { message, response } = error || {};
    const { data } = response || {};
    const { errorCode } = data || {};

    console.error(message);

    dispatchStore({ type: SET_SHOW_LOADING, payload: false });

    onApplyError(errorCode || '');
  }
};

export const getDiscountsTypePromotion = (discounts) =>
  discounts.filter((d) => d.type === PROMOTION);
export const getDiscountsTypeVoucher = (discounts) =>
  discounts.filter((d) => d.type === VOUCHER);
export const getDiscountsTypeOther = (discounts) =>
  discounts.filter((d) => d.type !== PROMOTION && d.type === VOUCHER);

export const handleApplyDiscount = ({
  token,
  item,
  onApplyError,
  onApplySuccess = () => {},
  isAutoTriggerApplyDiscount = false,
}) => {
  let { code, country, currencyCode, deliveryMethod, paymentMethod } =
    item || {};

  const { addTransactionInformation, currentReceiver } = stateStore || {};
  const {
    receiverPayoutMethod,
    remittance,
    paymentMethod: paymentMethodStore,
  } = addTransactionInformation || {};

  if (!country || isAutoTriggerApplyDiscount) {
    country = currentReceiver?.country?.code;
  }

  if (!currencyCode) {
    currencyCode = remittance?.currentCurrencySelected?.currencyCode;
  }

  if (!deliveryMethod) {
    deliveryMethod = receiverPayoutMethod?.[ADD_TRANSACTION_PAYOUT_METHOD_NAME];
  }

  if (!paymentMethod) {
    paymentMethod = paymentMethodStore?.[ADD_TRANSACTION_PAYMENT_METHOD_NAME];
  }

  const sendAmount = numberUtils.mathRound(
    remittance[ADD_TRANSACTION_YOU_SEND_NAME] || 0
  );
  const sendAnotherAmount = numberUtils.mathRound(
    remittance[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0
  );
  const sendTotalAmount = numberUtils.mathRound(sendAmount + sendAnotherAmount);

  const receiverAmount = Number(
    remittance[ADD_TRANSACTION_RECEIVER_GET_NAME] || 0
  );
  const feeAmount = Number(remittance?.feeAmount?.feeAmount || 0);

  const checkPayload = {
    code,
    countryCode: country,
    currencies: [currencyCode],
    deliveryMethod,
    paymentMethod: [paymentMethod],
    txnAmount: sendTotalAmount,
    receivingAmount: receiverAmount,
    txnFee: feeAmount,
  };
  checkDiscountAppliedValid(token, checkPayload, onApplyError, onApplySuccess);
};

export const handleRemoveDiscountApplied = (payload) => {
  const { payoutMethod, paymentMethod, callback } = payload || {};

  const statePayload = {
    ...stateStore.addTransactionInformation,
    receiverPayoutMethod: {
      ...stateStore.addTransactionInformation.receiverPayoutMethod,
      ...(payoutMethod && {
        [ADD_TRANSACTION_PAYOUT_METHOD_NAME]: payoutMethod,
        currentPayoutItemSelectedIndex: -1,
      }),
    },
    paymentMethod: {
      ...stateStore.addTransactionInformation.paymentMethod,
      ...(paymentMethod && {
        [ADD_TRANSACTION_PAYMENT_METHOD_NAME]: paymentMethod,
      }),
      ...(Boolean(callback) && { discountsAvailable: [] }),
      discountApplied: null,
    },
  };
  dispatchStore({
    type: SET_ADD_TRANSACTION_INFORMATION,
    payload: statePayload,
  });

  callback && callback();
};

export const handleOnCloseDiscountImportantPopup = ({ callback }) => {
  callback();
};

export const renderDiscountImportantPopup = ({
  t,
  isOpen,
  setOpen,
  payoutMethod,
  onCloseExt,
  onClickExt,
  contentReplace,
}) => (
  <InformPopup
    isOpen={isOpen}
    imageSrc={NoticeIcon}
    imagesStyles={{
      width: '124px',
      height: '100px',
      marginBottom: '8px',
    }}
    text={''}
    content={contentReplace || t('discount_important_desc')}
    buttonLabel1={t('button_cancel')}
    onClose={() =>
      handleOnCloseDiscountImportantPopup({
        callback: () => {
          setOpen(false);
          onCloseExt && onCloseExt();
        },
      })
    }
    buttonLabel2={t('button_ok')}
    onClick={() =>
      handleRemoveDiscountApplied({
        payoutMethod,
        callback: () => {
          setOpen(false);
          onClickExt && onClickExt();
        },
      })
    }
    isBackdropOverlap={true}
    zIndexBackdropOverlap={1055}
    isClickBackdropToClose={false}
  />
);

export const findDiscountDetails = (discounts, code) =>
  discounts?.find((d) => d.code === code) || null;

export const checkDiscountAppliedValidWhenPaymentMethodChangeAfterTxnCreated =
  ({ isUpdateTxn, discount, currentPaymentMethodSelected }) =>
    Boolean(
      isUpdateTxn &&
        discount &&
        (!discount?.paymentMethod ||
          discount?.paymentMethod === currentPaymentMethodSelected)
    );

export const checkDiscountAppliedInvalidWhenPaymentMethodChangeBeforeTxnCreated =
  ({
    isUpdateTxn,
    discountApplied,
    prevPaymentMethodSelected,
    currentPaymentMethodSelected,
  }) =>
    Boolean(
      !isUpdateTxn &&
        discountApplied &&
        prevPaymentMethodSelected !== currentPaymentMethodSelected
    );

export const getDiscountsActiveList = ({
  discountsAvailable,
  discountsInactive,
}) => [
  ...(isArray(discountsAvailable) ? discountsAvailable : []),
  ...(isArray(discountsInactive) ? discountsInactive : []),
];
export const getDiscountDefault = ({
  discountsAvailable,
  discountsInactive,
}) => {
  const discountsActive = getDiscountsActiveList({
    discountsAvailable,
    discountsInactive,
  });
  return !isEmpty(discountsActive) && isArray(discountsActive)
    ? discountsActive?.find((discount) => discount?.isDefault)
    : null;
};
