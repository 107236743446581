/* eslint-disable no-unused-vars */
import { api } from 'api';
import EditIcon from 'assets/ver2/icons/edit-black-icon.svg';
import LocationIcon from 'assets/ver2/icons/location-outline-black-icon.svg';
import MobileIcon from 'assets/ver2/icons/mobile-icon.svg';
import { format } from 'helpers';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Avatar from 'components/Profile/components/Avatar';
import FormEditProfileAddress from 'components/Profile/components/FormEditProfileAddress';
import FormEditProfileInformation from 'components/Profile/components/FormEditProfileInformation';
import { checkMaintenance } from 'helpers/maintenance';
import { useDetectDevice } from 'hooks/useDetectDevice';
import { isEmpty } from 'lodash';
import PersonalDetailsMobile from './mobile';

const PersonalDetails = () => {
  const { t } = useLang();
  const { state } = useStore();
  const { currentUser, configOccupation } = state;
  const { firstName, lastName, mobile, email, address } = currentUser || {};
  const { token } = useAuth();
  const { isHHMTTheme } = useTheme();
  const { isDesktop } = useDetectDevice();

  const [currentJob, setCurrentJob] = useState(null);
  const [
    isShowFormEditProfileInformationPopup,
    setShowFormEditProfileInformationPopup,
  ] = useState(false);
  const [
    isShowFormEditProfileAddressPopup,
    setShowFormEditProfileAddressPopup,
  ] = useState(false);

  const fetchProfileOccupation = async (token) => {
    try {
      const { data } = await api.getOccupation(token);

      if (data) {
        const occupationInit = configOccupation?.find(
          (o) => o.key === data.job
        );
        const previousOccupationInit = configOccupation?.find(
          (o) => o.key === data.previousJob
        );

        setCurrentJob({
          job: occupationInit,
          previousJob: previousOccupationInit,
        });
      }
    } catch (error) {
      checkMaintenance(error);
      console.error(error?.message);
    }
  };
  const initialProfileInformation = async (token) => {
    await Promise.all([fetchProfileOccupation(token)]);
  };
  useEffect(() => {
    initialProfileInformation(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, currentUser, configOccupation]);

  const handleOnOpenFormEditProfileInformationPopup = async () => {
    setShowFormEditProfileInformationPopup(true);
  };
  const handleOnCloseFormEditProfileInformationPopup = () => {
    setShowFormEditProfileInformationPopup(false);
  };

  const handleOnOpenFormEditProfileAddressPopup = async () => {
    setShowFormEditProfileAddressPopup(true);
  };
  const handleOnCloseFormEditProfileAddressPopup = () => {
    setShowFormEditProfileAddressPopup(false);
  };

  const renderBodyContent = () => {
    if (!isDesktop)
      return (
        <PersonalDetailsMobile
          handleOnOpenFormEditProfileInformationPopup={
            handleOnOpenFormEditProfileInformationPopup
          }
          handleOnOpenFormEditProfileAddressPopup={
            handleOnOpenFormEditProfileAddressPopup
          }
        />
      );
    return (
      <PersonalDetailsStyled>
        <Avatar
          width={150}
          height={150}
          background="linear-gradient(180deg, #DEE4EF 0%, #94A2B9 100%)"
          isShowName={false}
        />
        <Information>
          <FieldValue $isBold={true}>{`${firstName} ${lastName}`}</FieldValue>
          <FieldValue $isUppercase={true} style={{ marginTop: '4px' }}>
            {email}
          </FieldValue>
          <Divider />
          <SubTitle>
            {t('label_contact_details')}{' '}
            <EditIconStyled
              src={EditIcon}
              alt="edit-icon"
              style={{
                filter: isHHMTTheme
                  ? 'var(--ds-f-c-yellow)'
                  : 'var(--ds-f-c-blue-sky)',
              }}
              onClick={handleOnOpenFormEditProfileInformationPopup}
            />
          </SubTitle>
          <FieldWrap $width="60%" style={{ marginBottom: '24px' }}>
            {mobile && (
              <FieldValue $isFlexBox={true}>
                <img src={MobileIcon} width={20} height={20} />
                <FieldValue>
                  {mobile ? format.toMobileNumberStr(mobile) : '-'}
                </FieldValue>
              </FieldValue>
            )}
          </FieldWrap>
          <SubTitle>
            {t('label_address')}{' '}
            <EditIconStyled
              src={EditIcon}
              alt="edit-icon"
              style={{
                filter: isHHMTTheme
                  ? 'var(--ds-f-c-yellow)'
                  : 'var(--ds-f-c-blue-sky)',
              }}
              onClick={handleOnOpenFormEditProfileAddressPopup}
            />
          </SubTitle>
          <FieldWrap>
            <FieldValue $isFlexBox={true}>
              <img src={LocationIcon} width={20} height={20} />
              <FieldValue style={{ textTransform: 'uppercase' }}>
                {address?.fullAddress}
              </FieldValue>
            </FieldValue>
          </FieldWrap>
        </Information>
      </PersonalDetailsStyled>
    );
  };

  if (isEmpty(currentUser)) return null;
  return (
    <>
      {isShowFormEditProfileInformationPopup && (
        <FormEditProfileInformation
          isOpen={isShowFormEditProfileInformationPopup}
          onClose={handleOnCloseFormEditProfileInformationPopup}
          currentJob={currentJob}
          fetchProfileOccupation={fetchProfileOccupation}
          titleHeader={t('label_edit_contact_information')}
          isVer2={true}
          isEditMobilePhoneNumberOnly={true}
        />
      )}

      {isShowFormEditProfileAddressPopup && (
        <FormEditProfileAddress
          isOpen={isShowFormEditProfileAddressPopup}
          onClose={handleOnCloseFormEditProfileAddressPopup}
          titleHeader={t('label_edit_residential_address')}
          isVer2={true}
        />
      )}

      {renderBodyContent()}
    </>
  );
};

const PersonalDetailsStyled = styled.div`
  display: flex;
  gap: 40px;
  align-items: start;
  padding: 16px;
  box-shadow: 0px 4px 15px 0px #0000001a;
  width: 774px;
  border-radius: 8px;
`;

const Information = styled.div`
  width: 100%;
`;

const SubTitle = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: initial;
  color: var(--ds-c-blue);

  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const EditIconStyled = styled.img`
  margin-left: 8px;
  cursor: pointer;
  filter: var(--ds-f-c-yellow);
`;

const FieldWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => props.$width || '100%'};
  height: fit-content;
`;
const FieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: ${(props) => (props.$isBold ? 700 : 500)};
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);
  text-transform: ${(props) => (props.$isUppercase ? 'uppercase' : 'initial')};

  width: 100%;
  margin: 0;
  padding: 0;

  & span {
    color: #7a8189;
  }

  ${(props) =>
    props.$isFlexBox &&
    `
    display: flex;
    gap: 8px;
  `}
`;

const Divider = styled.div`
  border-bottom: 1px solid var(--ds-bg-2);
  margin-block: 16px;
`;

export default PersonalDetails;
