import CopyIcon from 'assets/icons/copy-hhmt-icon.svg';
import NoteIcon from 'assets/icons/warnning-yellow-icon.svg';
import { DESKTOP_MIN_WIDTH, PAYMENT_AMOUNT_NAME } from 'constants';
import { format } from 'helpers';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useLang from 'hooks/useLang';
import { useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import { numberUtils } from 'utils';

const FormInputCopyHighlight = ({
  className,
  field,
  value,
  inputStyles = {},
}) => {
  const { t } = useLang();

  const [isShowCopySuccess, setShowCopySuccess] = useState(false);
  const target = useRef(null);

  // const isPaymentEmailField = field.name === PAYMENT_EMAIL_NAME;
  const isPaymentAmountField = field.name === PAYMENT_AMOUNT_NAME;

  const parseAmountFloatValue = (value) => {
    return value && format.toAmountFloatStr(numberUtils.mathRound(value));
  };

  const valueFormat =
    numberUtils.isNumber(value) && isPaymentAmountField
      ? `AUD ${parseAmountFloatValue(value)}`
      : value;

  const { debounce } = useDebounceCallback(() => {
    handleCopySuccess();
  }, 0.3);

  const handleCopySuccess = () => {
    setShowCopySuccess(false);
  };

  const handleCopy = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(value);
    }

    setShowCopySuccess(true);

    debounce();
  };

  return (
    <>
      <style>
        {`
          #copy-trigger {
            inset: auto auto -28px 170px!important;
          }
        `}
      </style>
      <Overlay target={target.current} show={isShowCopySuccess} placement="top">
        <Tooltip id="copy-trigger">{t('label_copy_success')}</Tooltip>
      </Overlay>

      <FormGroupStyled ref={target}>
        <FormLabel>{t(field.label)}:</FormLabel>

        <Highlight>
          <FormControl
            type={field.type}
            name={field.name}
            className={`${className} border-animation`}
            value={valueFormat}
            placeholder={field.placeholder}
            readOnly={true}
            style={inputStyles}
          />
          <CopyText onClick={handleCopy}>
            {t('label_copy')}{' '}
            <CopyIconStyled src={CopyIcon} width={24} height={24} alt="" />
          </CopyText>
          <NoteWrap>
            <img src={NoteIcon} width={16} height={16} />
            <p>{t('label_bank_account_name_reminder')}</p>
          </NoteWrap>
        </Highlight>
      </FormGroupStyled>
    </>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 10px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 10px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);
  margin-bottom: 4px;

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const Highlight = styled.div`
  --border-color-primary: #fcb519;
  --border-color-second: #fcb51920;
  --border-width: 2px;
  --radius: 12px;

  & {
    position: relative;
    border-radius: var(--radius);
    border: 1px solid var(--border-color-primary);
    padding: 12px 6px 12px 10px;
    width: 100%;
    height: fit-content;
  }
  &::before {
    content: '';
    position: absolute;
    inset: calc(var(--border-width) * 0.01);
    z-index: 1;
    border: var(--border-width) solid var(--border-color-second);
    border-radius: calc(var(--radius) * 0.95);
    background-image: conic-gradient(
      from var(--angle),
      #fff 40%,
      var(--border-color-primary),
      #fff
    );
    background-origin: border-box;
    -webkit-mask: linear-gradient(#fff, #fff) content-box,
      linear-gradient(#fff, #fff);
    mask: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
    mask-clip: content-box, border-box;
    -webkit-mask-clip: content-box, border-box;
    -webkit-mask-composite: xor;
    animation: spin 2s linear infinite;
  }

  @property --angle {
    syntax: '<angle>';
    inherits: true;
    initial-value: 0turn;
  }
  @keyframes spin {
    to {
      --angle: 1turn;
    }
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  height: 32px;
  border: none;
  border-radius: 10px;
  padding: 0px;
  background: var(--ds-c-white);

  &:focus {
    background: var(--ds-c-white);
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &[readonly] {
    background: var(--ds-c-white);
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const CopyText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  color: var(--ds-c-blue);
  z-index: 1;

  margin: 0;
  padding: 0;
  padding-inline: 8px;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 32px;
  background: #ffffff;
  border: 1px solid var(--ds-c-blue);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 12px;
  right: 6px;

  &:hover {
    color: var(--ds-c-blue-hover);
    border: 1px solid var(--ds-c-blue-hover);
    background: #ffffff;

    & img {
      opacity: 0.5;
    }
  }
`;
const CopyIconStyled = styled.img`
  margin-left: 4px;
`;

const NoteWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  gap: 2px;
  margin-top: 8px;

  & p {
    ${paragraphMixin};
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }
`;

export default FormInputCopyHighlight;
