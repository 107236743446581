import { api } from 'api';
import AddIcon from 'assets/icons/add-black-icon.svg';
import AddCircleOutlineIcon from 'assets/icons/add-receiver-outline-icon.svg';
import {
  fetchReceiverInformation,
  isIdSelected,
} from 'components/FormAddTransaction/func';
import {
  addNewEwalletAccount,
  updateEwalletAccount,
} from 'components/Receivers/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  ADD_EWALLET_EXPIRY_NAME,
  ADD_EWALLET_ID_NAME,
  ADD_EWALLET_NAME,
  ADD_EWALLET_NUMBER_NAME,
  ADD_TRANSACTION_METHOD_EWALLET_NAME,
  ADD_TRANSACTION_RECEIVER_NAME,
  METHOD_EWALLET,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import { arrayUtils, stringUtils } from 'utils';

import DeleteEwalletPopup from 'components/FormEditReceiver/components/DeleteEwalletPopup';
import {
  default as FormAddNewEwalletAccount,
  default as FormUpdateEwalletAccount,
} from 'components/FormEditReceiver/components/FormAddNewEwalletAccount';
import {
  getEwalletsAdd,
  getNumberUnmaskStr,
} from 'components/FormEditReceiver/func';
import CustomActionDropdown from 'components/Receivers/components/CustomActionDropdown';
import InformPopup from 'components/common/InformPopup';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { format, formatter } from 'helpers';
import { checkMaintenance } from 'helpers/maintenance';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import useCheckServiceAlerts from 'hooks/service-alerts/useCheckServiceAlerts';
import useServiceAlertsStore from 'hooks/service-alerts/useServiceAlertsStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import FormInputRadioCheckbox from '../../FormInputRadioCheckbox';

const initialFormField = RESET_EDIT_RECEIVER_INFORMATION_STORE.accountDetails;
const initialFormEwallet =
  RESET_EDIT_RECEIVER_INFORMATION_STORE.accountDetails.ewallet;

const EwalletPayoutInformation = ({
  countryConfig,
  currentReceiver,
  information,
  setInformation,
  validation,
  setValidation,
  isDisabled,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { token } = useAuth();

  const { addTransactionCurrentStep: currentStep } = state;

  const [receiverInformation, setReceiverInformation] =
    useState(initialFormField);
  const [ewalletInformation, setEwalletInformation] =
    useState(initialFormEwallet);
  const [ewalletValidation, setEwalletValidation] =
    useState(initialFormEwallet);
  const [isOpenFormAddNewEwalletPopup, setOpenFormAddNewEwalletPopup] =
    useState(false);
  const [isOpenUpdateEwalletPopup, setOpenUpdateEwalletPopup] = useState(false);
  const [isOpenDeleteEwalletPopup, setOpenDeleteEwalletPopup] = useState(false);
  const [isOpenUpdatePopup, setOpenUpdatePopup] = useState(false);

  const { currentPayoutItemSelectedIndex } = information;

  const { isDesktop } = useDetectDevice();

  const { setPayoutMethodItemByEWALLET } = useFormAddTransactionStore();
  const { serviceAlertsByCountryCode: serviceAlertsStore } =
    useServiceAlertsStore();
  const { checkByPayoutMethodItem } = useCheckServiceAlerts();
  const { checkServiceAlertsOfPayoutMethodItemItemDisable } =
    checkByPayoutMethodItem;

  const config = useGetConfig({ countryConfig });
  const { ewalletNumber, ewalletExpire } = config;

  const numberFormat = formatter.formatParse(ewalletNumber?.format);

  useEffect(() => {
    if (currentReceiver) {
      setReceiverInformation(currentReceiver);
    }
  }, [currentReceiver]);

  useEffect(() => {
    if (currentPayoutItemSelectedIndex === -1) {
      const newInformation = {
        ...information,
        [ADD_TRANSACTION_METHOD_EWALLET_NAME]: null,
        currentPayoutItemSelectedIndex: -1,
      };

      setInformation(newInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPayoutItemSelectedIndex]);

  const handleOnOpenDeleteEwalletPopup = (ewallet) => {
    const newEwalletInformation = {
      id: ewallet?.id,
      ewalletId: ewallet?.ewalletId,
      [ADD_EWALLET_ID_NAME]: ewallet.ewalletId,
      [ADD_EWALLET_NAME]: ewallet.ewalletProviderName,
      [ADD_EWALLET_NUMBER_NAME]: ewallet.ewalletNumber,
      [ADD_EWALLET_EXPIRY_NAME]: ewallet.expiryDate,
    };
    setEwalletInformation(newEwalletInformation);

    setOpenDeleteEwalletPopup(true);
  };

  const handleOnCloseDeleteEwalletPopup = () => {
    setEwalletInformation(initialFormEwallet);

    setOpenDeleteEwalletPopup(false);
  };

  const handleOnClickDeleteEwalletPopup = async () => {
    dispatch({ type: SET_SHOW_LOADING, payload: true });

    try {
      await api.deleteEwallet(
        token,
        currentReceiver?.receiverId,
        ewalletInformation?.id
      );

      setOpenDeleteEwalletPopup(false);

      setValidation({
        ...validation,
        [ADD_TRANSACTION_METHOD_EWALLET_NAME]: '',
      });

      fetchReceiverInformation(
        token,
        currentReceiver?.receiverId,
        information,
        currentStep,
        false,
        ewalletInformation?.id
      );

      dispatch({ type: SET_SHOW_LOADING, payload: false });
    } catch (error) {
      checkMaintenance(error);
      console.error(error?.message);
      dispatch({ type: SET_SHOW_LOADING, payload: false });
    }
  };

  const handleOnOpenFormUpdateEwalletPopup = (ewallet) => {
    const newEwalletInformation = {
      id: ewallet?.id,
      ewalletId: ewallet?.ewalletId,
      [ADD_EWALLET_ID_NAME]: ewallet.ewalletId,
      [ADD_EWALLET_NAME]: ewallet.ewalletProviderName,
      [ADD_EWALLET_NUMBER_NAME]: ewallet.ewalletNumber,
      [ADD_EWALLET_EXPIRY_NAME]: ewallet.expiryDate,
    };
    setEwalletInformation(newEwalletInformation);

    setOpenUpdateEwalletPopup(true);
  };

  const handleOnCloseFormUpdateEwalletPopup = () => {
    setEwalletInformation(initialFormEwallet);

    setOpenUpdateEwalletPopup(false);
  };

  const handleUpdateEwallet = async (ewalletUpdate) => {
    dispatch({ type: SET_SHOW_LOADING, payload: true });

    const ewallet = {
      id: ewalletUpdate?.id,
      ewalletId: ewalletUpdate?.ewalletId,
      ewalletProviderName: ewalletUpdate?.ewalletProviderName,
      ewalletNumber: ewalletUpdate?.ewalletNumber,
      expiryDate: ewalletUpdate?.expiryDate,
    };

    try {
      const { data: ewalletUpdateRes } = await updateEwalletAccount(
        token,
        currentReceiver?.receiverId,
        ewallet
      );

      if (ewalletUpdateRes) {
        const newInformation = {
          ...information,
          [ADD_TRANSACTION_METHOD_EWALLET_NAME]: {
            ...information[ADD_TRANSACTION_METHOD_EWALLET_NAME],
            ewallet: ewalletUpdateRes,
          },
          currentPayoutItemSelectedIndex: ewalletUpdateRes?.id,
        };

        fetchReceiverInformation(
          token,
          currentReceiver?.receiverId,
          newInformation,
          currentStep
        );

        handleOnCloseFormUpdateEwalletPopup();

        handleOnToggleUpdatePopup();
      }

      dispatch({ type: SET_SHOW_LOADING, payload: false });
    } catch (error) {
      checkMaintenance(error);
      console.error(error?.message);
      dispatch({ type: SET_SHOW_LOADING, payload: false });
    }
  };

  const handleOnToggleFormAddNewEwallet = () => {
    setEwalletInformation(initialFormEwallet);

    setOpenFormAddNewEwalletPopup(!isOpenFormAddNewEwalletPopup);
  };

  const handleOnClickFormAddNewEwallet = async (ewallet) => {
    let newInformation = { ...information };

    if (currentReceiver?.ewallets?.length === 0) {
      newInformation = {
        ...newInformation,
        [ADD_TRANSACTION_RECEIVER_NAME]: {
          ...information[ADD_TRANSACTION_RECEIVER_NAME],
          ewallets: [ewallet],
        },
      };
    } else {
      newInformation = {
        ...newInformation,
        [ADD_TRANSACTION_RECEIVER_NAME]: {
          ...information[ADD_TRANSACTION_RECEIVER_NAME],
          ewallets: [...currentReceiver.ewallets, ewallet],
        },
      };
    }
    const currentEwallets =
      newInformation[ADD_TRANSACTION_RECEIVER_NAME].ewallets;

    const ewalletsAdd = getEwalletsAdd(
      currentEwallets,
      currentReceiver?.ewallets
    );

    if (!arrayUtils.isArrayEmpty(ewalletsAdd)) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      const ewalletPromises = ewalletsAdd.map((ewallet) =>
        addNewEwalletAccount(token, currentReceiver?.receiverId, ewallet)
      );

      try {
        await Promise.all(ewalletPromises);

        fetchReceiverInformation(
          token,
          currentReceiver?.receiverId,
          information,
          currentStep
        );

        dispatch({ type: SET_SHOW_LOADING, payload: false });

        setOpenFormAddNewEwalletPopup(false);

        setValidation({
          ...validation,
          [ADD_TRANSACTION_METHOD_EWALLET_NAME]: '',
        });
      } catch (error) {
        checkMaintenance(error);
        console.error(error?.message);
        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    }

    if (arrayUtils.isArrayEmpty(ewalletsAdd)) {
      setOpenFormAddNewEwalletPopup(false);
    }
  };

  const handleOnToggleUpdatePopup = () => {
    setOpenUpdatePopup(!isOpenUpdatePopup);
  };

  const handleEwalletSelected = (id, ewallet) => {
    if (isDisabled) return;

    const newInformation = {
      ...information,
      [ADD_TRANSACTION_METHOD_EWALLET_NAME]: {
        dmCode: METHOD_EWALLET,
        bank: null,
        pickupOffice: null,
        ewallet,
      },
      currentPayoutItemSelectedIndex: id,
    };
    setInformation(newInformation);

    const newValidation = {
      ...validation,
      [ADD_TRANSACTION_METHOD_EWALLET_NAME]: '',
    };
    setValidation(newValidation);

    setPayoutMethodItemByEWALLET(ewallet, id);
  };

  const renderAddNewEwalletButton = (style) => {
    if (style === 2) {
      return (
        <ButtonCommon
          value={t('button_add_new_ewallet_account')}
          color="var(--ds-c-blue)"
          background="var(--ds-c-white)"
          borderNameVariable="--border-filter"
          isFill={false}
          styles={{
            height: '44px',
            borderRadius: '12px',
            fontWeight: 600,
          }}
          buttonIconStyles={{
            height: '44px',
            flexDirection: 'row-reverse',
            gap: '0px',
          }}
          iconSrc={AddIcon}
          iconStyles={{
            filter: 'var(--ds-f-c-blue)',
          }}
          isReverseIcon={true}
          onClick={handleOnToggleFormAddNewEwallet}
        />
      );
    }

    if (
      arrayUtils.isArrayEmpty(
        receiverInformation?.ewallets || currentReceiver?.ewallets
      )
    )
      return null;

    return (
      <ButtonCommon
        value={t('button_add_new_ewallet_account')}
        color="var(--ds-c-blue-hyperlink-default)"
        background="var(--ds-c-white)"
        styles={{
          paddingInline: 0,
          height: '20px',
          borderRadius: 0,
          fontWeight: 500,
        }}
        buttonIconStyles={{
          height: '20px',
          flexDirection: 'row-reverse',
          gap: '6px',
          marginTop: '16px',
        }}
        iconSrc={AddCircleOutlineIcon}
        iconStyles={{
          filter:
            'invert(12%) sepia(87%) saturate(6995%) hue-rotate(234deg) brightness(90%) contrast(113%)',
        }}
        onClick={handleOnToggleFormAddNewEwallet}
      />
    );
  };

  return (
    <>
      <Wrapper>
        {ewalletInformation && isOpenDeleteEwalletPopup && (
          <DeleteEwalletPopup
            isOpen={ewalletInformation && isOpenDeleteEwalletPopup}
            ewalletNumber={ewalletInformation[ADD_EWALLET_NUMBER_NAME]}
            onClose={handleOnCloseDeleteEwalletPopup}
            onClick={handleOnClickDeleteEwalletPopup}
          />
        )}

        {isOpenUpdatePopup && (
          <InformPopup
            isOpen={isOpenUpdatePopup}
            text={t('popup_update_success_title')}
            content={stringUtils.replaceKeyword(
              t('popup_update_success_desc'),
              [
                {
                  key: 'label',
                  value: t('label_ewallet'),
                },
              ]
            )}
            buttonLabel2={t('button_close')}
            onClick={handleOnToggleUpdatePopup}
          />
        )}

        {isOpenFormAddNewEwalletPopup && (
          <FormAddNewEwalletAccount
            isOpen={isOpenFormAddNewEwalletPopup}
            countryConfig={countryConfig}
            information={receiverInformation}
            setInformation={setReceiverInformation}
            ewalletInformation={ewalletInformation}
            setEwalletInformation={setEwalletInformation}
            ewalletValidation={ewalletValidation}
            setEwalletValidation={setEwalletValidation}
            onClose={handleOnToggleFormAddNewEwallet}
            onClick={handleOnClickFormAddNewEwallet}
          />
        )}

        {isOpenUpdateEwalletPopup && (
          <FormUpdateEwalletAccount
            isOpen={isOpenUpdateEwalletPopup}
            isEdit={isOpenUpdateEwalletPopup}
            countryConfig={countryConfig}
            information={receiverInformation}
            setInformation={setReceiverInformation}
            ewalletInformation={ewalletInformation}
            setEwalletInformation={setEwalletInformation}
            ewalletValidation={ewalletValidation}
            setEwalletValidation={setEwalletValidation}
            onClose={handleOnCloseFormUpdateEwalletPopup}
            onClick={handleUpdateEwallet}
          />
        )}

        {arrayUtils.isArrayEmpty(
          receiverInformation?.ewallets || currentReceiver?.ewallets
        ) ? (
          <>
            <NoEwallet
              $isRequired={validation[ADD_TRANSACTION_METHOD_EWALLET_NAME]}
            >
              {t('label_no_ewallet')}
              {renderAddNewEwalletButton(2)}
            </NoEwallet>
          </>
        ) : (
          <>
            {(receiverInformation?.ewallets || currentReceiver?.ewallets)?.map(
              (ewallet) => (
                <EwalletPayoutInformationStyled
                  key={ewallet?.id}
                  $isActive={
                    !checkServiceAlertsOfPayoutMethodItemItemDisable({
                      serviceAlertsStore,
                      payoutItemId: ewallet?.ewalletId,
                    }) &&
                    isIdSelected(currentPayoutItemSelectedIndex, ewallet?.id)
                  }
                  $isDisabled={isDisabled}
                >
                  <GridItem
                    $isDisabled={isDisabled}
                    onClick={() => handleEwalletSelected(ewallet?.id, ewallet)}
                  >
                    <FormInputRadioCheckbox
                      isChecked={
                        !checkServiceAlertsOfPayoutMethodItemItemDisable({
                          serviceAlertsStore,
                          payoutItemId: ewallet?.ewalletId,
                        }) &&
                        isIdSelected(
                          currentPayoutItemSelectedIndex,
                          ewallet?.id
                        )
                      }
                    />
                  </GridItem>
                  <GridItem
                    style={{ position: 'relative', justifyContent: 'start' }}
                    $isDisabled={isDisabled}
                    onClick={() => handleEwalletSelected(ewallet?.id, ewallet)}
                  >
                    <Text className="payout-information-ewallet-name">
                      {ewallet?.ewalletProviderName}
                    </Text>
                    {ewalletNumber?.visible && (
                      <SubText>
                        {t('label_ewallet_id')}:{' '}
                        {ewallet?.ewalletNumber
                          ? formatter.mask(
                              getNumberUnmaskStr(
                                ewallet?.ewalletNumber,
                                numberFormat
                              ),
                              numberFormat
                            )
                          : ''}
                      </SubText>
                    )}
                    {ewalletExpire?.visible && (
                      <SubText>
                        {t('label_expiry_date')}:{' '}
                        {format.toWalletExpDateStr(ewallet?.expiryDate)}
                      </SubText>
                    )}
                  </GridItem>
                  {!isDisabled && (
                    <GridItem $isDisabled={isDisabled}>
                      <CustomActionDropdown
                        styles={{
                          height: '24px',
                          width: '24px',
                          background: 'transparent',
                          position: 'absolute',
                          right: isDesktop ? '-16px' : '-32px',
                        }}
                        dropdownMenuStyles={{
                          transform: 'translate(18px,32px) !important',
                        }}
                        onEdit={() =>
                          handleOnOpenFormUpdateEwalletPopup(ewallet)
                        }
                        onDelete={() => handleOnOpenDeleteEwalletPopup(ewallet)}
                      />
                    </GridItem>
                  )}
                </EwalletPayoutInformationStyled>
              )
            )}
          </>
        )}
      </Wrapper>
      {!isDisabled && renderAddNewEwalletButton()}
    </>
  );
};

const Wrapper = styled.div`
  background: var(--ds-bg-1);
  border-radius: 12px;
  padding: 8px;
`;

const EwalletPayoutInformationStyled = styled.div`
  display: grid;
  grid-template-columns: 50px calc(100% - 100px) 50px;
  grid-gap: 0px;
  width: 100%;
  height: fit-content;
  padding: 10px;
  margin-bottom: 0px;
  border-bottom: 1px solid var(--ds-c-blue-remox-light);
  background-color: transparent;

  ${(props) =>
    props.$isDisabled &&
    `
    background-color: #eeeeee;
    color: #7a8189;
  `};

  &:last-child {
    border-bottom: none;
  }
`;
const GridItem = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: start;

  &:nth-child(1) {
    justify-content: center;
    align-items: center;
  }

  &:last-child {
    justify-content: space-around;
    align-items: start;
  }

  & div {
    & div,
    input {
      cursor: ${(props) => props.$isDisabled && 'not-allowed'};
    }
  }

  & p {
    cursor: ${(props) => props.$isDisabled && 'not-allowed'};
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;
  text-align: left;

  margin: 0;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;
  text-align: left;

  margin: 0;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const NoEwallet = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${(props) =>
    props.$isRequired ? 'var(--ds-c-red)' : 'var(--ds-c-grey-dark)'};

  display: grid;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: calc(100% + 16px);
  height: fit-content;
  background: #ffffff;
  border: ${(props) =>
    props.$isRequired
      ? '1px dashed var(--ds-c-red)'
      : '1px dashed var(--ds-bg-2)'};
  border-radius: 12px;
  margin-top: -8px;
  margin-bottom: -8px;
  margin-left: -8px;
  margin-right: -8px;
  padding-block: 28px;
`;

export default EwalletPayoutInformation;
