import { api } from 'api';
import CancelEditReceiverPopup from 'components/FormEditReceiver/components/CancelEditReceiverPopup';
import FormInputMobileNumberValidation from 'components/FormRegister/LogInDetails/components/FormInputMobileNumberValidation';
import ButtonCommon from 'components/common/ButtonCommon';
import { checkFormInputValidation } from 'components/common/func';
import {
  BODY_PADDING_BOTTOM_MOBILE,
  CONFIG_OCCUPATION_EXTRAS,
  DESKTOP_MIN_WIDTH,
  EDIT_PROFILE_DOB_NAME,
  EDIT_PROFILE_EMAIL_NAME,
  EDIT_PROFILE_EMAIL_NAME_FIELD,
  EDIT_PROFILE_FULL_NAME,
  EDIT_PROFILE_FULL_NAME_FIELD,
  EDIT_PROFILE_GENDER_NAME,
  EDIT_PROFILE_MOBILE_FIELD,
  EDIT_PROFILE_MOBILE_NAME,
  EDIT_PROFILE_OCCUPATION_FIELDS,
  EDIT_PROFILE_OCCUPATION_NAME,
  EDIT_PROFILE_PREVIOUS_EMPLOYED_NAME,
  MOBILE_MIN_WIDTH,
  RESET_EDIT_PROFILE_INFORMATION_STORE,
  TABLET_MAX_WIDTH,
} from 'constants';
import { formatter } from 'helpers';
import { checkMaintenance } from 'helpers/maintenance';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { Fade } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { SET_CURRENT_USER, SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import { stringUtils } from 'utils';
import CustomOccupationDropdown from '../CustomOccupationDropdown';
import FormInputValidation from '../FormInputValidation';
import UpdateProfilePopup from '../UpdateProfilePopup';

const initialFormField = RESET_EDIT_PROFILE_INFORMATION_STORE.personalDetails;

const FormEditProfileInformation = ({
  isOpen,
  onClose,
  titleHeader,
  currentJob,
  fetchProfileOccupation,
  isVer2 = false,
  isEditMobilePhoneNumberOnly = false,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { currentUser } = state;
  const { token } = useAuth();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();
  const { firstName, lastName, mobile, email } = currentUser || {};

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [isOpenCancelPopup, setOpenCancelPopup] = useState(false);
  const [isOpenUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [numberMaskStr, setNumberMaskStr] = useState(null);
  const [isSaveDisabled, setSaveDisabled] = useState(true);

  // eslint-disable-next-line no-useless-escape
  const numberFormat = `[\"#### ### ###\"]`;
  // eslint-disable-next-line no-useless-escape
  const prefixFormat = `[\"04\"]`;

  useEffect(() => {
    if (currentUser) {
      const newInformation = {
        [EDIT_PROFILE_FULL_NAME]: `${firstName} ${lastName}`,
        [EDIT_PROFILE_GENDER_NAME]: '',
        [EDIT_PROFILE_DOB_NAME]: '',
        [EDIT_PROFILE_EMAIL_NAME]: email,
        [EDIT_PROFILE_MOBILE_NAME]: mobile,
        [EDIT_PROFILE_OCCUPATION_NAME]: currentJob?.job,
        [EDIT_PROFILE_PREVIOUS_EMPLOYED_NAME]: currentJob?.previousJob,
      };
      setInformation(newInformation);

      setNumberMaskStr(
        formatter.mask(mobile, formatter.formatParse(numberFormat))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, currentJob]);

  useEffect(() => {
    if (checkInformationChanged(information)) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information]);

  const handleOnToggleUpdatePopup = async () => {
    setOpenUpdatePopup(!isOpenUpdatePopup);
  };
  const handleOnClickUpdatePopup = () => {
    setOpenUpdatePopup(false);

    onClose();
  };

  const handleOnCloseCancelPopup = () => {
    setOpenCancelPopup(false);
  };
  const handleOnToggleCancelPopup = () => {
    if (checkInformationChanged(information)) {
      setOpenCancelPopup(true);
    } else {
      onClose();
    }
  };

  const handleOnClickCancelPopup = () => {
    setInformation(initialFormField);

    setOpenCancelPopup(false);

    onClose();
  };

  const updateProfileInformation = async () => {
    const payload = {
      mobile: information[EDIT_PROFILE_MOBILE_NAME],
    };

    return api.updateProfile(token, payload);
  };

  const updateProfileOccupation = async () => {
    const payload = {
      job: information[EDIT_PROFILE_OCCUPATION_NAME]?.key,
      previousJob: information[EDIT_PROFILE_PREVIOUS_EMPLOYED_NAME]?.key,
    };

    return api.saveOccupation(token, payload);
  };

  const isMobileChanged = (information) => {
    if (information[EDIT_PROFILE_MOBILE_NAME] !== mobile) return true;

    return false;
  };

  const isOccupationChanged = (information) => {
    if (information[EDIT_PROFILE_OCCUPATION_NAME]?.key !== currentJob?.job?.key)
      return true;

    return false;
  };

  const isPreEmployedChanged = (information) => {
    if (
      information[EDIT_PROFILE_PREVIOUS_EMPLOYED_NAME]?.key !==
      currentJob?.previousJob?.key
    )
      return true;

    return false;
  };

  const checkInformationChanged = (information) => {
    return (
      isMobileChanged(information) ||
      isOccupationChanged(information) ||
      isPreEmployedChanged(information)
    );
  };

  const handleSave = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    delete newInformation[EDIT_PROFILE_FULL_NAME];
    delete newValidation[EDIT_PROFILE_FULL_NAME];

    delete newInformation[EDIT_PROFILE_GENDER_NAME];
    delete newValidation[EDIT_PROFILE_GENDER_NAME];

    delete newInformation[EDIT_PROFILE_DOB_NAME];
    delete newValidation[EDIT_PROFILE_DOB_NAME];

    delete newInformation[EDIT_PROFILE_EMAIL_NAME];
    delete newValidation[EDIT_PROFILE_EMAIL_NAME];

    if (
      information[EDIT_PROFILE_OCCUPATION_NAME] &&
      !CONFIG_OCCUPATION_EXTRAS.find(
        (occupation) =>
          occupation === information[EDIT_PROFILE_OCCUPATION_NAME]?.key
      )
    ) {
      delete newInformation[EDIT_PROFILE_PREVIOUS_EMPLOYED_NAME];
      delete newValidation[EDIT_PROFILE_PREVIOUS_EMPLOYED_NAME];
    }

    if (checkInformationChanged(information)) {
      const isAllowSubmit =
        Object.values(newInformation).every((value) => value) &&
        Object.values(newValidation).every((value) => !value);

      if (isAllowSubmit) {
        dispatch({ type: SET_SHOW_LOADING, payload: true });

        try {
          const promises = [];

          if (isMobileChanged(information)) {
            promises.push(updateProfileInformation());
          }

          if (
            isOccupationChanged(information) ||
            isPreEmployedChanged(information)
          ) {
            promises.push(updateProfileOccupation());
          }

          await Promise.all(promises);

          await fetchProfileOccupation(token);

          const { data } = await api.getProfile(token);

          if (data) {
            const authUser = {
              ...data,
              token: token,
            };

            dispatch({ type: SET_CURRENT_USER, payload: authUser });
          }

          handleOnToggleUpdatePopup();

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        } catch (error) {
          checkMaintenance(error);
          console.error(error);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      }

      if (!isAllowSubmit) {
        newValidation = checkFormInputValidation(
          information[EDIT_PROFILE_MOBILE_NAME],
          EDIT_PROFILE_MOBILE_FIELD,
          newValidation
        );

        newValidation = checkFormInputValidation(
          information[EDIT_PROFILE_OCCUPATION_NAME]?.value,
          EDIT_PROFILE_OCCUPATION_FIELDS[0],
          newValidation
        );

        if (
          CONFIG_OCCUPATION_EXTRAS.find(
            (occupation) =>
              occupation === information[EDIT_PROFILE_OCCUPATION_NAME]?.key
          )
        ) {
          newValidation = checkFormInputValidation(
            information[EDIT_PROFILE_PREVIOUS_EMPLOYED_NAME]?.key,
            EDIT_PROFILE_OCCUPATION_FIELDS[1],
            information,
            newValidation
          );
        }

        setValidation(newValidation);
      }
    }
  };

  const renderFormInputMobilePhoneNumber = () => {
    return (
      <FormInputMobileNumberValidation
        field={EDIT_PROFILE_MOBILE_FIELD}
        config={{
          visible: true,
          required: true,
          prefix: prefixFormat,
          format: numberFormat,
          example: '',
          max: 10,
          min: 10,
        }}
        numberMaskStr={numberMaskStr}
        setNumberMaskStr={setNumberMaskStr}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    );
  };
  const renderBodyContent = () => {
    if (isEditMobilePhoneNumberOnly)
      return <>{renderFormInputMobilePhoneNumber()}</>;
    return (
      <>
        <FormInputValidation
          field={EDIT_PROFILE_FULL_NAME_FIELD}
          information={information}
        />
        {/* <FormInputGenderRadio
            field={EDIT_PROFILE_GENDER_FIELD}
            information={information}
          /> */}
        {/* <FormInputValidation
            field={EDIT_PROFILE_DOB_FIELD}
            information={information}
          /> */}
        <FormInputValidation
          field={EDIT_PROFILE_EMAIL_NAME_FIELD}
          information={information}
          styles={{
            textTransform: 'lowercase',
          }}
        />
        {renderFormInputMobilePhoneNumber()}
        <CustomOccupationDropdown
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
        />
      </>
    );
  };

  return (
    <>
      <style>
        {`
          @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${
          TABLET_MAX_WIDTH - 1
        }px) {
            .profile-edit-modal-backdrop {
              top: 70px;
            }
          }

          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal-backdrop {
              opacity: 0!important;
              z-index: 1045!important;
            }

            .modal {
              top: 70px;
              padding-left: 0px;
              background: #fff;
            }
          }

          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
            .profile-edit-modal-backdrop {
              background: #212121;
              opacity: 0.7!important;
              display: initial!important;
            }
          }
        `}
      </style>

      {isOpenCancelPopup && (
        <CancelEditReceiverPopup
          isOpen={isOpenCancelPopup}
          onClose={handleOnCloseCancelPopup}
          onClick={handleOnClickCancelPopup}
          backDropZIndex={1055}
        />
      )}

      {isOpenUpdatePopup && (
        <UpdateProfilePopup
          isOpen={isOpenUpdatePopup}
          onClick={handleOnClickUpdatePopup}
          title={t('popup_update_success_title')}
          desc={stringUtils.replaceKeyword(t('popup_update_success_desc'), [
            { key: 'label', value: t('label_general_information') },
          ])}
          backDropZIndex={1055}
        />
      )}

      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby={isDesktop && 'contained-modal-title-vcenter'}
        backdropClassName="profile-edit-modal-backdrop"
        centered={isDesktop}
        animation={isDesktop}
      >
        <ModalHeader $isVer2={isVer2}>
          {titleHeader || t('label_update_general_details')}
        </ModalHeader>
        <ModalBody>{renderBodyContent()}</ModalBody>
        <ModalFooter>
          <Fade in appear>
            <NextWrap>
              <ButtonCommon
                value={t('button_cancel')}
                onClick={handleOnToggleCancelPopup}
                styles={{
                  margin: '0px',
                  marginRight: isMobile || isTablet ? 0 : '16px',
                  width: isMobile || isTablet ? '50%' : '80px',
                }}
                color="var(--ds-c-blue)"
                background="var(--ds-c-white)"
              />
              <ButtonCommon
                value={t('button_save')}
                color="var(--c-primary)"
                background="var(--bg-primary)"
                isFill={true}
                styles={{
                  margin: '0px',
                  width: isMobile || isTablet ? '50%' : '80px',
                }}
                onClick={handleSave}
                isUseKeyDown
                isDisabled={isSaveDisabled}
              />
            </NextWrap>
          </Fade>
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
  }

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    & .modal-dialog {
      position: absolute;
      top: 0px;
      margin: 0;
      height: 100dvh;
      width: 100%;
      max-width: 100%;

      & .modal-content {
        padding: 24px 24px 0 24px;
        border-radius: 0px;
        box-shadow: none;
        background: var(--ds-c-white);
        width: 100%;
        height: calc(100dvh - 70px);
        margin-inline: auto;
        border: none;
      }

      & .modal {
        top: 70px;
        padding-left: 0px;
        background-color: #fff;
      }
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-content {
      padding: 24px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 470px;
      height: fit-content;
      margin-inline: auto;
    }
  }
`;
const ModalHeader = styled(Modal.Header)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: ${(props) =>
    props.$isVer2 ? 'var(--ds-c-green-default)' : 'var(--ds-c-blue)'};

  padding: 0;
  margin-bottom: 0;
  padding-bottom: 16px;
  border: none !important;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalBody = styled(Modal.Body)`
  padding: 0;

  @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
    padding-bottom: ${BODY_PADDING_BOTTOM_MOBILE}px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  background: var(--ds-c-white);
  margin-block: 0px;
  position: fixed;
  left: 24px;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-inline: unset;
    padding-inline: unset;
    height: 40px;
    left: unset;
    border-top: none;
  }
`;

export default FormEditProfileInformation;
