/* eslint-disable no-unused-vars */
import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import { getMaintenanceHost } from 'helpers/maintenance';
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import { dispatchStore } from 'store';
import { SET_SHOW_LOADING } from 'store/action';
import { functionUtils } from 'utils';
import MaintenancePopup from '../popups/MaintenancePopup';

const MaintenanceModal = ({ isShow, action }) => {
  const { locate } = useLang();
  const { closePortalModalName } = usePortalModal();

  const handleOnClick = () => {
    if (functionUtils.isFunction(action)) {
      action();
    }
    dispatchStore({ type: SET_SHOW_LOADING, payload: true });
    window.location.href = `${getMaintenanceHost()}?lang=${locate}`;
    closePortalModalName();
  };

  return (
    <MaintenancePopup
      isOpen={isShow}
      imageSrc={NoticeIcon}
      imagesStyles={{
        width: '124px',
        height: '100px',
      }}
      onClick={handleOnClick}
      isBackdropOverlap={true}
      zIndexBackdropOverlap={1055}
    />
  );
};

export default MaintenanceModal;
