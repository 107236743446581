import PendingAnimationHHMT from 'assets/icons/pending-animation-hhmt.gif';
import PendingAnimationRemox from 'assets/icons/pending-animation-remox.gif';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';

const PendingScreen = ({ isOpen, pageYOffset }) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();

  return (
    <PendingScreenStyled $isOpen={isOpen}>
      <BackdropStyled pageYOffset={pageYOffset} />

      <PendingStyled>
        <PendingImage
          src={isHHMTTheme ? PendingAnimationHHMT : PendingAnimationRemox}
        />
        <Desc>{t('add_transaction_transaction_process_desc')}</Desc>
      </PendingStyled>
    </PendingScreenStyled>
  );
};
const PendingScreenStyled = styled.div`
  display: ${(props) => !props.$isOpen && 'none'};
  width: 668px;
`;
const BackdropStyled = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100dvh - 70px);
  background: #fff;
  z-index: 10000;
  top: 70px;
`;

const PendingStyled = styled.div`
  position: absolute;
  z-index: 10001;
  top: 104px;
  left: 0;
  color: var(--bg-primary) !important;
  display: grid;
  justify-items: center;
  padding-inline: 24px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    left: calc(50% - 187px);
    padding-inline: unset;
  }
`;
const PendingImage = styled.img`
  width: 187px;
  height: 187px;
  object-fit: contain;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 374px;
    height: 374px;
  }
`;
const Desc = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0e1012;
  text-align: center;

  margin: 24px 0px 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default PendingScreen;
