import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import styled from 'styled-components';

import BankIcon from 'assets/icons/bank-icon.svg';
import ArrowDownSVG from 'assets/ver2/icons/arrow-down-icon.svg';
import {
  getNumberUnmaskStr,
  isShowField,
} from 'components/FormEditReceiver/func';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { formatter } from 'helpers';
import useReceiver from 'hooks/receiver/useReceiver';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';

const CustomBanksAccordion = ({ banks, countryConfig }) => {
  const { t } = useLang();

  const [currentBank, setCurrentBank] = useState(-1);

  const { isReceiverOfBusinessType } = useReceiver();

  const config = useGetConfig({ countryConfig });
  const { accountHolder, accountNumber, accountSwift, accountBsb } = config;

  const accountNumberFormat = formatter.formatParse(accountNumber?.format);
  const bsbNumberFormat = formatter.formatParse(accountBsb?.format);
  const swiftNumberFormat = formatter.formatParse(accountSwift?.format);

  const accountNumberLabel =
    accountNumber?.name ||
    accountNumber?.type ||
    t('label_bank_account_number');
  const acountBsbLabel = accountBsb?.name || accountBsb?.type || 'BSB';
  const acountSwiftLabel = accountSwift?.name || accountSwift?.type || 'SWIFT';

  const handleAccordionItemOnClick = (index) => {
    if (currentBank === index) {
      setCurrentBank(-1);

      return;
    }

    setCurrentBank(index);
  };

  const getLabel = () => {
    if (isReceiverOfBusinessType) return `${t('label_bank_account')}`;

    return `${t('label_bank_account')} (${banks.length})`;
  };

  return (
    <FieldWrap>
      <FieldIcon $isCenter={banks.length <= 1}>
        <img src={BankIcon} width={24} height={24} alt="" />
        {getLabel()}
      </FieldIcon>
      {banks?.length ? (
        <AccordionStyled>
          {banks.map((bank, index) => (
            <AccordionItem
              key={index}
              eventKey={index}
              onClick={() => handleAccordionItemOnClick(index)}
            >
              <AccordionHeader>
                <AccordionHeaderText>{bank?.bankName}</AccordionHeaderText>
                <AccordionHeaderBody>
                  {currentBank === index && (
                    <>
                      <Divider />
                      {accountHolder?.visible && (
                        <FieldBodyWrap>
                          <p>
                            {t('label_bank_account_holder')}:{' '}
                            {bank?.accountName}
                          </p>
                        </FieldBodyWrap>
                      )}
                      {accountNumber?.visible && (
                        <FieldBodyWrap>
                          <p>
                            {accountNumberLabel}:{' '}
                            {bank?.accountNumber
                              ? formatter.mask(
                                  getNumberUnmaskStr(
                                    bank?.accountNumber,
                                    accountNumberFormat
                                  ),
                                  accountNumberFormat
                                )
                              : ''}
                          </p>
                        </FieldBodyWrap>
                      )}
                      {isShowField(accountSwift?.visible, bank?.swiftCode) && (
                        <FieldBodyWrap>
                          <p>
                            {acountSwiftLabel}:{' '}
                            {bank?.swiftCode
                              ? formatter.mask(
                                  getNumberUnmaskStr(
                                    bank?.swiftCode,
                                    swiftNumberFormat
                                  ),
                                  swiftNumberFormat
                                )
                              : ''}
                          </p>
                        </FieldBodyWrap>
                      )}
                      {isShowField(accountBsb?.visible, bank?.branchCode) && (
                        <FieldBodyWrap>
                          <p>
                            {acountBsbLabel}:{' '}
                            {bank?.branchCode
                              ? formatter.mask(
                                  getNumberUnmaskStr(
                                    bank?.branchCode,
                                    bsbNumberFormat
                                  ),
                                  bsbNumberFormat
                                )
                              : ''}
                          </p>
                        </FieldBodyWrap>
                      )}
                    </>
                  )}
                </AccordionHeaderBody>
              </AccordionHeader>
            </AccordionItem>
          ))}
        </AccordionStyled>
      ) : (
        <NoBank>{t('label_no_bank_account')}</NoBank>
      )}
    </FieldWrap>
  );
};

const FieldWrap = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  min-height: 40px;
  margin-top: 16px;
`;
const FieldIcon = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  justify-content: start;
  align-items: center;
  height: 72px;
  width: 170px;
  padding: ${(props) => (props.$isCenter ? '12px 0' : '22px 0')};

  & img {
    margin-right: 8px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const AccordionStyled = styled(Accordion)`
  width: 437px;
`;
const AccordionItem = styled(Accordion.Item)`
  border: none;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
`;
const AccordionHeader = styled(Accordion.Header)`
  height: fit-content;
  padding: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  & button {
    padding: 0;
    background-color: var(--ds-c-white) !important;
    box-shadow: none !important;
    display: block;

    &:focus,
    &:hover {
      z-index: 1;
    }

    &::after,
    &:not(.collapsed)::after {
      background-image: url(${ArrowDownSVG});
      background-position: center;
      background-size: 24px 24px;

      position: absolute;
      top: 10px;
      right: 0px;
    }
  }
`;
const AccordionHeaderText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  padding: 0;
  padding-right: 20px;
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const AccordionHeaderBody = styled.div``;
const FieldBodyWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: fit-content;
  margin-top: 8px;

  & p {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--ds-c-grey-dark);

    cursor: text;
    margin: 0;
    padding: 0;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const NoBank = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #b0b6bd;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 437px;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #eef2f5;
  margin: 16px 0;
`;

export default CustomBanksAccordion;
