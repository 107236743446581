const elementHelper = {
  detectInputFieldType: (regex) => {
    const numberRegex = [`\d`, '0-9', '.'];
    const stringRegex = [
      `\w`,
      'a-z',
      'A-Z',
      '.',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];
    const detectRegex = (regexs) =>
      Boolean(regexs.find((r) => regex.includes(r)));
    if (!regex) return 'text';
    if (detectRegex(numberRegex) && detectRegex(stringRegex)) return 'text';
    if (detectRegex(numberRegex) && !detectRegex(stringRegex)) return 'tel';
    if (!detectRegex(numberRegex) && detectRegex(stringRegex)) return 'text';
    return 'text';
  },
  convertSpecificCharactersInput: (inputType, inputValue = '') => {
    if (!inputValue) return '';
    if (inputType !== 'text') return inputValue;
    if (inputType === 'text') {
      const transform = (input) => {
        const charMap = {
          á: 'as',
          à: 'af',
          ả: 'ar',
          ã: 'ax',
          ạ: 'aj',
          â: 'aa',
          ă: 'aw',
          aư: 'aw',
          đ: 'dd',
          é: 'es',
          è: 'ef',
          ẻ: 'er',
          ẽ: 'ex',
          ẹ: 'ej',
          ê: 'ee',
          í: 'is',
          ì: 'if',
          ỉ: 'ir',
          ĩ: 'ix',
          ị: 'ij',
          ó: 'os',
          ò: 'of',
          ỏ: 'or',
          õ: 'ox',
          ọ: 'oj',
          ô: 'oo',
          ơ: 'ow',
          oư: 'ow',
          ú: 'us',
          ù: 'uf',
          ủ: 'ur',
          ũ: 'ux',
          ụ: 'uj',
          ư: 'uw',
          uư: 'uw',
          ý: 'ys',
          ỳ: 'yf',
          ỷ: 'yr',
          ỹ: 'yx',
          ỵ: 'yj',
        };
        if (input.length === 1)
          return input
            .split('')
            .map((char) => charMap[char?.toLowerCase()] || char?.toLowerCase())
            .join('');
        return charMap[input?.toLowerCase()] || input?.toLowerCase();
      };
      let newValue = inputValue || '';
      return newValue ? transform(newValue)?.toUpperCase() : '';
    }
    return inputValue;
  },
  replaceValueOfInputField: (name = '', value = '') => {
    if (!name) return;
    const ele = document.getElementsByName(name)?.[0];
    const eleTagName = ele.tagName;
    if (!ele || eleTagName !== 'INPUT') return;
    ele.value = value;
  },
};

export default elementHelper;
