import TooltipCommon from 'components/common/TooltipCommon';
import TooltipCommonMobile from 'components/common/TooltipCommon/mobile';
import { checkFormInputValidation } from 'components/common/func';
import {
  DESKTOP_MIN_WIDTH,
  PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME,
  PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME,
  PAYMENT_BY_DEPOSIT_EXPIRY_NAME,
  PAYMENT_BY_DEPOSIT_NAME,
  PAYMENT_BY_DEPOSIT_NAME_FIELD,
} from 'constants';
import card from 'helpers/creditCardHelper';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { numberUtils } from 'utils';

const customFormInputStyleMobile = {
  [PAYMENT_BY_DEPOSIT_EXPIRY_NAME]: {
    width: 'calc(50% - 4px)',
    float: 'left',
    marginRight: '4px',
    zIndex: 1,
  },
  [PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME]: {
    width: 'calc(50% - 4px)',
    float: 'left',
    marginLeft: '4px',
    zIndex: 1,
  },
  [PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME]: {
    width: 'calc(50% - 4px)',
    float: 'left',
    marginLeft: '4px',
    zIndex: 1,
  },
};

const customFormInputStyle = {
  [PAYMENT_BY_DEPOSIT_EXPIRY_NAME]: {
    width: 'calc(50% - 12px)',
    float: 'left',
    marginRight: '12px',
    zIndex: 1,
  },
  [PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME]: {
    width: 'calc(50% - 12px)',
    float: 'left',
    marginLeft: '12px',
    zIndex: 1,
  },
  [PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME]: {
    width: 'calc(50% - 12px)',
    float: 'left',
    marginLeft: '12px',
    zIndex: 1,
  },
};

const FormInputValidation = ({
  field,
  information,
  setInformation,
  validation,
  setValidation,
  nameOnCardCustom,
}) => {
  const { t } = useLang();
  const { state } = useStore();
  const { currentUser } = state;
  const { isMobile, isTablet, isDesktop } = useDetectDevice();

  const userFullName =
    nameOnCardCustom ||
    `${currentUser?.firstName.trim()} ${currentUser?.lastName.trim()}`;

  const isDisabled = [].includes(field.name);

  const handleCardNameValidation = (name, value, validation) => {
    const isPaymentByDepositNameField = name === PAYMENT_BY_DEPOSIT_NAME;

    if (isPaymentByDepositNameField) {
      if (value && numberUtils.isNumber(value)) {
        validation = {
          ...validation,
          [name]: PAYMENT_BY_DEPOSIT_NAME_FIELD.msgInvalid,
        };

        return validation;
      }
      if (value && !card.isHolderNameValid(value, userFullName)) {
        validation = {
          ...validation,
          [name]: PAYMENT_BY_DEPOSIT_NAME_FIELD.msgInvalid2,
        };
      }
    }

    return validation;
  };

  const handleOnChange = (e) => {
    let { value, name } = e.target;

    let newInformation = { ...information };
    let newValidation = { ...validation };

    if (name === PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME) {
      if (!numberUtils.isNumber(value)) return;

      if (value.length > 3) return;
    }

    if (name === PAYMENT_BY_DEPOSIT_NAME) {
      value = value?.toUpperCase();
    }

    newInformation = {
      ...information,
      [name]: value,
    };

    setInformation(newInformation);

    newValidation = checkFormInputValidation(
      newInformation[name],
      field,
      validation
    );

    newValidation = handleCardNameValidation(name, value, newValidation);

    setValidation(newValidation);
  };

  const handleFormLabelOnClick = (e, name) => {
    e.preventDefault();

    if (isDesktop) {
      const inputEle = document.getElementsByName(name)[0];

      if (inputEle) {
        inputEle.focus();
      }
    }
  };

  return (
    <FormGroupStyled
      style={
        isMobile || isTablet
          ? customFormInputStyleMobile[field.name]
          : customFormInputStyle[field.name]
      }
    >
      <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
        {t(field.label)}
        {field.isRequired && <span>*</span>}
        {field.tooltip && (
          <>
            {isMobile || isTablet ? (
              <TooltipCommonMobile
                text={t(field.tooltip)}
                name={field.name}
                label={t(field.label)}
              />
            ) : (
              <TooltipCommon text={t(field.tooltip)} />
            )}
          </>
        )}
      </FormLabel>
      <FormControl
        type={field.type}
        inputMode={field?.inputMode}
        name={field.name}
        value={information ? information[field.name] : ''}
        placeholder={t(field.placeholder)}
        isInvalid={validation[field.name]}
        onChange={handleOnChange}
        readOnly={field.isReadOnly}
        $isDisabled={isDisabled}
      />
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: ${(props) =>
    props.$isDisabled ? 'var(--ds-c-grey-neutral)' : 'var(--ds-c-grey-dark)'};
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: ${(props) =>
    props.$isDisabled ? 'var(--ds-c-grey-disabled)' : 'var(--ds-c-white)'};
  cursor: ${(props) => props.$isDisabled && 'not-allowed'};

  &:focus {
    background: ${(props) =>
      props.$isDisabled && 'var(--ds-c-grey-disabled)!important'};
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 10px !important;
  }

  &[readonly] {
    background: ${(props) =>
      props.$isDisabled && 'var(--ds-c-grey-disabled)!important'};
    border: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputValidation;
