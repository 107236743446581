import { api } from 'api';
import { checkMaintenance } from 'helpers/maintenance';
import { dispatchStore } from 'store';
import { SET_SHOW_LOADING } from 'store/action';

export const fetchBuyRateCurrency = async (currencyCode) => {
  try {
    const { data } = await api.lookupBuyCurrency(currencyCode);

    return data;
  } catch (error) {
    checkMaintenance(error);
    console.error(error?.message);
  }
};

export const fetchBuyCurrencies = async () => {
  try {
    const { data } = await api.getBuyCurrencies();

    dispatchStore({ type: SET_SHOW_LOADING, payload: false });

    return data;
  } catch (error) {
    checkMaintenance(error);
    console.error(error?.message);

    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
  }
};

export const fetchSellRateCurrency = async (currencyCode) => {
  try {
    const { data } = await api.lookupSellCurrency(currencyCode);

    return data;
  } catch (error) {
    checkMaintenance(error);
    console.error(error?.message);
  }
};

export const fetchSellCurrencies = async () => {
  try {
    const { data } = await api.getSellCurrencies();

    dispatchStore({ type: SET_SHOW_LOADING, payload: false });

    return data;
  } catch (error) {
    checkMaintenance(error);
    console.error(error?.message);

    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
  }
};
