import NotFoundImage from 'assets/images/404-image.png';
import ButtonCommon from 'components/common/ButtonCommon';
import NavbarFooter from 'components/common/NavbarFooter/ver2';
import { DASHBOARD_PREFIX, DESKTOP_MIN_WIDTH } from 'constants';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

const NotFoundPage = () => {
  const { state } = useStore();
  const { currentUser } = state;
  const navigate = useNavigate();
  const { t } = useLang();
  const { isMobile, isTablet } = useDetectDevice();

  if (!currentUser) return null;
  return (
    <NotFoundStyled>
      <ContentBody>
        <img
          src={NotFoundImage}
          width={isMobile ? 327 : 470}
          height={isMobile ? 287 : 413}
          alt=""
        />
        <p
          dangerouslySetInnerHTML={{
            __html: t('label_not_found_title'),
          }}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: t('label_not_found_desc'),
          }}
        />
        <ButtonCommon
          value={t('button_back')}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
          styles={{
            width: '150px',
            height: '40px',
            marginInline: 'auto',
            marginTop: '40px',
          }}
          onClick={() => navigate(`${DASHBOARD_PREFIX}`)}
        />
      </ContentBody>
      {(isMobile || isTablet) && <NavbarFooter />}
    </NotFoundStyled>
  );
};

const NotFoundStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100dvh - 70px);
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100dvh - 120px);
    padding-top: 24px;
    padding-bottom: 0px;
    margin-top: 72px;
  }
`;

const ContentBody = styled.div`
  display: grid;
  justify-content: center;
  width: 247px;
  position: relative;

  & p {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;

    color: var(--c-grey-dark);
    margin-top: 40px;
    margin-bottom: 8px;
  }

  & span {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    text-align: center;

    color: var(--c-sub-info);
    margin-bottom: 0px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 284px;

    & p {
      font-size: 32px;
      line-height: 40px;
    }

    & span {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export default NotFoundPage;
