import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import {
  EN_LANG,
  HHMT_THEME,
  I18N_REDIRECTED,
  MAINTENANCE_HTML_CODE,
} from 'constants';
import { dispatchStore, stateStore } from 'store';
import { SET_PORTAL_MODAL, SET_SHOW_LOADING } from 'store/action';
import { domUtils, functionUtils, objectUtils } from 'utils';
import Cookies from './cookies';

const showPortalModalName = ({
  name,
  title,
  content,
  action,
  actionClose,
  modalStyles,
  modalOptions,
}) => {
  dispatchStore({
    type: SET_PORTAL_MODAL,
    payload: {
      isShowPortalModal: true,
      namePortalModal: name,
      ...(title && { title }),
      ...(content && { content }),
      ...(functionUtils.isFunction(action) && { action }),
      ...(functionUtils.isFunction(actionClose) && { actionClose }),
      ...(objectUtils.isObject(modalStyles) && { modalStyles }),
      ...(objectUtils.isObject(modalOptions) && { modalOptions }),
    },
  });
};

export const getMaintenanceHost = () => {
  const agentCode = process.env.REACT_APP_APP_NAME || HHMT_THEME;
  if (agentCode === HHMT_THEME)
    return process.env.REACT_APP_MAINTENANCE_HHMT_HOST;
  return process.env.REACT_APP_MAINTENANCE_REMOX_HOST;
};

export const getMaintenanceI18n = async () => {
  const url = `${getMaintenanceHost()}/i18n.json`;
  try {
    const response = await fetch(url);
    if (!response?.ok) {
      throw new Error(`Response status: ${response?.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error?.message);
  }
  return null;
};

export const checkMaintenance = (error = {}) => {
  const { response } = error || {};
  const { status } = response || {};
  if (status !== MAINTENANCE_HTML_CODE) return;

  let detectMaintenanceInApp = false;
  const listener = () => {
    if (
      stateStore?.portalModal?.isShowPortalModal &&
      stateStore?.portalModal?.namePortalModal ===
        PORTAL_NAME_MODAL_DEFINE.MAINTENANCE_MODAL
    )
      return;
    detectMaintenanceInApp = true;
    showPortalModalName({
      isShow: true,
      name: PORTAL_NAME_MODAL_DEFINE.MAINTENANCE_MODAL,
    });
  };
  document.addEventListener('click', listener);
  document.addEventListener('mousedown', listener);
  document.addEventListener('touchstart', listener);

  if (!detectMaintenanceInApp) {
    const locate =
      localStorage?.getItem(I18N_REDIRECTED) ||
      Cookies.get(I18N_REDIRECTED) ||
      domUtils.getQueryWithName('lang') ||
      EN_LANG;
    dispatchStore({ type: SET_SHOW_LOADING, payload: true });
    window.location.href = `${getMaintenanceHost()}?lang=${locate}`;
    return;
  }
};
