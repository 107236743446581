import { api } from 'api';
import EditIcon from 'assets/icons/edit-outline-icon.svg';
import EmailIcon from 'components/common/Icons/Email';
import JobIcon from 'components/common/Icons/JobIcon';
import LocationIcon from 'components/common/Icons/LocationIcon';
import PhoneIcon from 'components/common/Icons/PhoneIcon';
import ProfileOutlineIcon from 'components/common/Icons/ProfileOutlineIcon';
import { format } from 'helpers';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { SCREEN_ID_PROFILE_DETAILS } from 'constants';
import { checkMaintenance } from 'helpers/maintenance';
import useEvent from 'hooks/useEvent';
import FormEditProfileAddress from '../FormEditProfileAddress';
import FormEditProfileInformation from '../FormEditProfileInformation';

const SCREEN_ID = SCREEN_ID_PROFILE_DETAILS;

const PersonalDetails = () => {
  const { t } = useLang();
  const { state } = useStore();
  const { currentUser, configOccupation } = state;
  const { firstName, lastName, mobile, email, address } = currentUser || {};
  const { token } = useAuth();
  const { isHHMTTheme } = useTheme();

  const [currentJob, setCurrentJob] = useState(null);
  const [
    isShowFormEditProfileInformationPopup,
    setShowFormEditProfileInformationPopup,
  ] = useState(false);
  const [
    isShowFormEditProfileAddressPopup,
    setShowFormEditProfileAddressPopup,
  ] = useState(false);

  const fetchProfileOccupation = async (token) => {
    try {
      const { data } = await api.getOccupation(token);

      if (data) {
        const occupationInit = configOccupation?.find(
          (o) => o.key === data.job
        );
        const previousOccupationInit = configOccupation?.find(
          (o) => o.key === data.previousJob
        );

        setCurrentJob({
          job: occupationInit,
          previousJob: previousOccupationInit,
        });
      }
    } catch (error) {
      checkMaintenance(error);
      console.error(error?.message);
    }
  };

  const initialProfileInformation = async (token) => {
    await Promise.all([fetchProfileOccupation(token)]);
  };

  useEffect(() => {
    initialProfileInformation(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, currentUser, configOccupation]);

  const handleOnOpenFormEditProfileInformationPopup = async () => {
    setShowFormEditProfileInformationPopup(true);
  };

  const handleOnCloseFormEditProfileInformationPopup = () => {
    setShowFormEditProfileInformationPopup(false);
  };

  const handleOnOpenFormEditProfileAddressPopup = async () => {
    setShowFormEditProfileAddressPopup(true);
  };

  const handleOnCloseFormEditProfileAddressPopup = () => {
    setShowFormEditProfileAddressPopup(false);
  };

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent]);

  return (
    <>
      {isShowFormEditProfileInformationPopup && (
        <FormEditProfileInformation
          isOpen={isShowFormEditProfileInformationPopup}
          onClose={handleOnCloseFormEditProfileInformationPopup}
          currentJob={currentJob}
          fetchProfileOccupation={fetchProfileOccupation}
        />
      )}

      {isShowFormEditProfileAddressPopup && (
        <FormEditProfileAddress
          isOpen={isShowFormEditProfileAddressPopup}
          onClose={handleOnCloseFormEditProfileAddressPopup}
        />
      )}

      <PersonalDetailsStyled>
        <Title>{t('label_personal_details')}</Title>
        <SubTitle>
          {t('label_general_information')}{' '}
          <EditIconStyled
            src={EditIcon}
            alt="edit-icon"
            style={{
              filter: isHHMTTheme
                ? 'var(--ds-f-c-yellow)'
                : 'var(--ds-f-c-blue-dark)',
            }}
            onClick={handleOnOpenFormEditProfileInformationPopup}
          />
        </SubTitle>
        <FieldWrap>
          <ProfileOutlineIcon
            color="var(--ds-c-grey-dark)"
            size={{ width: 24, height: 24 }}
          />
          <FieldValue>{`${firstName} ${lastName}`}</FieldValue>
        </FieldWrap>
        {/* <FieldWrap>
          <GenderIcon size={{ width: 24, height: 24 }} />
          <FieldValue>{`-`}</FieldValue>
        </FieldWrap> */}
        {/* <FieldWrap>
          <DobIcon size={{ width: 24, height: 24 }} />
          <FieldValue>{`-`}</FieldValue>
        </FieldWrap> */}
        <FieldWrap>
          <EmailIcon size={{ width: 24, height: 24 }} />
          <FieldValue>{email}</FieldValue>
        </FieldWrap>
        <FieldWrap>
          <PhoneIcon size={{ width: 24, height: 24 }} />
          <FieldValue>
            {mobile ? format.toMobileNumberStr(mobile) : '-'}
          </FieldValue>
        </FieldWrap>
        {currentJob?.job && (
          <FieldWrap>
            <JobIcon size={{ width: 24, height: 24 }} />
            <FieldValue>
              {currentJob?.previousJob
                ? `${currentJob?.job?.value} (${currentJob?.previousJob?.value})`
                : currentJob?.job?.value}
            </FieldValue>
          </FieldWrap>
        )}
        <Divider />
        <SubTitle>
          {t('label_residential_address')}{' '}
          <EditIconStyled
            src={EditIcon}
            alt="edit-icon"
            style={{
              filter: isHHMTTheme
                ? 'var(--ds-f-c-yellow)'
                : 'var(--ds-f-c-blue-dark)',
            }}
            onClick={handleOnOpenFormEditProfileAddressPopup}
          />
        </SubTitle>
        <FieldWrap>
          <LocationIcon size={{ width: 24, height: 24 }} />
          <FieldValue style={{ textTransform: 'uppercase' }}>
            {address?.fullAddress}
          </FieldValue>
        </FieldWrap>
      </PersonalDetailsStyled>
    </>
  );
};

const PersonalDetailsStyled = styled.div`
  display: inline-block;
  width: 438px;
  height: fit-content;
`;

const Title = styled.h4`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: var(--ds-c-blue);
  text-transform: uppercase;

  margin-top: 42px;
  margin-bottom: 16px;
`;

const SubTitle = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #0e1012;

  display: flex;
  align-items: center;
`;
const EditIconStyled = styled.img`
  margin-left: 8px;
  cursor: pointer;
`;

const FieldWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: fit-content;
  min-height: 40px;
  margin-top: 8px;
  padding: 10px 0;
`;
const FieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 16px;

  & span {
    color: #7a8189;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #eef2f5;
  margin-bottom: 24px;
  margin-top: 24px;
`;

export default PersonalDetails;
