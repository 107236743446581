import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH, TABLET_MAX_WIDTH } from 'constants';
import { getMaintenanceI18n } from 'helpers/maintenance';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const MaintenancePopup = ({
  isOpen,
  imageSrc,
  imagesStyles = {},
  alignContent = 'left' || 'center' || 'right',
  onClose,
  onClick,
  isBackdropOverlap,
  zIndexBackdropOverlap,
  zIndexModal = 0,
  isClickBackdropToClose = false,
}) => {
  const { locate } = useLang();

  const [bodyContent, setBodyContent] = useState(null);

  const fetchMaintenanceI18n = async () => {
    const response = await getMaintenanceI18n();
    setBodyContent(response);
  };

  useEffect(() => {
    fetchMaintenanceI18n();
  }, []);

  const handleOnClickBackdropToClose = () => {
    if (isClickBackdropToClose) {
      onClose();
    }
  };

  if (isEmpty(bodyContent)) return null;
  return (
    <>
      <style>
        {zIndexModal
          ? `
          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal {
              z-index: ${zIndexModal || 1101};
            }
          }
        `
          : ``}

        {isBackdropOverlap
          ? `
          .alert-modal-backdrop {
            z-index: ${zIndexBackdropOverlap || 1100}!important;
            background: #212121;
            opacity: 0.7!important;
            display: initial!important;
          }
        `
          : `
        .alert-modal-backdrop {
          background: #212121;
          opacity: 0.7!important;
          display: initial!important;
        }
      `}
      </style>
      <ModalStyled
        show={isOpen}
        onHide={handleOnClickBackdropToClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="alert-modal-backdrop"
        centered
      >
        <ModalBody>
          {imageSrc && (
            <ImageWrap>
              <img
                src={imageSrc}
                alt=""
                width={50}
                height={50}
                style={imagesStyles}
              />
            </ImageWrap>
          )}
          <SubText
            $alignContent={alignContent}
            dangerouslySetInnerHTML={{
              __html: bodyContent?.['message']?.[locate],
            }}
          />
        </ModalBody>
        <ModalFooter
          style={{
            justifyContent: 'center',
          }}
        >
          <ButtonCommon
            value={bodyContent?.['button_ok_got_it']?.[locate]}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: '195px',
              borderRadius: '100px',
            }}
            onClick={onClick}
            isUseKeyDown
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
    & .modal-content {
      padding: 16px;
      border-radius: 8px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 100%;
      height: fit-content;
      margin-inline: auto;
      border: none;

      @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
        width: 436px;
      }
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 24px;
  text-align: left;
`;
const ImageWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-blue);
  margin: 0;
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: ${(props) => props.$alignContent};
  white-space: break-spaces;

  color: var(--ds-c-grey-dark);
  margin: 0;

  & a {
    text-decoration: none;
    color: var(--ds-c-blue);
  }

  & b {
    color: var(--ds-c-blue);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default MaintenancePopup;
