import AddReceiverIcon from 'assets/icons/add-receiver-icon.svg';
import FemaleIcon from 'assets/icons/female-icon.svg';
import MaleIcon from 'assets/icons/male-icon.svg';
import BuyIcon from 'assets/ver2/icons/buy-currency-icon.svg';
import SendIcon from 'assets/ver2/icons/send-black-icon.svg';
import { HHMT_THEME, REMOX_THEME } from 'constants/theme';

export const RESET_BACKDROP_STORE = {
  isShow: false,
  current: '',
  label: '',
  text: '',
  onClick: () => {},
};

export const RESET_PORTAL_MODAL_STORE = {
  isShowPortalModal: false,
  namePortalModal: '',
  title: '',
  content: '',
  action: null,
  actionClose: null,
  modalStyles: null,
  modalOptions: null,
};

export const AGENT_CODE_SWIFT = 'SWIFT';
export const AGENT_CODE_RMOL = 'RMOL';

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

export const STATUS_SUCCESS = 'SUCCESS';
export const STATUS_FAIL = 'FAIL';

export const NAVBAR_FOOTER_ACTIONS = [
  {
    name: 'sendMoney',
    icon: SendIcon,
    text: 'button_send_money',
  },
  {
    name: 'buyMoney',
    icon: BuyIcon,
    text: 'fx_buy_currency_label',
  },
  {
    name: 'addReceiver',
    icon: AddReceiverIcon,
    text: 'button_add_new_receiver',
  },
];

export const PAGINATION_NEXT_BUTTON = 'next';
export const PAGINATION_PREV_BUTTON = 'prev';

export const ENTER_KEY_DOWN = 'Enter';

export const DESKTOP_COLLAPSE_MIN_WIDTH = 1440;
export const DESKTOP_MIN_WIDTH = 1280;
export const TABLET_MAX_WIDTH = 1280;
export const TABLET_MIN_WIDTH = 768;
export const MOBILE_MAX_WIDTH = 768;
export const MOBILE_SMALL_MAX_WIDTH = 375;
export const MOBILE_SMALL_MIN_WIDTH = 320;
export const MOBILE_MIN_WIDTH = 0;

export const EN_LANG = 'en';
export const VI_LANG = 'vi';

export const NOT_FOUND_TITLE = 'This page could not be found';

export const HHMT_EMAIL = 'info@hhmt.com.au';
export const HHMT_PHONE = '02 9728 7928';
export const HHMT_PHONE_2 = '02 9745 9898';
export const HHMT_EMAIL_PAY = 'pay@hhmt.com.au';
export const HHMT_NAME = 'Hai Ha Money Transfer';
export const HHMT_BRAND = 'Hai Ha Money Tranfer';
export const HHMT_BSB = '802985';
export const HHMT_ACCOUNT_NUMBER = '113369264';
export const HHMT_TITLE = 'HHMT Online';
export const HHMT_LOGIN_TITLE = 'Login - Hai Ha Money Transfer';
export const HHMT_FORGOT_PASSWORD_TITLE =
  'Forgot Password - Hai Ha Money Transfer';
export const HHMT_RESET_PASSWORD_TITLE =
  'Reset Your Password - Hai Ha Money Transfer';
export const HHMT_ACOUNT_ACTIVATION_TITLE =
  'Account Activation - Hai Ha Money Transfer';
export const HHMT_REGISTRATION_TITLE =
  'Register an online account - Hai Ha Money Transfer';

export const REMOX_EMAIL = 'info@remox.com.au';
export const REMOX_PHONE = '02 8318 5977';
export const REMOX_PHONE_2 = '02 8318 5977';
export const REMOX_EMAIL_PAY = 'pay@remox.com.au';
export const REMOX_NAME = 'Remox';
export const REMOX_BRAND = 'Remox Money Transfer & Foreign Exchange';
export const REMOX_BSB = '802985';
export const REMOX_ACCOUNT_NUMBER = '115784338';
export const REMOX_TITLE =
  'Remox Money Transfer & Foreign Exchange | Send money to India, Philippines and many countries with the best exchange rates and lowest fee';
export const REMOX_LOGIN_TITLE = 'Login | Remox';
export const REMOX_FORGOT_PASSWORD_TITLE = 'Forgot Password | Remox';
export const REMOX_RESET_PASSWORD_TITLE = 'Reset Your Password | Remox';
export const REMOX_ACOUNT_ACTIVATION_TITLE = 'Account Activation | Remox';
export const REMOX_REGISTRATION_TITLE =
  'Easily Register an Online Account | Remox';

export const COMMON_PHONE = '0401598698';

export const CONFIG_OCCUPATION_EXTRAS = ['UNEMPLOYED', 'RETIRED'];

export const CREADIT_CARD_TYPE_VISA = 'VISA';
export const CREADIT_CARD_TYPE_MASTERCARD = 'MASTERCARD';

export const MALE = 'MALE';
export const FEMALE = 'FEMALE';
export const GENDER_ICON_REFERENCE = {
  [MALE]: MaleIcon,
  [FEMALE]: FemaleIcon,
};

export const GENDER_TRANSLATE_REFERENCE = {
  [MALE]: 'form_gender_male',
  [FEMALE]: 'form_gender_female',
};

export const THEME_REFERENCE = {
  [HHMT_THEME]: 'hhmt',
  HHMT: 'hhmt',
  [REMOX_THEME]: 'remox',
  REMOX: 'remox',
};

export const HEADER_AGENT_CODE_REFERENCE = {
  [HHMT_THEME]: AGENT_CODE_SWIFT,
  HHMT: AGENT_CODE_SWIFT,
  [REMOX_THEME]: AGENT_CODE_RMOL,
  REMOX: AGENT_CODE_RMOL,
};

export const BODY_PADDING_BOTTOM_MOBILE = 300;

export const DEBOUNCE_SEC = 0.65;
export const THROTTLE_SEC = 0.3;

export const isProductionEnv = process.env.REACT_APP_ENV === 'production';
export const isStagingEnv = process.env.REACT_APP_ENV === 'staging';
export const isDevelopmentEnv = process.env.REACT_APP_ENV === 'development';

export const MAINTENANCE_HTML_CODE = 503;
