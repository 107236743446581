/* eslint-disable no-unused-vars */
import { api } from 'api';
import { compareVersions, validateStrict } from 'compare-versions';
import { checkMaintenance } from 'helpers/maintenance';
import { useEffect, useState } from 'react';
import packageJson from '../../package.json';

const useCheckVersion = () => {
  const [currentVersion, setCurrentVersion] = useState(packageJson['version']);
  const fetchBuildVersion = async () => {
    try {
      const { data } = await api.getBuildVersion();

      if (data) {
        const buildVersion = data?.web?.version;

        const isCheckVersionAndForceReloadApp =
          validateStrict(buildVersion) &&
          validateStrict(currentVersion) &&
          compareVersions(buildVersion, currentVersion) > 0;
        if (isCheckVersionAndForceReloadApp) {
          window.location.reload();
        }
      }
    } catch (error) {
      checkMaintenance(error);
      console.error(error?.message);
    }
  };

  useEffect(() => {
    if (!currentVersion) {
      setCurrentVersion(packageJson['version']);
    }
  }, [currentVersion]);

  return {
    fetchBuildVersion,
    currentVersion,
  };
};

export default useCheckVersion;
