import { api } from 'api';
import ButtonCommon from 'components/common/ButtonCommon';
import { checkFormInputValidation } from 'components/common/func';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import {
  FORGOT_PASSWORD_EMAIL_NAME,
  FORGOT_PASSWORD_FIELD,
  LOGIN_PREFIX,
  RESET_FORGOT_PASSWORD_INFORMATION,
} from '../../../constants';
import FormInputValidation from '../components/FormInputValidation';

import { checkMaintenance } from 'helpers/maintenance';
import FormSendEmailSuccessMobile from '../components/FormSendEmailSuccess/mobile';

const initialField = RESET_FORGOT_PASSWORD_INFORMATION;

const FormForgotPasswordMobile = () => {
  const { t } = useLang();
  const { dispatch } = useStore();

  const [information, setInformation] = useState(initialField);
  const [validation, setValidation] = useState(initialField);
  const [isSendEmailSuccess, setSendEmailSuccess] = useState(false);

  const handleSubmit = async () => {
    let newValidation = { ...validation };

    newValidation = checkFormInputValidation(
      information[FORGOT_PASSWORD_FIELD.name],
      FORGOT_PASSWORD_FIELD,
      newValidation
    );

    setValidation(newValidation);

    const isAllowSubmit =
      Object.values(information).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      try {
        const { data } = await api.forgotPassword(
          information[FORGOT_PASSWORD_EMAIL_NAME]
        );

        if (data?.success) {
          setSendEmailSuccess(true);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      } catch (error) {
        checkMaintenance(error);
        setSendEmailSuccess(true);

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    }
  };

  if (isSendEmailSuccess) {
    return <FormSendEmailSuccessMobile information={information} />;
  }
  return (
    <>
      <TitleH3>{t('forgot_password_text_need_help')}</TitleH3>
      <Paragraph>{t('forgot_password_text_solution')}</Paragraph>
      <FormForgotPasswordMobileStyled>
        <FormInputValidation
          key={FORGOT_PASSWORD_FIELD.name}
          field={FORGOT_PASSWORD_FIELD}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          checkFormInputValidation={checkFormInputValidation}
        />
        <LinkBackToLoginStyled to={LOGIN_PREFIX}>
          {t('button_back_to_login')}
        </LinkBackToLoginStyled>
        <ResetSubmit>
          <ButtonCommon
            value={t('button_reset_your_password')}
            onClick={handleSubmit}
            styles={{
              margin: '0px',
              width: '100%',
            }}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
          />
        </ResetSubmit>
      </FormForgotPasswordMobileStyled>
    </>
  );
};

const FormForgotPasswordMobileStyled = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  padding-bottom: 96px;
`;

const ResetSubmit = styled.div`
  position: fixed;
  bottom: 0px;
  background: var(--ds-c-white);
  width: 100%;
  margin-inline: -24px;
  padding: 16px 24px;
  border-top: 1px solid var(--ds-c-grey-disabled);
`;

const LinkBackToLoginStyled = styled(Link)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;

  color: var(--ds-c-blue);
  display: block;
  margin-bottom: 0px;
  position: fixed;
  bottom: 77px;
  left: 0;
  margin-inline: auto;
  width: 100%;
  height: 40px;

  &:hover {
    color: var(--ds-c-blue) !important;
  }
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 24px;
`;

const TitleH3 = styled.h3`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;
`;

export default FormForgotPasswordMobile;
