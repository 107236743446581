/* eslint-disable no-unused-vars */
import {
  findDiscountDetails,
  getDiscountDefault,
} from 'components/Discount/func';
import { LogUtil } from 'components/common/LogUtil';
import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import { fetchSellCurrencies, fetchSellRateCurrency } from 'components/fx/func';
import {
  FOREIGN_EXCHANGE_PREFIX,
  FX_TRANSACTIONS_PREFIX,
  HOME_URL,
  METHOD_EPAYMENT,
  ORDER_EMAIL,
  PAYMENT_CREADIT_CARD_NO,
  PAYMENT_CREADIT_CARD_TYPE,
  STATUS_SUBMITTED,
  SURCHARGE_RATE,
} from 'constants';
import {
  AP_PAY_AMOUNT_MAX_LIMIT,
  AP_PAY_AMOUNT_MIN_LIMIT,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_FISTNAME_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_LASTNAME_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_MIDDLENAME_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_NAME,
  FX_ADD_TRANSACTION_MESSAGE_NAME,
  FX_ADD_TRANSACTION_OCCUPATION_NAME,
  FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  FX_ADD_TRANSACTION_PICKUP_DATE,
  FX_ADD_TRANSACTION_PICKUP_DATE_DATA,
  FX_ADD_TRANSACTION_PICKUP_LOCATION,
  FX_ADD_TRANSACTION_PICKUP_LOCATION_TYPE,
  FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME,
  FX_ADD_TRANSACTION_PURPOSE_NAME,
  FX_ADD_TRANSACTION_SOURCE_NAME,
  FX_ADD_TRANSACTION_UPLOAD_ID_DRIVER_LICENCE_FRONT_NAME,
  FX_ADD_TRANSACTION_UPLOAD_ID_PASSPORT_NAME,
  FX_CALCULATOR_FORM_BUY_NAME,
  FX_CALCULATOR_FORM_PAY_NAME,
  PICKUP_LOCATION_TYPE_1,
  PICKUP_LOCATION_TYPE_2,
  PICKUP_LOCATION_TYPE_REFERENCE,
  RESET_FX_ADD_TRANSACTION_CONFIRM_INFORMATION,
  RESET_FX_ADD_TRANSACTION_DOCUMENT_UPLOAD,
  RESET_FX_ADD_TRANSACTION_INFORMATION_STORE,
  RESET_FX_ADD_TRANSACTION_ORDER,
  RESET_FX_ADD_TRANSACTION_PAYMENT_METHOD,
  RESET_FX_ADD_TRANSACTION_PICKUP_DATE,
  RESET_FX_ADD_TRANSACTION_PICKUP_LOCATION,
} from 'constants/fx';
import { address, datePicker } from 'helpers';
import Cookies from 'helpers/cookies';
import { checkMaintenance } from 'helpers/maintenance';
import useAuth from 'hooks/useAuth';
import useCommonStore from 'hooks/useCommonStore';
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import useTheme from 'hooks/useTheme';
import { isArray, isEmpty, isString } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  ADD_DENOMINATION,
  CLEAR_DENOMINATIONS,
  CLEAR_NOTES,
  REMOVE_DENOMINATION,
  SET_DENOMINATIONS,
  SET_FX_ADD_TRANSACTION_AS_GUEST,
  SET_FX_ADD_TRANSACTION_CURRENT_STEP,
  SET_FX_ADD_TRANSACTION_INFORMATION,
  SET_FX_CREATE_TXN_RESPONSE,
  SET_FX_TOKEN_TRANSACTION,
  SET_FX_TRANSACTION_DETAILS,
  SET_NOTES,
} from 'store/fx/action';
import { arrayUtils, numberUtils } from 'utils';
import useFxAddTransaction, {
  AMOUNT_MAX_REQUIRE_ADDITIONAL_INFORMATION,
  FX_MAKE_PAYMENT_KEY,
} from './useFxAddTransaction';
import useFxCalculator, { initialCalculatorForm } from './useFxCalculator';
import useFxStore from './useFxStore';

const useFxFormAddTransactionStore = () => {
  const { state, setState } = useFxStore();
  const { token } = useAuth();
  const { theme } = useTheme();
  const { locate } = useLang();
  const navigate = useNavigate();

  const {
    addTransactionInformation,
    addTransactionCurrentStep,
    transactionDetails,
    tokenTransaction,
    isAddTransactionAsGuest,
    createTxnResponse,
    denominations,
    cusNotes,
  } = state || {};
  const {
    isDisabled,
    isUpdateTran,
    paymentStatus,
    isMakePayment,
    getPickupLocationFullAddress,
    getPayLimitRanger,
    isFxAsGuest,
    guestEmail,
    guestMobile,
    getFxTransactionInformation,
    isCreateNewTran,
  } = useFxAddTransaction();
  const {
    checkSellRateOutdate,
    getContentOfSellRateTimeout,
    getCurrencySelected,
    getCurrentMinDenomination,
  } = useFxCalculator();
  const { currentUser } = useCommonStore();

  const { showPortalModalName } = usePortalModal();

  const {
    order,
    pickupLocation,
    paymentMethod,
    pickupDate,
    confirmInformation,
    documentUpload,
  } = addTransactionInformation || {};

  const { currencyOrders } = order || {};
  const orderNote = order[FX_ADD_TRANSACTION_MESSAGE_NAME];

  const pickupLocationSelected =
    pickupLocation[FX_ADD_TRANSACTION_PICKUP_LOCATION];
  const pickupLocationType =
    pickupLocation[FX_ADD_TRANSACTION_PICKUP_LOCATION_TYPE];
  const isPickupLocationByEA = pickupLocationType === PICKUP_LOCATION_TYPE_1;
  const isPickupLocationByAusPost =
    pickupLocationType === PICKUP_LOCATION_TYPE_2;

  const paymentMethodName =
    paymentMethod[FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME];

  const pickupDateSelected = pickupDate[FX_ADD_TRANSACTION_PICKUP_DATE];
  const pickupDateSelectedData =
    pickupDate[FX_ADD_TRANSACTION_PICKUP_DATE_DATA];

  const {
    discountApplied,
    discountsAvailable,
    discountsInactive,
    discountDetails,
  } = paymentMethod || {};
  const { discountAmount, totalAmountAfterDiscount, subtotalAfterDiscount } =
    discountApplied || {};
  const isDiscountApplied = Boolean(Number(discountAmount));
  const discountDefault = getDiscountDefault({
    discountsAvailable,
    discountsInactive,
  });
  const isHideDiscountSection = isDisabled
    ? isEmpty(discountApplied)
    : (!paymentMethodName && isEmpty(discountApplied)) ||
      isEmpty(discountDefault) ||
      isEmpty(discountApplied);

  const orderEmail = Cookies.get(ORDER_EMAIL);
  const confirmInformationEmail = (
    confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_NAME] ||
    transactionDetails?.customer?.email ||
    currentUser?.email ||
    guestEmail
  )?.toLowerCase();
  const confirmInformationMobile =
    confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_NAME] ||
    currentUser?.mobile ||
    guestMobile ||
    orderEmail;

  /* ============== DISPATCH ============== */
  const setAddTransactionCurrentStep = (stepData = 0) =>
    setState({
      action: SET_FX_ADD_TRANSACTION_CURRENT_STEP,
      payload: stepData,
    });

  const setAddTransactionInformation = (
    transactionInformationData = RESET_FX_ADD_TRANSACTION_INFORMATION_STORE
  ) => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: transactionInformationData,
    });
  };
  const resetAddTransactionInformation = () => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: RESET_FX_ADD_TRANSACTION_INFORMATION_STORE,
    });
  };

  const setOrder = (orderData = null) =>
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        order: isEmpty(orderData) ? RESET_FX_ADD_TRANSACTION_ORDER : orderData,
      },
    });
  const setCurrencyOrders = (currencyOrdersData = null) => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        order: {
          ...order,
          currencyOrders: isEmpty(currencyOrdersData)
            ? []
            : isArray(currencyOrdersData)
            ? currencyOrdersData
            : [],
        },
      },
    });
  };
  const setCurrencyOrder = (orderData = null) => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        order: {
          ...order,
          currencyOrders: isEmpty(orderData)
            ? []
            : [...currencyOrders, orderData],
        },
      },
    });
  };
  const setOrderNote = (noteData = '') => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        order: {
          ...order,
          [FX_ADD_TRANSACTION_MESSAGE_NAME]: noteData,
        },
      },
    });
  };

  const setPickupLocation = (pickupLocationData = null) =>
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        pickupLocation: isEmpty(pickupLocationData)
          ? RESET_FX_ADD_TRANSACTION_PICKUP_LOCATION
          : pickupLocationData,
      },
    });
  const setPickupLocationType = (typeData = '') => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        pickupLocation: {
          ...pickupLocation,
          [FX_ADD_TRANSACTION_PICKUP_LOCATION_TYPE]: typeData,
        },
      },
    });
  };

  const setPaymentMethod = (paymentMethodData = null) =>
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        paymentMethod: isEmpty(paymentMethodData)
          ? RESET_FX_ADD_TRANSACTION_PAYMENT_METHOD
          : paymentMethodData,
      },
    });
  const setPaymentMethodName = (paymentMethodNameData = null) => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        paymentMethod: {
          ...paymentMethod,
          [FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME]: paymentMethodNameData,
        },
      },
    });
  };

  const setPickupDate = (pickupDateData = null) =>
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        pickupDate: isEmpty(pickupDateData)
          ? RESET_FX_ADD_TRANSACTION_PICKUP_DATE
          : pickupDateData,
      },
    });

  const setConfirmInformation = (confirmInformationData = null) => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        confirmInformation: isEmpty(confirmInformationData)
          ? RESET_FX_ADD_TRANSACTION_CONFIRM_INFORMATION
          : confirmInformationData,
      },
    });
  };
  const setConfirmInformationEmail = (email = '') => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        confirmInformation: {
          ...confirmInformation,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_NAME]: email,
        },
      },
    });
  };
  const setConfirmInformationMobile = (mobile = '') => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        confirmInformation: {
          ...confirmInformation,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_NAME]: mobile,
        },
      },
    });
  };
  const setConfirmInformationEmailMobile = (email = '', mobile = '') => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        confirmInformation: {
          ...confirmInformation,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_NAME]: email,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_NAME]: mobile,
        },
      },
    });
  };
  const setConfirmInformationAdditionalInformation = (
    additionalInformationData = {}
  ) => {
    if (!isAdditionalInformationRequired || isEmpty(additionalInformationData))
      return;
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        confirmInformation: {
          ...confirmInformation,
          ...additionalInformationData,
        },
      },
    });
  };

  const setCreateTxnResponse = (txnResponse = {}) => {
    setState({
      action: SET_FX_CREATE_TXN_RESPONSE,
      payload: txnResponse,
    });
  };

  const setDocumentUpload = (documentUploadData = null) => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        documentUpload: isEmpty(documentUploadData)
          ? RESET_FX_ADD_TRANSACTION_DOCUMENT_UPLOAD
          : documentUploadData,
      },
    });
  };

  const setTransactionDetails = (transactionDetailsData = null) =>
    setState({
      action: SET_FX_TRANSACTION_DETAILS,
      payload: !isEmpty(transactionDetailsData) ? transactionDetailsData : null,
    });

  const redirectToForeignExhangePath = () => {
    window.location.href = `${HOME_URL[theme]}${FOREIGN_EXCHANGE_PREFIX}?lang=${locate}`;
  };

  const handleCancelTransaction = () => {
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
    resetAddTransactionInformation();
    setAddTransactionCurrentStep(0);
    setTransactionDetails();
    resetDenomination();
    resetNotes();
  };

  const setTransactionDetailsByOrderRef = async (orderRef) => {
    if (isEmpty(orderRef)) {
      setTransactionDetails();
      return;
    }

    try {
      LogUtil.log('setTransactionDetailsByOrderRef', 'start');
      const transactionDetails = await getFxTransactionInformation({
        token,
        orderRef,
        email: confirmInformationEmail,
        errorCallback: () => {
          handleCancelTransaction();
          if (isFxAsGuest || !token) {
            redirectToForeignExhangePath();
          } else {
            navigate(`${FX_TRANSACTIONS_PREFIX}`);
          }
        },
      });
      if (!isEmpty(transactionDetails)) {
        const isPreventMakePaymentWhenDirectlyAccessURLOfAProcessedTxn =
          isMakePayment &&
          orderRef &&
          !paymentStatus &&
          transactionDetails?.orderStatus !== STATUS_SUBMITTED;
        if (isPreventMakePaymentWhenDirectlyAccessURLOfAProcessedTxn) {
          if (!token && !isFxAsGuest) return;
          if (!orderRef || paymentStatus) return;
          handleCancelTransaction();
          if (isFxAsGuest || !token) {
            redirectToForeignExhangePath();
          } else {
            navigate(`${FX_TRANSACTIONS_PREFIX}`);
          }
        } else {
          setTransactionDetails(transactionDetails);
        }
      } else {
        setTransactionDetails();
      }
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('setTransactionDetailsByOrderRef', 'error', error);
    }
  };

  const setDiscountsAvailable = ({ discountsAvailable, discountsInactive }) => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        paymentMethod: {
          ...addTransactionInformation?.paymentMethod,
          discountsAvailable,
          discountsInactive,
          discountApplied: null,
        },
      },
    });
  };
  const setDiscountApplied = (discount = null) => {
    setState({
      action: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        paymentMethod: {
          ...addTransactionInformation?.paymentMethod,
          discountApplied: !isEmpty(discount) ? discount : null,
        },
      },
    });
  };

  const setTokenTransaction = (token = '') => {
    setState({
      action: SET_FX_TOKEN_TRANSACTION,
      payload: !isEmpty(token) ? token : '',
    });
  };

  const setAddTransactionAsGuest = (isSkip = false) => {
    setState({
      action: SET_FX_ADD_TRANSACTION_AS_GUEST,
      payload: isSkip,
    });
  };

  /* ============== UTILS ============== */
  const getSurchargeAmount = () => {
    if (!paymentMethodName) return 0;
    const sendTotalAmount = getSubTotalAmount();
    const feeAmount = getServiceFeeAmount();
    let surchargeAmount = 0;
    surchargeAmount =
      paymentMethodName === METHOD_EPAYMENT
        ? isDiscountApplied
          ? numberUtils.mathRound(totalAmountAfterDiscount * SURCHARGE_RATE) ||
            0
          : numberUtils.mathRound(
              numberUtils.mathRound(sendTotalAmount + feeAmount) *
                SURCHARGE_RATE
            )
        : 0;
    return Number(surchargeAmount);
  };
  const getDiscountApplied = () => {
    if (!isDiscountApplied) return null;
    return discountApplied;
  };
  const getDiscountAmount = () => {
    if (!isDiscountApplied) return 0;
    return discountAmount;
  };
  const getDiscountDetails = () => {
    if (!isDiscountApplied) return null;
    if (isUpdateTran) return discountDetails;
    return (
      findDiscountDetails(discountsAvailable, discountApplied?.code) || null
    );
  };
  const getSubTotalAmount = () => {
    if (isEmpty(currencyOrders)) return 0;
    let subTotalAmount = 0;
    currencyOrders.forEach((order) => {
      subTotalAmount =
        Number(subTotalAmount) + Number(order[FX_CALCULATOR_FORM_PAY_NAME]);
    });
    return Number(numberUtils.mathRound(subTotalAmount));
  };
  const getTotalPayAmount = () => {
    const surchargeAmount = getSurchargeAmount();
    const feeAmount = getServiceFeeAmount();
    const sendTotalAmount = getSubTotalAmount();
    let totalAmount = 0;
    totalAmount = isDiscountApplied
      ? paymentMethodName === METHOD_EPAYMENT
        ? numberUtils.mathRound(
            Number(totalAmountAfterDiscount) +
              Number(totalAmountAfterDiscount) * SURCHARGE_RATE
          )
        : Number(totalAmountAfterDiscount)
      : numberUtils.mathRound(feeAmount + surchargeAmount + sendTotalAmount);
    return totalAmount;
  };
  const getPaymentAmountLimit = () => {
    const payLimit = getPayLimitRanger()?.[paymentMethodName];
    const sendTotalAmount = isDiscountApplied
      ? subtotalAfterDiscount
      : getSubTotalAmount();

    const amountLimit = payLimit?.feesIncluded
      ? sendTotalAmount
      : sendTotalAmount;

    return amountLimit;
  };
  const checkPickupLocationTypeDisable = (item) => {
    const { type, labelKey, iconSrc, serviceFeeAmount, collect } = item;
    const subTotalAmount = getSubTotalAmount();
    if (type === PICKUP_LOCATION_TYPE_2) {
      if (
        subTotalAmount < AP_PAY_AMOUNT_MIN_LIMIT ||
        subTotalAmount > AP_PAY_AMOUNT_MAX_LIMIT
      )
        return true;
    }
    return false;
  };
  const replaceOrderInCurrencyOrders = ({
    array,
    item,
    itemEdit,
    prop,
    subProp1,
  }) => {
    const getPos = () => {
      if (!prop) return -1;
      if (!subProp1) return -1;
      return array.findIndex(
        (i) => i?.[prop]?.[subProp1] === itemEdit?.[prop]?.[subProp1]
      );
    };
    const pos = getPos();
    if (pos > -1) {
      return [...array.slice(0, pos), item, ...array.slice(pos + 1)];
    }
    return [...array, item];
  };
  const addOrderToCurrencyOrders = (order, orderEdit) => {
    if (isEmpty(currencyOrders)) {
      setCurrencyOrder(order);
    } else if (!isMakePayment && isDiscountApplied) {
      const newCurrencyOrders = replaceOrderInCurrencyOrders({
        array: currencyOrders,
        item: order,
        itemEdit: orderEdit,
        prop: 'currentCurrencySelected',
        subProp1: 'currencyCode',
      });
      const newAddTransactionInformation = {
        ...addTransactionInformation,
        order: {
          ...order,
          currencyOrders: newCurrencyOrders,
        },
        paymentMethod: {
          ...paymentMethod,
          discountsAvailable: [],
          discountsInactive: [],
          discountApplied: null,
        },
      };
      setAddTransactionInformation(newAddTransactionInformation);
    } else if (isEmpty(orderEdit)) {
      setCurrencyOrder(order);
    } else {
      const newCurrencyOrders = replaceOrderInCurrencyOrders({
        array: currencyOrders,
        item: order,
        itemEdit: orderEdit,
        prop: 'currentCurrencySelected',
        subProp1: 'currencyCode',
      });
      setCurrencyOrders(newCurrencyOrders);
    }
  };
  const deleteOrderInCurrencyOrders = (order) => {
    if (isEmpty(currencyOrders)) return;
    if (!isMakePayment && isDiscountApplied) {
      const newCurrencyOrders = arrayUtils.deleteItem({
        array: currencyOrders,
        item: order,
      });
      const newAddTransactionInformation = {
        ...addTransactionInformation,
        order: {
          ...order,
          currencyOrders: newCurrencyOrders,
        },
        paymentMethod: {
          ...paymentMethod,
          discountsAvailable: [],
          discountsInactive: [],
          discountApplied: null,
        },
      };
      setAddTransactionInformation(newAddTransactionInformation);

      // REMOVE DENOMINATION
      const currencyCode = order.currentCurrencySelected.currencyCode;
      removeDenomination(currencyCode);
      return;
    }
    const newCurrencyOrders = arrayUtils.deleteItem({
      array: currencyOrders,
      item: order,
    });
    setCurrencyOrders(newCurrencyOrders);
    // REMOVE DENOMINATION
    const currencyCode = order.currentCurrencySelected.currencyCode;
    removeDenomination(currencyCode);
  };
  const getServiceFeeAmount = () => {
    if (isPickupLocationByEA)
      return Number(
        numberUtils.mathRound(
          PICKUP_LOCATION_TYPE_REFERENCE[PICKUP_LOCATION_TYPE_1]
            .serviceFeeAmount
        )
      );
    if (isPickupLocationByAusPost)
      return Number(
        numberUtils.mathRound(
          PICKUP_LOCATION_TYPE_REFERENCE[PICKUP_LOCATION_TYPE_2]
            .serviceFeeAmount
        )
      );
    return 0;
  };
  const getOrderLatestBySellRateChanged = ({
    order = initialCalculatorForm,
    sellRateLatest = null,
    currencies,
  }) => {
    const { currentCurrencySelected, sellRate } = order || {};
    const { currencyCode } = currentCurrencySelected || {};
    const minDenomination = getCurrentMinDenomination(currencyCode, currencies);
    const { rate: rateLatest } = sellRateLatest || {};

    const isSellRateOutdate = checkSellRateOutdate({
      sellRate,
      sellRateLatest,
    });

    if (!isSellRateOutdate) {
      return order;
    } else {
      let buyValue = order[FX_CALCULATOR_FORM_BUY_NAME] || 0;
      let payValue = order[FX_CALCULATOR_FORM_PAY_NAME] || 0;
      buyValue =
        Math.round((payValue * rateLatest) / minDenomination) * minDenomination;
      payValue = numberUtils.mathRound(buyValue / rateLatest);

      const newOrder = {
        ...order,
        [FX_CALCULATOR_FORM_PAY_NAME]: payValue,
        [FX_CALCULATOR_FORM_BUY_NAME]: buyValue,
        sellRate: sellRateLatest,
      };
      return newOrder;
    }
  };
  const refreshCurrencyOrdersByExchangeRateOutdate = async ({
    callback = () => {},
  }) => {
    try {
      if (isEmpty(currencyOrders)) return;

      const currencies = await fetchSellCurrencies();

      const getSellRatePromises = currencyOrders.map((order) => {
        const { currentCurrencySelected } = order || {};
        const { currencyCode } = currentCurrencySelected || {};
        return fetchSellRateCurrency(currencyCode);
      });
      let sellRatesLatest = await Promise.all(getSellRatePromises);
      /* BEGIN - test */
      // sellRatesLatest = [
      //   {
      //     currency: 'US DOLLAR',
      //     currencyCode: 'USD',
      //     rate: '0.8000',
      //     rateToken:
      //       '6633bb6f01cea61f58e676f54962ef127fa73a5e20c9050246fd2c14e7e98160925069bac8f550511c79366ec19904dc',
      //   },
      //   {
      //     currency: 'VIETNAM DONG',
      //     currencyCode: 'VND',
      //     rate: '20000.2842',
      //     rateToken:
      //       'dbf21c9512161580869982d80ce6bea6d25b27dc5591531823b87fda891fdab967784f89e3ab42a51cd5c88fdd6ff9ef',
      //   },
      // ];
      /* END - test */
      const sellRatesLatestOutdate = sellRatesLatest.filter((rate, index) =>
        checkSellRateOutdate({
          sellRate: currencyOrders?.[index]?.sellRate,
          sellRateLatest: rate,
        })
      );

      if (isEmpty(sellRatesLatestOutdate)) {
        callback();
        return;
      }
      if (!isEmpty(sellRatesLatestOutdate)) {
        const currencyOrdersLatest = currencyOrders.map((order, index) =>
          getOrderLatestBySellRateChanged({
            order,
            sellRateLatest: sellRatesLatest[index],
            currencies,
          })
        );

        showPortalModalName({
          name: PORTAL_NAME_MODAL_DEFINE.FX_CALCULATOR_MODAL_RATE_TIMEOUT_MODAL,
          content: getContentOfSellRateTimeout(
            sellRatesLatestOutdate,
            currencyOrders,
            currencies
          ),
          action: () => {
            setCurrencyOrders(currencyOrdersLatest);
            callback();
          },
        });
      }
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('refreshCurrencyOrdersByExchangeRateOutdate', error);
    }
  };

  const getOrderStoreTransform = async () => {
    if (isEmpty(transactionDetails)) return RESET_FX_ADD_TRANSACTION_ORDER;

    try {
      const { currencies, notes } = transactionDetails || {};

      let orderStoreTransform = RESET_FX_ADD_TRANSACTION_ORDER;
      let currencyOrders = [];

      const sellCurrencies = await fetchSellCurrencies();

      if (!isEmpty(sellCurrencies)) {
        currencyOrders = currencies.map((currency) => ({
          [FX_CALCULATOR_FORM_PAY_NAME]: currency?.audAmount || 0,
          [FX_CALCULATOR_FORM_BUY_NAME]: currency?.amount || 0,
          currentCurrencySelected: getCurrencySelected(
            currency?.code,
            sellCurrencies
          ),
          sellRate: {
            currency: currency?.currencyName,
            currencyCode: currency?.code,
            rate: currency?.rate,
            rateToken: currency?.rateToken,
          },
        }));
      }

      orderStoreTransform = {
        ...orderStoreTransform,
        currencyOrders,
        [FX_ADD_TRANSACTION_MESSAGE_NAME]: notes,
      };

      return orderStoreTransform;
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('getOrderStoreTransform', 'error', error);
      return RESET_FX_ADD_TRANSACTION_ORDER;
    }
  };
  const getPickupLocationStoreTransform = () => {
    if (isEmpty(transactionDetails))
      return RESET_FX_ADD_TRANSACTION_PICKUP_LOCATION;

    try {
      let pickupLocationStore = RESET_FX_ADD_TRANSACTION_PICKUP_LOCATION;
      const { deliveryAddress, collectEntity, pickupLocationCode } =
        transactionDetails || {};

      pickupLocationStore = {
        ...pickupLocationStore,
        [FX_ADD_TRANSACTION_PICKUP_LOCATION_TYPE]:
          collectEntity === 'AUSPOST'
            ? PICKUP_LOCATION_TYPE_2
            : PICKUP_LOCATION_TYPE_1,
        [FX_ADD_TRANSACTION_PICKUP_LOCATION]: {
          name: deliveryAddress?.businessName,
          code: pickupLocationCode,
          street: deliveryAddress?.street,
          suburb: deliveryAddress?.suburb,
          state: deliveryAddress?.state,
          postcode: deliveryAddress?.postCode,
          phone: '',
          email: '',
          openingHours: '',
          entity: collectEntity,
          direction: '',
        },
      };

      return pickupLocationStore;
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('getPickupLocationStoreTransform', 'error', error);
      return RESET_FX_ADD_TRANSACTION_PICKUP_LOCATION;
    }
  };
  const getPaymentMethodStoreTransform = () => {
    if (isEmpty(transactionDetails))
      return RESET_FX_ADD_TRANSACTION_PAYMENT_METHOD;

    try {
      let paymentMethodStore = RESET_FX_ADD_TRANSACTION_PAYMENT_METHOD;

      const {
        paymentMethod,
        creditCardNo,
        creditCardType,
        discount,
        discountDetails,
      } = transactionDetails || {};

      paymentMethodStore = {
        ...paymentMethodStore,
        [FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME]: paymentMethod,
        [PAYMENT_CREADIT_CARD_NO]: creditCardNo,
        [PAYMENT_CREADIT_CARD_TYPE]: creditCardType,
        discountApplied: discount,
        discountDetails,
      };

      return paymentMethodStore;
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('getPaymentMethodStoreTransform', 'error', error);
      return RESET_FX_ADD_TRANSACTION_PAYMENT_METHOD;
    }
  };
  const getPickupDateStoreTransform = () => {
    if (isEmpty(transactionDetails))
      return RESET_FX_ADD_TRANSACTION_PICKUP_DATE;

    try {
      const { preferredPickupDate } = transactionDetails || {};

      let pickupDateStore = RESET_FX_ADD_TRANSACTION_PICKUP_DATE;

      pickupDateStore = {
        ...pickupDateStore,
        [FX_ADD_TRANSACTION_PICKUP_DATE]: preferredPickupDate,
        [FX_ADD_TRANSACTION_PICKUP_DATE_DATA]: null,
      };

      return pickupDateStore;
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('getPickupDateStoreTransform', 'error', error);
      return RESET_FX_ADD_TRANSACTION_PICKUP_DATE;
    }
  };
  const getConfirmInformationStoreTransform = () => {
    try {
      if (isEmpty(transactionDetails))
        return RESET_FX_ADD_TRANSACTION_CONFIRM_INFORMATION;

      let confirmInformationStore =
        RESET_FX_ADD_TRANSACTION_CONFIRM_INFORMATION;

      if (!isFxAsGuest) {
        if (isEmpty(currentUser))
          return RESET_FX_ADD_TRANSACTION_CONFIRM_INFORMATION;

        confirmInformationStore = {
          ...confirmInformationStore,
          ...currentUser,
        };
        return confirmInformationStore;
      }

      if (isFxAsGuest) {
        const {
          customer,
          residentialAddress,
          occupation,
          previousOccupation,
          purpose,
          sourceOfWealth,
        } = transactionDetails || {};
        confirmInformationStore = {
          ...confirmInformationStore,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_FISTNAME_NAME]:
            customer?.firstName,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_LASTNAME_NAME]:
            customer?.lastName,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME]: customer?.dob
            ? datePicker.dateStrToUTC(customer?.dob)
            : new Date(),
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME]: customer?.gender,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_NAME]: customer?.mobile,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_NAME]: customer?.email,
          isEnterAddressManuallySelected: residentialAddress?.isManualEntry,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME]:
            residentialAddress?.fullAddress,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME]: '', // ???
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME]: '', // ???
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME]:
            residentialAddress?.street,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME]: '', // ???
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME]:
            residentialAddress?.postCode,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME]:
            residentialAddress?.suburb,
          [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME]:
            residentialAddress?.state,
          [FX_ADD_TRANSACTION_OCCUPATION_NAME]: occupation,
          [FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]: previousOccupation,
          [FX_ADD_TRANSACTION_PURPOSE_NAME]: purpose,
          [FX_ADD_TRANSACTION_SOURCE_NAME]: sourceOfWealth,
        };
      }
      return confirmInformationStore;
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('getConfirmInformationStoreTransform', 'error', error);
      return RESET_FX_ADD_TRANSACTION_CONFIRM_INFORMATION;
    }
  };
  const getDocumentUploadStoreTransform = () => {
    if (isEmpty(transactionDetails))
      return RESET_FX_ADD_TRANSACTION_DOCUMENT_UPLOAD;
    if (!isFxAsGuest) return null;

    const doc = transactionDetails?.documents?.[0];
    let documentUploadStore = RESET_FX_ADD_TRANSACTION_DOCUMENT_UPLOAD;
    documentUploadStore = {
      ...documentUploadStore,
      isDriverLicenceSelected: doc?.idType === 'D',
      ...(doc?.idType === 'D' && {
        [FX_ADD_TRANSACTION_UPLOAD_ID_DRIVER_LICENCE_FRONT_NAME]: doc,
      }),
      ...(doc?.idType !== 'D' && {
        [FX_ADD_TRANSACTION_UPLOAD_ID_PASSPORT_NAME]: doc,
      }),
    };
    return documentUploadStore;
  };
  const transactionInformationStoreTransformInitial = async ({
    orderRef,
    errorCallback = () => {},
  }) => {
    if (isEmpty(transactionDetails)) {
      setTransactionDetailsByOrderRef(orderRef);
      if (orderRef) {
        Cookies.set(FX_MAKE_PAYMENT_KEY, true);
      }
      return;
    }

    try {
      const informationStore = {
        order: await getOrderStoreTransform(),
        pickupLocation: getPickupLocationStoreTransform(),
        paymentMethod: getPaymentMethodStoreTransform(),
        pickupDate: getPickupDateStoreTransform(),
        confirmInformation: getConfirmInformationStoreTransform(),
        documentUpload: getDocumentUploadStoreTransform(),
      };
      setAddTransactionInformation(informationStore);
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('getTransactionInformationTransform', 'error', error);
      errorCallback();
    }
  };

  const getCurrentUserPayloadTransform = () => {
    const firstName =
      confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_FISTNAME_NAME];
    const middleName =
      confirmInformation?.[
        FX_ADD_TRANSACTION_ENTER_INFORMATION_MIDDLENAME_NAME
      ];
    const lastName =
      confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_LASTNAME_NAME];

    return {
      dob: datePicker.isDateTimeValid(
        confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME]
      )
        ? new Date(
            confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME]
          )
        : isString(
            confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME]
          )
        ? datePicker.dateStrToUTC(
            confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME]
          )
        : new Date(),
      firstName: isEmpty(middleName) ? firstName : `${firstName} ${middleName}`,
      lastName,
      mobile:
        confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_NAME],
      email:
        confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_NAME],
      gender:
        confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME],
    };
  };

  const getResidentialAddressPayloadTransform = () => {
    return {
      street: address.addressStreetCombine(
        confirmInformation?.[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME
        ],
        confirmInformation?.[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME
        ],
        confirmInformation?.[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME
        ],
        confirmInformation?.[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME
        ]?.key
      ),
      suburb:
        confirmInformation?.[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME
        ],
      state:
        confirmInformation?.[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME
        ],
      postCode:
        confirmInformation?.[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME
        ],
      countryCode: 'AUS',
      countryId: '14',
      countryName: 'AUSTRALIA',
      isManualEntry: confirmInformation?.isEnterAddressManuallySelected,
      fullAddress:
        confirmInformation?.[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME
        ],
    };
  };

  const getDocumentUploadPayloadTransform = () => {
    const { isDriverLicenceSelected } = documentUpload || {};
    const currentDocumentUpload = isDriverLicenceSelected
      ? documentUpload?.[FX_ADD_TRANSACTION_UPLOAD_ID_DRIVER_LICENCE_FRONT_NAME]
      : documentUpload?.[FX_ADD_TRANSACTION_UPLOAD_ID_PASSPORT_NAME];
    const document = isEmpty(currentDocumentUpload?.document)
      ? currentDocumentUpload
      : currentDocumentUpload?.document;
    const { keyname, originalFileName, etag, docType, idType } = document || {};

    return {
      keyname,
      originalFileName,
      etag,
      docType,
      idType: idType || docType,
    };
  };

  const fromWebflowRedirectToOrderInitial = async ({
    currencyCode,
    payAmount,
    buyAmount,
  }) => {
    try {
      const currencies = await fetchSellCurrencies();
      const sellRate = await fetchSellRateCurrency(currencyCode);

      if (isEmpty(currencies)) return;
      if (isEmpty(sellRate)) return;

      const currentMinDenomination = getCurrentMinDenomination(
        currencyCode,
        currencies
      );
      const rate = sellRate?.rate || 0;

      let buyValue = buyAmount || 0;
      let payValue = payAmount || 0;
      buyValue =
        Math.round((payValue * rate) / currentMinDenomination) *
        currentMinDenomination;
      payValue = numberUtils.mathRound(buyValue / rate);

      const order = {
        [FX_CALCULATOR_FORM_PAY_NAME]: payValue,
        [FX_CALCULATOR_FORM_BUY_NAME]: buyValue,
        currentCurrencySelected: getCurrencySelected(currencyCode, currencies),
        sellRate,
      };

      LogUtil.log('fromWebflowRedirectToOrderInitial', 'order add', order);
      addOrderToCurrencyOrders(order);
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error('fromWebflowRedirectToOrderInitial', 'error', error);
    }
  };

  const isAdditionalInformationRequired =
    (getSubTotalAmount() || 0) >= AMOUNT_MAX_REQUIRE_ADDITIONAL_INFORMATION;

  /* ============== SUBMIT TRANSACTION ============== */
  const getPickupLocationCodePayload = () => {
    const { code } = pickupLocationSelected || {};
    return code;
  };
  const getPickupLocationPayload = () => {
    if (isPickupLocationByEA) return null;
    const {
      code,
      entity,
      name,
      openingHours,
      postcode,
      state,
      street,
      suburb,
      rural,
    } = pickupLocationSelected || {};
    const fullAddress = getPickupLocationFullAddress(pickupLocationSelected);
    return {
      name,
      code,
      street,
      suburb,
      state,
      postcode,
      openingHours,
      entity,
      countryName: 'Australia',
      fullAddress,
      rural: pickupLocationType === PICKUP_LOCATION_TYPE_2 ? rural === 'Y' : '',
    };
  };
  const getDeliveryAddressPayload = () => {
    if (isPickupLocationByEA) return null;
    const { name, postcode, state, street, suburb } =
      pickupLocationSelected || {};
    const businessName = name;
    const fullAddress = isMakePayment
      ? transactionDetails?.deliveryAddress?.fullAddress || ''
      : getPickupLocationFullAddress(pickupLocationSelected);
    const postCode = postcode;
    return {
      addressType: '',
      businessName,
      countryCode: 'AUS',
      fullAddress,
      postCode,
      state,
      street,
      suburb,
      manualEntry: false,
    };
  };
  const getFeeTypePayload = () => {
    if (isPickupLocationByEA) return null;
    return 'AUSPOST';
  };
  const getCustomerPayload = () => {
    let firstName = '';
    let lastName = '';
    let dob = ''; // ???
    let email = '';
    let mobile = '';
    let gender = ''; // ???
    let identification = {
      code: 'D', // ???
      idNumber: '', // ???
      expiryDate: '', // ???
    };
    let residentialAddress = null;

    if (!isFxAsGuest) {
      firstName = currentUser?.firstName;
      lastName = currentUser?.lastName;
      email = currentUser?.email;
      mobile = currentUser?.mobile;
      const street = currentUser?.address?.street;
      const suburb = currentUser?.address?.suburb;
      const state = currentUser?.address?.state;
      const fullAddress = currentUser?.address?.fullAddress;
      const postCode = currentUser?.address?.postcode;
      const manual = currentUser?.address?.manual;

      residentialAddress = {
        street,
        suburb,
        state,
        postCode,
        fullAddress,
        isManualEntry: manual, // ???
        countryCode: 'AUS', // ???
        countryId: '14', // ???
        countryName: 'AUSTRALIA', // ???
      };
      return null;
    }

    if (isFxAsGuest) {
      ({ firstName, lastName, dob, email, mobile, gender } =
        getCurrentUserPayloadTransform());
      dob = datePicker.isDateUTC(
        confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME]
      )
        ? datePicker.dateUTCToDateStr(
            confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME]
          )
        : confirmInformation?.[FX_ADD_TRANSACTION_ENTER_INFORMATION_DOB_NAME];

      residentialAddress = getResidentialAddressPayloadTransform();
    }

    return {
      firstName,
      lastName,
      dob,
      email,
      mobile,
      gender,
      identification,
      residentialAddress,
    };
  };
  const getCurrenciesPayload = () => {
    return currencyOrders.map((order) => {
      const { currentCurrencySelected, sellRate } = order || {};

      const audAmount = order[FX_CALCULATOR_FORM_PAY_NAME];
      const amount = order[FX_CALCULATOR_FORM_BUY_NAME];
      const code = currentCurrencySelected?.currencyCode;
      const rate = sellRate?.rate;
      const rateToken = sellRate?.rateToken;

      return {
        amount,
        code,
        rate,
        rateToken,
        audAmount,
      };
    });
  };
  const getDocumentsUploadPayload = () => {
    if (!isFxAsGuest) return null;
    const documentUploaded = getDocumentUploadPayloadTransform();
    return [documentUploaded];
  };
  const getSendingSatchelDatePayload = () => {
    if (isPickupLocationByEA) return '';
    return pickupDateSelectedData?.sendingSatchelDate || '';
  };
  const getOrderRefPayload = () => {
    if (isCreateNewTran) return '';
    return '';
  };
  const getOccupationPayload = () => {
    if (!isAdditionalInformationRequired) return '';
    return confirmInformation?.[FX_ADD_TRANSACTION_OCCUPATION_NAME]?.key;
  };
  const getPreviousOccupationPayload = () => {
    if (!isAdditionalInformationRequired) return '';
    return confirmInformation?.[FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]?.key;
  };
  const getPurposePayload = () => {
    if (!isAdditionalInformationRequired) return '';
    return confirmInformation?.[FX_ADD_TRANSACTION_PURPOSE_NAME]?.key;
  };
  const getSourceOfWealthPayload = () => {
    if (!isAdditionalInformationRequired) return '';
    return confirmInformation?.[FX_ADD_TRANSACTION_SOURCE_NAME]?.key;
  };
  const getPayloadToCreateOrderSubmit = () => {
    const orderRef = getOrderRefPayload();
    const serviceFeeAmount = getServiceFeeAmount();
    const { entity } = pickupLocationSelected || {};
    const collectEntity = entity;
    const pickupLocationCode = getPickupLocationCodePayload();
    const pickupLocation = getPickupLocationPayload();
    const deliveryAddress = getDeliveryAddressPayload();
    const feeType = getFeeTypePayload();
    const customer = getCustomerPayload();
    const notes = orderNote;
    const currencies = getCurrenciesPayload();
    const subTotal = getSubTotalAmount();
    const surcharge = getSurchargeAmount();
    const orderTotal = getTotalPayAmount();
    const paymentMethod = paymentMethodName;
    const paymentAmount = subTotal;
    const documents = getDocumentsUploadPayload();
    const sendingSatchelDate = getSendingSatchelDatePayload();
    const discount = getDiscountApplied();
    const discountAmount = getDiscountAmount();
    const discountDetails = getDiscountDetails();
    const occupation = getOccupationPayload();
    const previousOccupation = getPreviousOccupationPayload();
    const purpose = getPurposePayload();
    const sourceOfWealth = getSourceOfWealthPayload();

    return {
      orderRef,
      orderType: 'BUY',
      deliveryFee: serviceFeeAmount,
      collectMethod: 'PICK UP',
      collectEntity,
      pickupLocationCode,
      ...(!isEmpty(pickupLocation) && { pickupLocation }), // auspost is required
      ...(!isEmpty(deliveryAddress) && { deliveryAddress }), // auspost is required
      ...(!isEmpty(feeType) && { feeType }), // auspost is required
      preferredPickupDate: pickupDateSelected,
      ...(!isEmpty(customer) && { customer }), // guest is required
      notes,
      currencies,
      subTotal,
      surcharge,
      orderTotal,
      paymentMethod,
      paymentAmount,
      ...(!isEmpty(documents) && { documents }), // guest is required
      occupation, // >= 10000 required
      previousOccupation, // >= 10000 required
      purpose, // >= 10000 required
      sourceOfWealth, // >= 10000 required
      sendingSatchelDate, // auspost is required
      discount,
      discountAmount,
      discountDetails,
    };
  };

  /* ============== DENOMINATION ============== */
  const setDenomination = (denominations) => {
    setState({
      action: SET_DENOMINATIONS,
      payload: { denominations },
    });
  };
  const addDenomination = (currencyCode, selection = 'Mixed') => {
    setState({
      action: ADD_DENOMINATION,
      payload: { currencyCode, selection },
    });
  };
  const removeDenomination = (currencyCode) => {
    setState({
      action: REMOVE_DENOMINATION,
      payload: { currencyCode },
    });
  };
  const resetDenomination = () => {
    setState({
      action: CLEAR_DENOMINATIONS,
      payload: null,
    });
  };
  const setNotes = (cusNotes) => {
    setState({
      action: SET_NOTES,
      payload: cusNotes,
    });
  };
  const resetNotes = () => {
    setState({
      action: CLEAR_NOTES,
      payload: null,
    });
  };

  return {
    // STATE
    addTransactionInformation,
    addTransactionCurrentStep,
    tokenTransaction,
    order,
    pickupLocation,
    currencyOrders,
    orderNote,
    pickupLocationSelected,
    pickupLocationType,
    paymentMethod,
    paymentMethodName,
    pickupDateSelected,
    pickupDateSelectedData,
    confirmInformation,
    documentUpload,
    discountApplied,
    discountsAvailable,
    discountsInactive,
    isDiscountApplied,
    discountDetails,
    discountDefault,
    isHideDiscountSection,
    transactionDetails,
    isPickupLocationByEA,
    isPickupLocationByAusPost,
    isAddTransactionAsGuest,
    confirmInformationEmail,
    confirmInformationMobile,
    isAdditionalInformationRequired,
    createTxnResponse,
    // DISPATCH
    setAddTransactionCurrentStep,
    setAddTransactionInformation,
    resetAddTransactionInformation,
    setTokenTransaction,
    setOrder,
    setCurrencyOrders,
    setCurrencyOrder,
    setOrderNote,
    setPickupLocation,
    setPickupLocationType,
    setPaymentMethod,
    setPaymentMethodName,
    setPickupDate,
    setConfirmInformation,
    setDocumentUpload,
    setTransactionDetails,
    setDiscountsAvailable,
    setDiscountApplied,
    setAddTransactionAsGuest,
    setConfirmInformationEmail,
    setConfirmInformationMobile,
    setConfirmInformationEmailMobile,
    setConfirmInformationAdditionalInformation,
    setCreateTxnResponse,
    // UTILS
    checkPickupLocationTypeDisable,
    addOrderToCurrencyOrders,
    deleteOrderInCurrencyOrders,
    getServiceFeeAmount,
    getSurchargeAmount,
    getSubTotalAmount,
    getTotalPayAmount,
    getPaymentAmountLimit,
    refreshCurrencyOrdersByExchangeRateOutdate,
    setTransactionDetailsByOrderRef,
    transactionInformationStoreTransformInitial,
    getCurrentUserPayloadTransform,
    fromWebflowRedirectToOrderInitial,
    // SUBMIT TRANSACTION
    getCurrenciesPayload,
    getPayloadToCreateOrderSubmit,
    // DENOMINATION
    denominations,
    setDenomination,
    addDenomination,
    removeDenomination,
    resetDenomination,
    cusNotes,
    setNotes,
    resetNotes,
  };
};

export default useFxFormAddTransactionStore;
