import { api } from 'api';
import DoneIcon from 'assets/icons/done-icon.svg';
import WarnningIcon from 'assets/icons/warnning-red-icon.svg';
import { checkFormInputValidation } from 'components/Profile/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  BODY_PADDING_BOTTOM_MOBILE,
  DESKTOP_MIN_WIDTH,
  EDIT_PROFILE_CURRENT_PASSWORD_FIELD,
  MOBILE_MIN_WIDTH,
  PASSWORD_LIVE_CHECKS,
  TABLET_MAX_WIDTH,
} from 'constants';
import {
  EDIT_PROFILE_CURRENT_PASSWORD_NAME,
  EDIT_PROFILE_NEW_PASSWORD_FIELD,
  EDIT_PROFILE_NEW_PASSWORD_NAME,
  EDIT_PROFILE_REPASSWORD_FIELD,
  EDIT_PROFILE_REPASSWORD_NAME,
  RESET_EDIT_PROFILE_PASSWORD_INFORMATION,
} from 'constants/profile';
import { checkMaintenance } from 'helpers/maintenance';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { Fade } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import FormInputPasswordValidation from '../FormInputPasswordValidation';
import UpdateProfilePopup from '../UpdateProfilePopup';

const initialField = RESET_EDIT_PROFILE_PASSWORD_INFORMATION;

const FormChangePasswordPopup = ({ isOpen, onClose }) => {
  const { t } = useLang();
  const { dispatch } = useStore();
  const { token } = useAuth();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();

  const [information, setInformation] = useState(initialField);
  const [validation, setValidation] = useState(initialField);
  const [isUpdatePasswordSuccessfully, setUpdatePasswordSuccessfully] =
    useState(false);
  const [liveCheckStatus, setLiveCheckStatus] = useState({
    0: WarnningIcon,
    1: WarnningIcon,
    2: WarnningIcon,
    3: WarnningIcon,
  });

  useEffect(() => {
    return () => {
      setInformation(initialField);
      setValidation(initialField);
    };
  }, [isOpen]);

  const isRePasswordMatch =
    information[EDIT_PROFILE_NEW_PASSWORD_NAME] ===
    information[EDIT_PROFILE_REPASSWORD_NAME];

  const handleSubmit = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    const isAllowSubmit =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value) &&
      Object.values(liveCheckStatus).every((value) => value === DoneIcon);

    if (!isAllowSubmit) {
      newValidation = checkFormInputValidation(
        information[EDIT_PROFILE_CURRENT_PASSWORD_NAME],
        EDIT_PROFILE_CURRENT_PASSWORD_FIELD,
        information,
        newValidation
      );

      newValidation = checkFormInputValidation(
        information[EDIT_PROFILE_NEW_PASSWORD_NAME],
        EDIT_PROFILE_NEW_PASSWORD_FIELD,
        information,
        newValidation
      );

      newValidation = checkFormInputValidation(
        information[EDIT_PROFILE_REPASSWORD_NAME],
        EDIT_PROFILE_REPASSWORD_FIELD,
        information,
        newValidation
      );
    }

    if (isAllowSubmit) {
      if (isRePasswordMatch) {
        dispatch({ type: SET_SHOW_LOADING, payload: true });
        try {
          const { data } = await api.updatePassword(
            token,
            information[EDIT_PROFILE_CURRENT_PASSWORD_NAME],
            information[EDIT_PROFILE_NEW_PASSWORD_NAME]
          );

          if (data?.success) {
            setUpdatePasswordSuccessfully(true);

            dispatch({ type: SET_SHOW_LOADING, payload: false });

            setLiveCheckStatus({
              0: WarnningIcon,
              1: WarnningIcon,
              2: WarnningIcon,
              3: WarnningIcon,
            });

            onClose();
          }
        } catch (error) {
          checkMaintenance(error);
          console.error(error?.message);

          newValidation = {
            ...newValidation,
            [EDIT_PROFILE_CURRENT_PASSWORD_NAME]:
              EDIT_PROFILE_CURRENT_PASSWORD_FIELD.msgInvalid,
          };

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      } else {
        newValidation = {
          ...newValidation,
          [EDIT_PROFILE_REPASSWORD_NAME]:
            EDIT_PROFILE_REPASSWORD_FIELD.msgNotMatch,
        };
      }
    }

    setValidation(newValidation);
  };

  return (
    <>
      <style>
        {`
          @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${
          TABLET_MAX_WIDTH - 1
        }px) {
            .change-password-modal-backdrop {
              top: 70px;
            }
          }

          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal-backdrop {
              opacity: 0!important;
              z-index: 1045!important;
            }

            .modal {
              top: 70px;
              padding-left: 0px;
              background: #fff;
            }
          }

          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
            .change-password-modal-backdrop {
              background: #212121;
              opacity: 0.7!important;
              display: initial!important;
            }
          }
        `}
      </style>

      {isUpdatePasswordSuccessfully && (
        <UpdateProfilePopup
          isOpen={isUpdatePasswordSuccessfully}
          onClick={() => setUpdatePasswordSuccessfully(false)}
          title={t('popup_update_success_title')}
          desc={t('popup_change_password_success')}
          backDropZIndex={1055}
        />
      )}

      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby={isDesktop && 'contained-modal-title-vcenter'}
        backdropClassName="change-password-modal-backdrop"
        centered={isDesktop}
        animation={isDesktop}
      >
        <ModalHeader>{t('button_change_password')}</ModalHeader>
        <ModalBody>
          <FormInputPasswordValidation
            field={EDIT_PROFILE_CURRENT_PASSWORD_FIELD}
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            setLiveCheckStatus={setLiveCheckStatus}
          />
          <FormInputPasswordValidation
            field={EDIT_PROFILE_NEW_PASSWORD_FIELD}
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            setLiveCheckStatus={setLiveCheckStatus}
          />
          <Paragraph>
            {t('reset_password_text_password_requirements')}
          </Paragraph>
          <PasswordRequires>
            {PASSWORD_LIVE_CHECKS.map((check, index) => (
              <li key={check.id}>
                <img src={liveCheckStatus[index]} alt="" />
                <Paragraph>{t(check.require)}</Paragraph>
              </li>
            ))}
          </PasswordRequires>
          <FormInputPasswordValidation
            field={EDIT_PROFILE_REPASSWORD_FIELD}
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            setLiveCheckStatus={setLiveCheckStatus}
          />
        </ModalBody>
        <ModalFooter>
          <Fade in appear>
            <NextWrap>
              <ButtonCommon
                value={t('button_cancel')}
                onClick={onClose}
                styles={{
                  margin: '0px',
                  marginRight: isMobile || isTablet ? 0 : '16px',
                  width: isMobile || isTablet ? '50%' : '80px',
                }}
                color="var(--ds-c-blue)"
                background="var(--ds-c-white)"
              />
              <ButtonCommon
                value={t('button_save')}
                color="var(--c-primary)"
                background="var(--bg-primary)"
                isFill={true}
                styles={{
                  margin: '0px',
                  width: isMobile || isTablet ? '50%' : '80px',
                }}
                onClick={handleSubmit}
                isUseKeyDown
                isDisabled={false}
              />
            </NextWrap>
          </Fade>
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
  }

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    & .modal-dialog {
      position: absolute;
      top: 0px;
      margin: 0;
      height: 100dvh;
      width: 100%;
      max-width: 100%;

      & .modal-content {
        padding: 24px 24px 0 24px;
        border-radius: 0px;
        box-shadow: none;
        background: var(--ds-c-white);
        width: 100%;
        height: calc(100dvh - 70px);
        margin-inline: auto;
        border: none;
      }

      & .modal {
        top: 70px;
        padding-left: 0px;
        background-color: #fff;
      }
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-content {
      padding: 24px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 470px;
      height: fit-content;
      margin-inline: auto;
    }
  }
`;
const ModalHeader = styled(Modal.Header)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #7a8189;

  padding: 0;
  margin-bottom: 0;
  padding-bottom: 16px;
  border: none !important;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #1c3f88;
  }
`;

const ModalBody = styled(Modal.Body)`
  padding: 0;

  @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
    padding-bottom: ${BODY_PADDING_BOTTOM_MOBILE}px;
  }
`;

const PasswordRequires = styled.ul`
  margin: 0;
  margin-bottom: 24px;
  padding-left: 0px;

  & li {
    display: flex;
    align-items: center;
    list-style: disc;
    list-style-type: disc;

    & img {
      margin-right: 8px;
    }

    & p {
      margin-bottom: 0px;
    }
  }
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  background: var(--ds-c-white);
  margin-block: 0px;
  position: fixed;
  left: 24px;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-inline: unset;
    padding-inline: unset;
    height: 40px;
    border-top: unset;
    left: unset;
  }
`;

export default FormChangePasswordPopup;
