import {
  address2Valid,
  address3Valid,
  businessNameValid,
  dateValid,
  numberValid,
  textValid,
} from 'helpers/validation';

export const BUSINESS_OF_RECEIVER_TYPE = 'ORGANISATION';

export const CREATE_BUSINESS_RECEIVER_TYPE = 'BUSINESS';
export const CREATE_INDIVIDUAL_RECEIVER_TYPE = 'INDIVIDUAL';

export const EDIT_RECEIVER_COUNTRY_NAME = 'edit-receiver-country-name-field';
export const EDIT_RECEIVER_COUNTRY_FIELD = {
  label: 'label_receiver_in',
  name: EDIT_RECEIVER_COUNTRY_NAME,
  type: 'text',
  placeholder: 'form_placeholder_please_select',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
};

export const EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME =
  'edit-receiver-select-type-of-receiver-field';
export const EDIT_RECEIVER_PERSONAL_DETAILS_SELECT_TYPE_OF_RECEIVER_FIELD = {
  label: 'form_select_type_of_receiver',
  name: EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME,
  type: 'radio',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
  checkValidFn: textValid,
};

export const EDIT_RECEIVER_FISTNAME_NAME = 'edit-receiver-first-name-field';
export const EDIT_RECEIVER_LASTNAME_NAME = 'edit-receiver-last-name-field';
export const EDIT_RECEIVER_FULLNAME_NAME = 'edit-receiver-full-name-field';
export const EDIT_RECEIVER_GENERAL_DETAILS_NAME_FIELDS = [
  {
    label: 'form_family_name',
    name: EDIT_RECEIVER_LASTNAME_NAME,
    type: 'text',
    placeholder: 'form_placeholder',
    tooltip: 'form_name_tooltip_2',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_name_invalid_1',
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
  {
    label: 'form_given_name',
    name: EDIT_RECEIVER_FISTNAME_NAME,
    type: 'text',
    placeholder: 'form_placeholder',
    tooltip: 'form_name_tooltip_3',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_name_invalid_1',
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
  {
    label: 'form_full_name',
    name: EDIT_RECEIVER_FULLNAME_NAME,
    type: 'text',
    placeholder: '-',
    isRequired: false,
    isReadOnly: true,
  },
];

export const EDIT_RECEIVER_BUSINESS_NAME_FIELD = {
  label: 'form_business_name',
  name: EDIT_RECEIVER_FISTNAME_NAME,
  type: 'text',
  placeholder: 'form_placeholder',
  tooltip: 'form_name_tooltip_3',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_name_invalid_1',
  msgRequired: 'form_required',
  checkValidFn: businessNameValid,
};

export const EDIT_RECEIVER_GENDER_NAME = 'edit-receiver-gender-field';
export const EDIT_RECEIVER_PERSONAL_DETAILS_GENDER_FIELD = {
  label: 'form_gender',
  name: EDIT_RECEIVER_GENDER_NAME,
  type: 'radio',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
  checkValidFn: textValid,
};

export const EDIT_RECEIVER_MOBILE_NAME = 'edit-receiver-mobile-field';
export const EDIT_RECEIVER_PHONE_NAME = 'edit-receiver-phone-field';
export const EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS = [
  {
    label: 'form_mobile_phone',
    name: EDIT_RECEIVER_MOBILE_NAME,
    type: 'tel',
    placeholder: 'form_placeholder',
    isRequired: true,
    isReadOnly: false,
    msgNumberInvalid: 'form_mobile_invalid_4',
    msgStartWith: 'form_mobile_invalid_5',
    msgStartWith2: 'form_mobile_invalid_6',
    msgInvalid: 'form_mobile_invalid_2',
    msgRequired: 'form_required',
  },
  {
    label: 'form_phone',
    name: EDIT_RECEIVER_PHONE_NAME,
    type: 'tel',
    placeholder: 'form_placeholder',
    isRequired: false,
    isReadOnly: false,
    msgNumberInvalid: 'form_mobile_invalid_4',
    msgStartWith: 'form_mobile_invalid_5',
    msgStartWith2: 'form_mobile_invalid_6',
    msgInvalid: 'form_mobile_invalid_2',
    msgRequired: 'form_required',
  },
];

export const EDIT_RECEIVER_BUSINESS_MOBILE_FIELD = {
  label: 'form_mobile_phone',
  name: EDIT_RECEIVER_MOBILE_NAME,
  type: 'tel',
  placeholder: 'form_placeholder',
  isRequired: true,
  isReadOnly: false,
  msgNumberInvalid: 'form_mobile_invalid_4',
  msgStartWith: 'form_mobile_invalid_5',
  msgStartWith2: 'form_mobile_invalid_6',
  msgInvalid: 'form_mobile_invalid_2',
  msgRequired: 'form_required',
};
export const EDIT_RECEIVER_BUSINESS_PHONE_FIELD = {
  label: 'form_contact_number',
  name: EDIT_RECEIVER_PHONE_NAME,
  type: 'tel',
  placeholder: 'form_placeholder',
  isRequired: true,
  isReadOnly: false,
  msgNumberInvalid: 'form_mobile_invalid_4',
  msgStartWith: 'form_mobile_invalid_5',
  msgStartWith2: 'form_mobile_invalid_6',
  msgInvalid: 'form_mobile_invalid_2',
  msgRequired: 'form_required',
};

export const EDIT_RECEIVER_RELATIONSHIP_NAME =
  'edit-receiver-relationship-name-field';
export const EDIT_RECEIVER_RELATIONSHIP_FIELD = {
  label: 'form_relationship_to_you',
  name: EDIT_RECEIVER_RELATIONSHIP_NAME,
  type: 'text',
  placeholder: 'form_relationship_to_you_placeholder',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
};

export const EDIT_RECEIVER_STREET_NAME = 'edit-receiver-street-field';
export const EDIT_RECEIVER_STATE_NAME = 'edit-receiver-state-field';
export const EDIT_RECEIVER_SUBURB_NAME = 'edit-receiver-suburb-field';
export const EDIT_RECEIVER_POSTAL_NAME = 'edit-receiver-postal-field';
export const EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS = [
  {
    label: 'label_address',
    name: EDIT_RECEIVER_STREET_NAME,
    type: 'text',
    placeholder: 'form_placeholder',
    tooltip: 'form_address_invalid_2',
    isRequired: true,
    isReadOnly: false,
    msgLimited: 'form_invalid_1',
    msgInvalid: 'form_address_invalid_3',
    msgRequired: 'form_required',
    checkValidFn: address2Valid,
  },
  {
    label: 'form_address_state_city',
    name: EDIT_RECEIVER_STATE_NAME,
    type: 'text',
    placeholder: 'form_placeholder_please_select',
    placeholderText: 'form_placeholder',
    tooltip: 'form_card_invalid_1',
    isRequired: true,
    isReadOnly: false,
    msgLimited: 'form_invalid_2',
    msgInvalid: 'form_address_invalid_4',
    msgRequired: 'form_required',
    checkValidFn: address3Valid,
  },
  {
    label: 'form_address_district',
    name: EDIT_RECEIVER_SUBURB_NAME,
    type: 'text',
    placeholder: 'form_placeholder_please_select',
    placeholderText: 'form_placeholder',
    tooltip: 'form_card_invalid_1',
    isRequired: true,
    isReadOnly: false,
    msgLimited: 'form_invalid_2',
    msgInvalid: 'form_address_invalid_5',
    msgRequired: 'form_required',
    checkValidFn: address2Valid,
  },
  {
    label: 'form_address_postal_code',
    name: EDIT_RECEIVER_POSTAL_NAME,
    type: 'tel',
    placeholder: '',
    isRequired: false,
    isReadOnly: false,
    msgInvalid: 'form_invalid_4',
    msgRequired: 'form_required',
  },
];

export const EDIT_RECEIVER_BANK_EWALLET_NAME =
  'edit-receiver-bank-ewallet-field';

export const EDIT_RECEIVER_TAB_REFERENCE = {
  0: 'personalDetails',
  1: 'residentialAddress',
  2: 'accountDetails',
};

export const EDIT_RECEIVER_TAB_CONTENT_REFERENCE = {
  0: 'label_general_details',
  1: 'label_address',
  2: 'label_account_details',
};

export const ADD_BANK_NAME = 'add-bank-name-field';
export const ADD_BANK_NAME_FIELD = {
  label: 'label_bank_name',
  name: ADD_BANK_NAME,
  type: 'text',
  placeholder: 'form_placeholder_please_select',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
  checkValidFn: textValid,
};

export const ADD_BANK_ACCOUNT_HOLDER_NAME = 'add-bank-account-holder-field';
export const ADD_BANK_ACCOUNT_NUMBER_NAME = 'add-bank-account-number-field';
export const ADD_BANK_SWIFT_NAME = 'add-bank-swift-field';
export const ADD_BANK_BSB_NAME = 'add-bank-bsb-field';
export const ADD_BANK_INPUT_FIELDS_INDIVIDUAL = [
  {
    label: 'label_bank_account_holder',
    name: ADD_BANK_ACCOUNT_HOLDER_NAME,
    type: 'text',
    placeholder: 'form_placeholder',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_name_invalid_1',
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
  {
    label: 'label_bank_account_number',
    name: ADD_BANK_ACCOUNT_NUMBER_NAME,
    type: 'text',
    placeholder: 'form_placeholder',
    tooltip: ``,
    isRequired: true,
    isReadOnly: false,
    msgNumberInvalid: 'form_mobile_invalid_4',
    msgExisted: 'form_bank_invalid_1',
    msgInvalid: 'form_card_invalid_6',
    msgRequired: 'form_required',
  },
  {
    label: 'SWIFT',
    name: ADD_BANK_SWIFT_NAME,
    type: 'tel',
    placeholder: 'form_placeholder',
    tooltip: `form_bank_tooltip_1`,
    isRequired: true,
    isReadOnly: false,
    msgNumberInvalid: 'form_mobile_invalid_4',
    msgExisted: '',
    msgInvalid: 'form_card_invalid_6',
    msgRequired: 'form_required',
  },
  {
    label: 'BSB',
    name: ADD_BANK_BSB_NAME,
    type: 'text',
    placeholder: 'form_placeholder',
    tooltip: ``,
    isRequired: true,
    isReadOnly: false,
    msgNumberInvalid: 'form_mobile_invalid_4',
    msgExisted: '',
    msgInvalid: 'form_card_invalid_6',
    msgRequired: 'form_required',
  },
];
export const ADD_BANK_INPUT_FIELDS_BUSINESS = [
  {
    label: 'label_bank_account_holder',
    name: ADD_BANK_ACCOUNT_HOLDER_NAME,
    type: 'text',
    placeholder: 'form_placeholder',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_name_invalid_1',
    msgRequired: 'form_required',
    checkValidFn: businessNameValid,
  },
  {
    label: 'label_bank_account_number',
    name: ADD_BANK_ACCOUNT_NUMBER_NAME,
    type: 'text',
    placeholder: 'form_placeholder',
    isRequired: true,
    isReadOnly: false,
    msgNumberInvalid: 'form_mobile_invalid_4',
    msgExisted: 'form_bank_invalid_1',
    msgInvalid: 'form_card_invalid_6',
    msgRequired: 'form_required',
  },
  {
    label: 'SWIFT',
    name: ADD_BANK_SWIFT_NAME,
    type: 'tel',
    placeholder: 'form_placeholder',
    tooltip: `form_bank_tooltip_1`,
    isRequired: true,
    isReadOnly: false,
    msgRequired: 'form_required',
    checkValidFn: numberValid,
  },
  {
    label: 'BSB',
    name: ADD_BANK_BSB_NAME,
    type: 'text',
    placeholder: 'form_placeholder',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_card_invalid_6',
    msgRequired: 'form_required',
  },
];

export const ADD_EWALLET_ID_NAME = 'add-ewallet-id-field';

export const ADD_EWALLET_NAME = 'add-ewallet-name-field';
export const ADD_EWALLET_NAME_FIELD = {
  label: 'form_ewallet_type',
  name: ADD_EWALLET_NAME,
  type: 'text',
  placeholder: 'form_placeholder_please_select',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
  checkValidFn: textValid,
};

export const ADD_EWALLET_NUMBER_NAME = 'add-ewallet-number-field';
export const ADD_EWALLET_NUMBER_FIELD = {
  label: 'label_ewallet_id',
  name: ADD_EWALLET_NUMBER_NAME,
  type: 'tel',
  placeholder: 'form_placeholder',
  isRequired: true,
  isReadOnly: false,
  msgExisted: 'form_ewallet_invalid_1',
  msgInvalid: 'form_card_invalid_6',
  msgRequired: 'form_required',
};

export const ADD_EWALLET_EXPIRY_NAME = 'add-ewallet-expiry-field';
export const ADD_EWALLET_EXPIRY_FIELD = {
  label: 'label_expiry_date',
  name: ADD_EWALLET_EXPIRY_NAME,
  type: 'text',
  placeholder: 'DD/MM/YYYY',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
  msgExpired: 'form_ewallet_expired',
  checkValidFn: dateValid,
};

export const PROPERTIES_NOT_IN_BUSINESS_RECEIVER_TYPE = [
  EDIT_RECEIVER_LASTNAME_NAME,
  EDIT_RECEIVER_FULLNAME_NAME,
];

export const RESET_EDIT_RECEIVER_INFORMATION_STORE = {
  personalDetails: {
    [EDIT_RECEIVER_COUNTRY_NAME]: '',
    [EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME]: '',
    [EDIT_RECEIVER_FISTNAME_NAME]: '',
    [EDIT_RECEIVER_LASTNAME_NAME]: '',
    [EDIT_RECEIVER_FULLNAME_NAME]: '',
    [EDIT_RECEIVER_GENDER_NAME]: '',
    [EDIT_RECEIVER_MOBILE_NAME]: '',
    [EDIT_RECEIVER_PHONE_NAME]: '',
    [EDIT_RECEIVER_RELATIONSHIP_NAME]: '',
  },
  residentialAddress: {
    [EDIT_RECEIVER_STREET_NAME]: '',
    [EDIT_RECEIVER_STATE_NAME]: '',
    [EDIT_RECEIVER_SUBURB_NAME]: '',
    [EDIT_RECEIVER_POSTAL_NAME]: '',
    isEditReceiverEnterAddressManuallySelected: false,
  },
  accountDetails: {
    banks: [],
    bank: {
      [ADD_BANK_NAME]: '',
      [ADD_BANK_ACCOUNT_HOLDER_NAME]: '',
      [ADD_BANK_ACCOUNT_NUMBER_NAME]: '',
      [ADD_BANK_SWIFT_NAME]: '',
      [ADD_BANK_BSB_NAME]: '',
      bankValidate: null,
    },
    ewallets: [],
    ewallet: {
      [ADD_EWALLET_NAME]: '',
      [ADD_EWALLET_NUMBER_NAME]: '',
      [ADD_EWALLET_EXPIRY_NAME]: '',
    },
  },
};
