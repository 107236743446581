import {
  default as PayidIcon,
  default as PayidInactiveIcon,
} from 'assets/ver2/icons/add-transaction/pm-payid-icon.svg';

import {
  default as VisaDebitIcon,
  default as VisaDebitIconColor,
} from 'assets/ver2/icons/add-transaction/pm-cc-icon.svg';

import {
  default as PayoutBankHHMTIcon,
  default as PayoutBankHHMTIconColor,
  default as PayoutBankRemoxIcon,
  default as PayoutBankRemoxIconColor,
} from 'assets/ver2/icons/add-transaction/dm-bank-icon.svg';

import {
  default as PayoutCashHHMTIcon,
  default as PayoutCashHHMTIconColor,
  default as PayoutCashRemoxIcon,
  default as PayoutCashRemoxIconColor,
} from 'assets/ver2/icons/add-transaction/dm-pickup-icon.svg';

import {
  default as PayoutEwalletHHMTIcon,
  default as PayoutEwalletHHMTIconColor,
  default as PayoutEwalletRemoxIcon,
  default as PayoutEwalletRemoxIconColor,
} from 'assets/ver2/icons/add-transaction/dm-ewallet-icon.svg';

import {
  default as PayoutHomeHHMTIcon,
  default as PayoutHomeHHMTIconColor,
  default as PayoutHomeRemoxIcon,
  default as PayoutHomeRemoxIconColor,
} from 'assets/ver2/icons/add-transaction/dm-home-icon.svg';
import {
  METHOD_BANK,
  METHOD_DEPOSIT,
  METHOD_EPAYMENT,
  METHOD_EWALLET,
  METHOD_HOME,
  METHOD_PAYID,
  METHOD_PICKUP,
  METHOD_POLI,
} from 'constants/transaction';
import { msgValid, textValid } from 'helpers';

export const SURCHARGE_RATE = 0.012;

export const ADD_TRANSACTION_STEP_REFERENCE = {
  0: 'receiverPayoutMethod',
  1: 'remittance',
  2: 'additionalInformation',
  3: 'paymentMethod',
};

export const ADD_TRANSACTION_RECEIVER_NAME = 'add-transaction-receiver-field';
export const ADD_TRANSACTION_RECEIVER_FIELD = {
  label: '',
  name: ADD_TRANSACTION_RECEIVER_NAME,
  type: 'text',
  placeholder: 'form_placeholder_please_select',
  placeholderText: 'form_placeholder',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_select_receiver_invalid',
  checkValidFn: textValid,
};

export const ADD_TRANSACTION_PAYOUT_METHODS = [
  {
    label: 'label_home_delivery',
    method: METHOD_HOME,
    icon: PayoutHomeHHMTIcon,
    iconColor: PayoutHomeHHMTIconColor,
    iconRemox: PayoutHomeRemoxIcon,
    iconRemoxColor: PayoutHomeRemoxIconColor,
  },
  {
    label: 'label_cash_pickup',
    method: METHOD_PICKUP,
    icon: PayoutCashHHMTIcon,
    iconColor: PayoutCashHHMTIconColor,
    iconRemox: PayoutCashRemoxIcon,
    iconRemoxColor: PayoutCashRemoxIconColor,
  },
  {
    label: 'label_bank_transfer',
    method: METHOD_BANK,
    icon: PayoutBankHHMTIcon,
    iconColor: PayoutBankHHMTIconColor,
    iconRemox: PayoutBankRemoxIcon,
    iconRemoxColor: PayoutBankRemoxIconColor,
  },
  {
    label: 'label_ewallet',
    method: METHOD_EWALLET,
    icon: PayoutEwalletHHMTIcon,
    iconColor: PayoutEwalletHHMTIconColor,
    iconRemox: PayoutEwalletRemoxIcon,
    iconRemoxColor: PayoutEwalletRemoxIconColor,
  },
];
export const ADD_TRANSACTION_PAYOUT_METHODS_OF_BUSINESS = [
  {
    label: 'label_bank_transfer',
    method: METHOD_BANK,
    icon: PayoutBankHHMTIcon,
    iconColor: PayoutBankHHMTIconColor,
    iconRemox: PayoutBankRemoxIcon,
    iconRemoxColor: PayoutBankRemoxIconColor,
  },
];

export const ADD_TRANSACTION_PAYOUT_METHOD_NAME =
  'add-transaction-payout-method-field';
export const ADD_TRANSACTION_PAYOUT_METHOD_FIELD = {
  label: '',
  name: ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  type: '',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_choose_payout_method_invalid_1',
};

export const ADD_TRANSACTION_METHOD_HOME_NAME =
  'add-transaction-method-home-field';

export const ADD_TRANSACTION_METHOD_PICKUP_NAME =
  'add-transaction-method-pickup-field';

export const ADD_TRANSACTION_METHOD_BANK_NAME =
  'add-transaction-method-bank-field';

export const ADD_TRANSACTION_METHOD_EWALLET_NAME =
  'add-transaction-method-ewallet-field';

export const ADD_TRANSACTION_YOU_SEND_NAME = 'add-transaction-you-send-field';
export const ADD_TRANSACTION_RECEIVER_GET_NAME =
  'add-transaction-receiver-get-field';
export const ADD_TRANSACTION_AMOUNT_FIELDS = [
  {
    label: 'form_you_send',
    name: ADD_TRANSACTION_YOU_SEND_NAME,
    type: 'text',
    placeholder: '0.00',
    isRequired: true,
    isReadOnly: false,
    msgMaxLimited: 'form_amount_invalid_1',
    msgMinLimited: 'form_amount_invalid_2',
    msgRequired: 'form_required',
  },
  {
    label: 'form_receiver_get',
    name: ADD_TRANSACTION_RECEIVER_GET_NAME,
    type: 'text',
    placeholder: '0.00',
    isRequired: true,
    isReadOnly: false,
    msgMaxLimited: 'form_amount_invalid_3',
    msgMinLimited: 'form_amount_invalid_4',
    msgRequired: 'form_required',
    msgServiceAlert: 'form_service_alerts_validation_2',
  },
];

export const ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME =
  'add-transaction-you-send-another-field';
export const ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME =
  'add-transaction-receiver-get-another-field';
export const ADD_TRANSACTION_AMOUNT_ANOTHER_FIELDS = [
  {
    label: 'form_you_send',
    name: ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
    type: 'text',
    placeholder: '0.00',
    isRequired: true,
    isReadOnly: false,
    msgMaxLimited: 'form_amount_invalid_1',
    msgMinLimited: 'form_amount_invalid_2',
    msgRequired: 'form_required',
  },
  {
    label: 'form_receiver_get',
    name: ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME,
    type: 'text',
    placeholder: '0.00',
    isRequired: true,
    isReadOnly: false,
    msgMaxLimited: 'form_amount_invalid_3',
    msgMinLimited: 'form_amount_invalid_4',
    msgRequired: 'form_required',
    msgServiceAlert: 'form_service_alerts_validation_2',
  },
];

export const ADD_TRANSACTION_RELATIONSHIP_NAME =
  'add-transaction-relationship-field';
export const ADD_TRANSACTION_RELATIONSHIP_FIELD = {
  label: 'form_relationship_to_you',
  name: ADD_TRANSACTION_RELATIONSHIP_NAME,
  type: 'text',
  placeholder: 'form_placeholder_please_select',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
};

export const ADD_TRANSACTION_MESSAGE_NAME = 'add-transaction-message-field';
export const ADD_TRANSACTION_MESSAGE_FIELD = {
  label: 'form_message',
  name: ADD_TRANSACTION_MESSAGE_NAME,
  type: 'text',
  placeholder: 'form_placeholder',
  isRequired: false,
  isReadOnly: false,
  msgInvalid: 'form_message_invalid_1',
  checkValidFn: msgValid,
};

export const ADD_TRANSACTION_PURPOSE_NAME = 'add-transaction-purpose-field';
export const ADD_TRANSACTION_PURPOSE_FIELD = {
  label: 'form_purpose_transaction',
  name: ADD_TRANSACTION_PURPOSE_NAME,
  type: 'text',
  placeholder: 'form_placeholder_please_select',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
  checkValidFn: textValid,
};

export const ADD_TRANSACTION_OCCUPATION_NAME =
  'add-transaction-occupation-field';
export const ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME =
  'add-transaction-previous-employed-field';
export const ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS = [
  {
    label: 'form_your_occupation',
    name: ADD_TRANSACTION_OCCUPATION_NAME,
    type: 'text',
    placeholder: 'form_placeholder_please_select',
    isRequired: true,
    isReadOnly: false,
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
  {
    label: 'form_previous_employed',
    name: ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME,
    type: 'text',
    placeholder: 'form_placeholder_please_select',
    isRequired: true,
    isReadOnly: false,
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
];

export const ADD_TRANSACTION_SOURCE_NAME = 'add-transaction-source-field';
export const ADD_TRANSACTION_SOURCE_FIELD = {
  label: 'form_source_of_funds',
  name: ADD_TRANSACTION_SOURCE_NAME,
  type: 'text',
  placeholder: 'form_placeholder_please_select',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
};

export const HHMT_ADD_TRANSACTION_PAYMENT_METHODS = [
  {
    label: 'label_bank_transfer',
    desc: '',
    method: METHOD_DEPOSIT,
    visible: 'depositVisible',
    icon: PayoutBankHHMTIcon,
    iconColor: PayoutBankHHMTIconColor,
  },
  {
    label: 'label_fast_bank_transfer',
    desc: 'label_real_time',
    method: METHOD_PAYID,
    visible: 'payIdVisible',
    icon: PayidInactiveIcon,
    iconColor: PayidIcon,
  },
  {
    label: 'label_credit_debit_card',
    desc: 'label_surcharge',
    payLimitDesc: 'label_amount',
    method: METHOD_EPAYMENT,
    visible: 'epaymentVisible',
    icon: VisaDebitIcon,
    iconColor: VisaDebitIconColor,
  },
];

export const REMOX_ADD_TRANSACTION_PAYMENT_METHODS = [
  {
    label: 'label_bank_transfer',
    desc: '',
    method: METHOD_DEPOSIT,
    visible: 'depositVisible',
    icon: PayoutBankHHMTIcon,
    iconColor: PayoutBankRemoxIconColor,
  },
  {
    label: 'label_fast_bank_transfer',
    desc: 'label_real_time',
    method: METHOD_PAYID,
    visible: 'payIdVisible',
    icon: PayidInactiveIcon,
    iconColor: PayidIcon,
  },
  {
    label: 'label_credit_debit_card',
    desc: 'label_surcharge',
    payLimitDesc: 'label_amount',
    method: METHOD_EPAYMENT,
    visible: 'epaymentVisible',
    icon: VisaDebitIcon,
    iconColor: VisaDebitIconColor,
  },
];

export const ADD_TRANSACTION_PAYMENT_METHOD_INSTRUCTION = {
  [METHOD_PAYID]: `add_transaction_instruction_payid`,
  [METHOD_POLI]: `add_transaction_instruction_poli`,
  [METHOD_DEPOSIT]: `add_transaction_instruction_deposit`,
  [METHOD_EPAYMENT]: `add_transaction_instruction_epayment`,
};

export const ADD_TRANSACTION_PAYMENT_METHOD_NAME =
  'add-transaction-payment-method-field';
export const ADD_TRANSACTION_PAYMENT_METHOD_FIELD = {
  label: '',
  name: ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  type: '',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_choose_payment_method_invalid',
};

export const ADD_TRANSACTION_METHOD_PAYID_NAME =
  'add-trarsaction-method-payid-field';

export const ADD_TRANSACTION_METHOD_POLI_NAME =
  'add-trarsaction-method-poli-field';

export const ADD_TRANSACTION_TICK_CHECKBOX =
  'add-transaction-tick-checkbox-field';
export const ADD_TRANSACTION_TICK_CHECKBOX_FIELD = {
  label: '',
  name: ADD_TRANSACTION_TICK_CHECKBOX,
  type: 'checkbox',
  isRequired: true,
  isReadOnly: true,
};

export const PAYMENT_BY_DEPOSIT_NAME = 'payment-by-deposit-name-field';
export const PAYMENT_BY_DEPOSIT_NAME_FIELD = {
  label: 'form_name_on_card',
  name: PAYMENT_BY_DEPOSIT_NAME,
  type: 'text',
  placeholder: 'form_placeholder',
  isRequired: true,
  isReadOnly: false,
  msgInvalid2: 'form_name_on_card_invalid_1',
  msgInvalid: 'form_name_invalid_1',
  msgRequired: 'form_required',
  checkValidFn: textValid,
};

export const PAYMENT_BY_DEPOSIT_NUMBER_NAME = 'payment-by-deposit-number-field';
export const PAYMENT_BY_DEPOSIT_NUMBER_FIELD = {
  label: 'form_card_number',
  name: PAYMENT_BY_DEPOSIT_NUMBER_NAME,
  type: 'tel',
  inputMode: 'numeric',
  placeholder: 'XXXX - XXXX - XXXX - XXXX',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_card_invalid_4',
  msgNotSupport: 'form_card_invalid_5',
  msgNotAccepted: 'form_card_invalid_8',
  msgRequired: 'form_required',
};

export const PAYMENT_BY_DEPOSIT_EXPIRY_NAME = 'payment-by-deposit-expiry-field';
export const PAYMENT_BY_DEPOSIT_EXPIRY_FIELD = {
  label: 'label_expiry_date',
  name: PAYMENT_BY_DEPOSIT_EXPIRY_NAME,
  type: 'tel',
  inputMode: 'numeric',
  placeholder: 'MM/YY',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_card_invalid_7',
  msgRequired: 'form_required',
  checkValidFn: textValid,
};

export const PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME =
  'payment-by-deposit-cvc-field';
export const PAYMENT_BY_DEPOSIT_CVC_NUMBER_FIELD = {
  label: 'CVC',
  name: PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME,
  type: 'tel',
  inputMode: 'numeric',
  placeholder: 'XXX',
  tooltip: 'form_card_invalid_1',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_card_invalid_3',
  msgRequired: 'form_required',
};
export const PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME =
  'payment-by-deposit-cvv-field';
export const PAYMENT_BY_DEPOSIT_CVV_NUMBER_FIELD = {
  label: 'CVV',
  name: PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME,
  type: 'tel',
  inputMode: 'numeric',
  placeholder: 'XXX',
  tooltip: 'form_card_invalid_1',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_card_invalid_2',
  msgRequired: 'form_required',
};

export const PAYMENT_EMAIL_NAME = 'payment-email-field';
export const PAYMENT_EMAIL_FIELD = {
  label: 'form_email',
  name: PAYMENT_EMAIL_NAME,
  type: 'text',
  isReadOnly: true,
};

export const PAYMENT_AMOUNT_NAME = 'payment-amount-field';
export const PAYMENT_AMOUNT_FIELD = {
  label: 'label_amount',
  name: PAYMENT_AMOUNT_NAME,
  type: 'text',
  isReadOnly: true,
};

export const PAYMENT_REFERENCE_NAME = 'payment-reference-field';
export const PAYMENT_REFERENCE_FIELD = {
  label: 'label_ref_number',
  name: PAYMENT_REFERENCE_NAME,
  type: 'text',
  isReadOnly: true,
};

export const PAYMENT_ACCOUNT_NAME = 'payment-account-field';
export const PAYMENT_ACCOUNT_FIELD = {
  label: 'label_account_name',
  name: PAYMENT_ACCOUNT_NAME,
  type: 'text',
  isReadOnly: true,
};

export const PAYMENT_BSB_NAME = 'payment-bsb-field';
export const PAYMENT_BSB_FIELD = {
  label: 'label_bsb',
  name: PAYMENT_BSB_NAME,
  type: 'text',
  isReadOnly: true,
};

export const PAYMENT_ACCOUNT_NUMBER_NAME = 'payment-account-number-field';
export const PAYMENT_ACCOUNT_NUMBER_FIELD = {
  label: 'label_bank_account_number',
  name: PAYMENT_ACCOUNT_NUMBER_NAME,
  type: 'text',
  isReadOnly: true,
};

export const PAYMENT_CREADIT_CARD_NO = 'payment_creadit_card_no_name';
export const PAYMENT_CREADIT_CARD_TYPE = 'payment_creadit_card_type_name';

export const RESET_PAYMENT_BY_DEPOSIT_STORE = {
  [PAYMENT_BY_DEPOSIT_NAME]: '',
  [PAYMENT_BY_DEPOSIT_NUMBER_NAME]: '',
  [PAYMENT_BY_DEPOSIT_EXPIRY_NAME]: '',
  [PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME]: '',
  [PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME]: '',
};

export const RESET_ADD_TRANSACTION_INFORMATION_STORE = {
  receiverPayoutMethod: {
    [ADD_TRANSACTION_RECEIVER_NAME]: null,
    [ADD_TRANSACTION_PAYOUT_METHOD_NAME]: '',
    currentPayoutItemSelectedIndex: -1,
  },
  remittance: {
    [ADD_TRANSACTION_YOU_SEND_NAME]: 0,
    [ADD_TRANSACTION_RECEIVER_GET_NAME]: 0,
    [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: 0,
    [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: 0,
    isAddAnotherReceivingCurrency: false,
    currentCurrencySelected: null,
    currentCurrencySelectedAnother: null,
    currentRateStore: null,
    currentRateAnotherStore: null,
    feeAmount: 0,
  },
  additionalInformation: {
    [ADD_TRANSACTION_RELATIONSHIP_NAME]: '',
    [ADD_TRANSACTION_OCCUPATION_NAME]: '',
    [ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]: '',
    [ADD_TRANSACTION_PURPOSE_NAME]: '',
    [ADD_TRANSACTION_SOURCE_NAME]: '',
    [ADD_TRANSACTION_MESSAGE_NAME]: '',
  },
  paymentMethod: {
    [ADD_TRANSACTION_PAYMENT_METHOD_NAME]: null,
    [PAYMENT_CREADIT_CARD_NO]: '',
    [PAYMENT_CREADIT_CARD_TYPE]: '',
    discountsAvailable: [],
    discountsInactive: [],
    discountApplied: null,
  },
};

export const BANK_NAMES_VALIDATION_HOLIDAY = [
  'AB BANK (NGAN HANG AN BINH)',
  'ACB (NGAN HANG A CHAU)',
  'AGRIBANK (NGAN HANG NONG NGHIEP VA PHAT TRIEN NONG THON VIETNAM)',
  'ANZ BANK (NGAN HANG ANZ)',
  'BAC A BANK (NGAN HANG TMCP BAC A)',
  'BAO VIET BANK (NGAN HANG BAO VIET)',
  'BIDV BANK (NGAN HANG DAU TU VA PHAT TRIEN VIET NAM)',
  'CIMB BANK',
  'CITI BANK (NGAN HANG CITIBANK VIET NAM)',
  'COMMONWEALTH BANK (NGAN HANG COMMONWEALTH BANK VIET NAM)',
  'DAI A BANK (NGAN HANG TMCP DAI A)',
  'DONGA BANK (NGAN HANG DONG A)',
  'EXIMBANK (NGAN HANG TMCP XUAT NHAP KHAU VIET NAM)',
  'GP BANK (NGAN HANG THUONG MAI TNHH MTV DAU KHI TOAN CAU)',
  'HDBANK (NGAN HANG TMCP PHAT TRIEN THANH PHO HO CHI MINH)',
  'HONG LEONG BANK (NGAN HANG TNHH MTV HONG LEONG BANK)',
  'HSBC BANK (NGAN HANG TNHH MTV HSBC VIET NAM)',
  'INDOVINA BANK (NGAN HANG TNHH INDOVINA)',
  'INDUSTRIAL BANK OF KOREA (NGAN HANG CONG NGHIEP HAN QUOC)',
  'KIENLONGBANK (NGAN HANG TMCP KIEN LONG)',
  'LIEN VIET POST BANK (NGAN HANG TMCP BUU DIEN LIEN VIET)',
  'MARITIME BANK (NGAN HANG TMCP HANG HAI)',
  'MB BANK (NGAN HANG TMCP QUAN DOI)',
  'MEKONG BANK (NGAN HANG TMCP PHAT TRIEN ME KONG)',
  'NAM A BANK (NGAN HANG TMCP NAM A)',
  'NCB BANK (NGAN HANG TMCP QUOC DAN)',
  'OCB BANK (NGAN HANG TMCP PHUONG DONG)',
  'OCEANBANK (NGAN HANG THUONG MAI TNHH MTV DAI DUONG)',
  'PG BANK (NGAN HANG TMCP XANG DAU PETROLIMEX)',
  'PUBLIC BANK VIETNAM LIMITED',
  'PVCOMBANK (NGAN HANG TMCP DAI CHUNG VIET NAM)',
  'SACOMBANK (NGAN HANG TMCP SAI GON THUONG TIN)',
  'SAIGON BANK (NGAN HANG TMCP SAI GON CONG THUONG)',
  'SCB BANK (NGAN HANG TMCP SAI GON)',
  'SEABANK (NGAN HANG TMCP DONG NAM A)',
  'SHB (NGAN HANG TMCP SAI GON - HA NOI)',
  'SHINHAN BANK (NGAN HANG TNHH MTV SHINHAN VIET NAM)',
  'TECHCOMBANK (NGAN HANG TMCP KY THUONG VIET NAM)',
  'TPBANK (NGAN HANG TMCP TIEN PHONG)',
  'TRUSTBANK (NGAN HANG TMCP DAI TIN)',
  'UOB BANK (NGAN HANG TNHH MTV UNITED OVERSEAS BANK VIETNAM)',
  'VIB BANK (NGAN HANG TMCP QUOC TE VIET NAM)',
  'VIET A BANK (NGAN HANG TMCP VIET A)',
  'VIET CAPITAL BANK (NGAN HANG TMCP BAN VIET)',
  'VIETBANK (NGAN HANG TMCP VIET NAM THUONG TIN)',
  'VIETCOMBANK (NGAN HANG TMCP NGOAI THUONG VIET NAM)',
  'VIETINBANK (NGAN HANG TMCP CONG THUONG VIET NAM)',
  'VPBANK (NGAN HANG TMCP VIET NAM THINH VUONG)',
  'VRBANK (NGAN HANG LIEN DOANH VIET - NGA)',
  'WOORIBANK',
];
