import Offers from 'components/Offers/components/Offers/ver2';
import OffersMobile from 'components/Offers/components/Offers/ver2/mobile';
import CollapsibleSideMenu from 'components/common/CollapsibleSideMenu';
import NavbarFooter from 'components/common/NavbarFooter/ver2';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useStore from 'hooks/useStore';
import React from 'react';
import styled from 'styled-components';

const OffersPage = () => {
  const { state } = useStore();
  const { isMenuExpand } = state; // For Collapsible Menu
  const { isMobile, isTablet } = useDetectDevice();

  if (isMobile || isTablet) {
    return (
      <OffersPageStyled>
        <OffersMobile />
        <NavbarFooter />
      </OffersPageStyled>
    );
  }
  return (
    <LayoutContainer>
      <CollapsibleSideMenu />
      <OuterContainerStyled isMenuExpand={isMenuExpand}>
        <OffersPageStyled>
          <Offers />
        </OffersPageStyled>
      </OuterContainerStyled>
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div`
  display: flex;
  height: 100dvh;
`;

const OuterContainerStyled = styled.div`
  flex-grow: 1;
  margin-left: ${(props) => (props.isMenuExpand ? '300px' : '98px')};
  transition: margin-left 0.3s;
  height: fit-content;
`;

const OffersPageStyled = styled.div`
  margin: 70px 0px 0px 0px;
  box-shadow: none;
  height: calc(100dvh - 70px);
  padding: 16px 24px;
  position: relative;
  background-color: var(--ds-c-white);
  border-radius: 12px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: fit-content;
    margin: 96px 50px 47px 0;
    padding: 24px 40px;
  }
`;

export default OffersPage;
