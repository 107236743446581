import NavbarFooter from 'components/common/NavbarFooter/ver2';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { useDetectDevice } from 'hooks/useDetectDevice';
import styled from 'styled-components';

const MaintenancePage = () => {
  const { isMobile, isTablet } = useDetectDevice();

  return (
    <NotFoundStyled>
      <ContentBody>
        <p>The site is currently down for maintenance.</p>
        <span>We will be back online shortly.</span>
      </ContentBody>
      {(isMobile || isTablet) && <NavbarFooter />}
    </NotFoundStyled>
  );
};

const NotFoundStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100dvh - 70px);
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100dvh - 120px);
    padding-top: 24px;
    padding-bottom: 0px;
    margin-top: 72px;
  }
`;

const ContentBody = styled.div`
  display: grid;
  justify-content: center;
  width: 247px;
  position: relative;

  & p {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;

    color: var(--c-grey-dark);
    margin-top: 40px;
    margin-bottom: 8px;
  }

  & span {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    text-align: center;

    color: var(--c-sub-info);
    margin-bottom: 0px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 500px;

    & p {
      font-size: 32px;
      line-height: 40px;
    }

    & span {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export default MaintenancePage;
