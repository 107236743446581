import { api } from 'api';
import { getNumberUnmaskStr } from 'components/FormEditReceiver/func';
import { DEBOUNCE_SEC, DESKTOP_MIN_WIDTH } from 'constants';
import { formatter } from 'helpers';
import { checkMaintenance } from 'helpers/maintenance';
import useDebounceCallback from 'hooks/useDebounceCallback';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import { numberUtils, stringUtils } from 'utils';

const FormInputMobileNumberValidation = ({
  field,
  config,
  numberMaskStr,
  setNumberMaskStr,
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();
  const { dispatch } = useStore();
  const { isDesktop } = useDetectDevice();

  const isConfigRequired = config?.required;
  const isConfigVisible = config?.visible;
  const placeholder = config?.example;
  const numberFormat = formatter.formatParse(config?.format);
  const prefixFormat = formatter.formatParse(config?.prefix);
  const numberMaxLength = config?.max;
  const numberMinLength = config?.min;

  useEffect(() => {
    if (!information[field.name]) return;
    if (numberMaskStr) return;
    if (information[field.name]) {
      const numberMask = formatter.mask(
        getNumberUnmaskStr(information[field.name], numberFormat),
        numberFormat
      );
      setNumberMaskStr(numberMask);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information, numberMaskStr]);

  const handleNumberValidation = async (value, name, validation) => {
    try {
      const { data } = await api.validatePhone('AU', value);

      if (data) {
        const isValid = data.data.status === 'valid';

        validation = {
          ...validation,
          [name]: isValid ? '' : field.msgNumberInvalid,
        };

        dispatch({ type: SET_SHOW_LOADING, payload: false });

        return validation;
      }
    } catch (error) {
      checkMaintenance(error);
      console.error(error?.message);

      dispatch({ type: SET_SHOW_LOADING, payload: false });
    }

    return validation;
  };
  const checkNumberValid = async (value, validation) => {
    validation = await handleNumberValidation(value, field.name, validation);

    setValidation(validation);
  };
  const { debounce: handleNumberValid } = useDebounceCallback(
    (value, validation) => {
      checkNumberValid(value, validation);
    },
    DEBOUNCE_SEC
  );

  const handleOnChange = async (e) => {
    let { value, name } = e.target;
    const valueTrim = value.trim();

    const numberUnmaskStr = getNumberUnmaskStr(valueTrim, numberFormat);

    if (numberUnmaskStr?.length > numberMaxLength) return;

    if (field.type === 'tel' && !numberUtils.isNumber(numberUnmaskStr)) return;

    if (!numberUnmaskStr) {
      const newInfomation = {
        ...information,
        [field.name]: '',
      };
      setInformation(newInfomation);

      const newValidation = {
        ...validation,
        [field.name]: '',
      };
      setValidation(newValidation);

      setNumberMaskStr('');

      return;
    }

    let newInformation = {
      ...information,
    };

    let newValidation = {
      ...validation,
    };

    const numberStr = formatter.mask(numberUnmaskStr, numberFormat);
    setNumberMaskStr(numberStr);

    newValidation = {
      ...newValidation,
      [name]: '',
    };

    if (prefixFormat !== '') {
      if (Array.isArray(prefixFormat) && prefixFormat?.length > 1) {
        if (!prefixFormat?.includes(numberUnmaskStr[0])) {
          newValidation = {
            ...newValidation,
            [name]: field.msgStartWith2,
          };
        }
      }

      if (
        !Array.isArray(prefixFormat) ||
        (Array.isArray(prefixFormat) && prefixFormat?.length === 1)
      ) {
        const prefixMask = formatter.mask(prefixFormat, prefixFormat);

        if (prefixMask?.length > 1) {
          if (numberUnmaskStr?.slice(0, 2) !== prefixMask) {
            newValidation = {
              ...newValidation,
              [name]: field.msgStartWith,
            };
          }
        }

        if (prefixMask?.length === 1) {
          if (numberUnmaskStr[0] !== prefixMask) {
            newValidation = {
              ...newValidation,
              [name]: field.msgStartWith,
            };
          }
        }
      }
    }

    const isCheckNumber =
      (numberUnmaskStr?.length === numberMaxLength ||
        numberUnmaskStr?.length === numberMinLength) &&
      !newValidation[field.name];
    if (isCheckNumber) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      newInformation = {
        ...newInformation,
        [name]: numberUnmaskStr,
      };
      setInformation(newInformation);

      handleNumberValid(numberUnmaskStr, newValidation);
    }

    setValidation(newValidation);
  };

  const handleOnBlur = () => {
    let newValidation = { ...validation };

    const numberUnmaskStr = getNumberUnmaskStr(numberMaskStr, numberFormat);

    if (isConfigVisible && numberUnmaskStr && !validation[field.name]) {
      if (
        (numberUnmaskStr.length > 0 &&
          numberUnmaskStr.length < numberMinLength) ||
        (numberUnmaskStr.length > numberMinLength &&
          numberUnmaskStr.length < numberMaxLength)
      ) {
        newValidation = {
          ...newValidation,
          [field.name]: field.msgInvalid,
        };
      }

      setValidation(newValidation);

      return;
    }
  };

  const handleFormLabelOnClick = (e, name) => {
    e.preventDefault();

    if (isDesktop) {
      const inputEle = document.getElementsByName(name)[0];

      if (inputEle) {
        inputEle.focus();
      }
    }
  };

  const getMessageValidation = () => {
    const numberUnmaskStr = getNumberUnmaskStr(numberMaskStr, numberFormat);

    if (config?.prefix !== '') {
      if (Array.isArray(prefixFormat) && prefixFormat?.length > 1) {
        const prefixMask1 = formatter.mask(prefixFormat[0], prefixFormat[0]);
        const prefixMask2 = formatter.mask(prefixFormat[1], prefixFormat[1]);

        return stringUtils.replaceKeyword(t(validation[field.name]), [
          {
            key: 'prefix1',
            value: prefixMask1,
          },
          {
            key: 'prefix2',
            value: prefixMask2,
          },
        ]);
      }

      if (
        !Array.isArray(prefixFormat) ||
        (Array.isArray(prefixFormat) && prefixFormat?.length === 1)
      ) {
        return t(validation[field.name]);
      }
    }

    if (numberUnmaskStr?.length < numberMinLength) {
      return t(validation[field.name])?.replace(10, numberMinLength);
    }

    if (
      numberUnmaskStr?.length > numberMinLength &&
      numberUnmaskStr?.length < numberMaxLength
    ) {
      return t(validation[field.name])?.replace(10, numberMaxLength);
    }

    return t(validation[field.name]);
  };

  return (
    <FormGroupStyled>
      <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
        {t(field.label)}
        {(isConfigRequired ?? field.isRequired) && <span>*</span>}
      </FormLabel>
      <FormControl
        type={field.type}
        name={field.name}
        value={numberMaskStr || ''}
        placeholder={placeholder || t(field.placeholder)}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        isInvalid={validation[field.name]}
        readOnly={field.isReadOnly}
        inputMode="numeric"
      />
      <FormControlFeedback hidden={false} type="invalid">
        {getMessageValidation()}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: var(--ds-c-white);

  &:focus {
    background: var(--ds-c-white) !important;
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 10px !important;
  }

  &[readonly] {
    background: var(--ds-c-white) !important;
    border: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputMobileNumberValidation;
