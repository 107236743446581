import { api } from 'api';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  EDIT_RECEIVER_COUNTRY_NAME,
  EDIT_RECEIVER_FISTNAME_NAME,
  EDIT_RECEIVER_GENDER_NAME,
  EDIT_RECEIVER_LASTNAME_NAME,
  EDIT_RECEIVER_MOBILE_NAME,
  EDIT_RECEIVER_PHONE_NAME,
  EDIT_RECEIVER_POSTAL_NAME,
  EDIT_RECEIVER_RELATIONSHIP_NAME,
  EDIT_RECEIVER_STATE_NAME,
  EDIT_RECEIVER_STREET_NAME,
  EDIT_RECEIVER_SUBURB_NAME,
  METHOD_BANK,
  METHOD_EWALLET,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useDeliveryMethods from 'hooks/useDeliveryMethods';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { arrayUtils, stringUtils } from 'utils';

import CustomBanksAccordion from 'components/FormEditReceiver/components/CustomBanksAccordion';
import CustomEwalletsAccordion from 'components/FormEditReceiver/components/CustomEwalletsAccordion';
import FormAddNewBankAccount from 'components/FormEditReceiver/components/FormAddNewBankAccount';
import FormAddNewEwalletAccount from 'components/FormEditReceiver/components/FormAddNewEwalletAccount';
import FormInputBankEwalletRadio from 'components/FormEditReceiver/components/FormInputBankEwalletRadio';
import { fetchReceiverInformation } from 'components/Receivers/func';
import {
  ADD_BANK_ACCOUNT_HOLDER_NAME,
  ADD_BANK_ACCOUNT_NUMBER_NAME,
  ADD_BANK_BSB_NAME,
  ADD_BANK_NAME,
  ADD_BANK_SWIFT_NAME,
  DESKTOP_MIN_WIDTH,
  EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME,
} from 'constants';
import { checkMaintenance } from 'helpers/maintenance';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import useReceiver from 'hooks/receiver/useReceiver';
import { useDetectDevice } from 'hooks/useDetectDevice';
import { isEmpty } from 'lodash';
import { Fade } from 'react-bootstrap';
import { SET_SHOW_LOADING } from 'store/action';
import BodySectionHeader from '../../BodySectionHeader';

const initialFormField = RESET_EDIT_RECEIVER_INFORMATION_STORE.accountDetails;
const initialFormBank =
  RESET_EDIT_RECEIVER_INFORMATION_STORE.accountDetails.bank;
const initialFormEwallet =
  RESET_EDIT_RECEIVER_INFORMATION_STORE.accountDetails.ewallet;

const AccountDetails = ({
  countryConfig,
  currentStep,
  setCurrentStep,
  handleOnToggleCancelPopup,
}) => {
  const { t } = useLang();
  const { token } = useAuth();
  const { state, dispatch } = useStore();
  const { editReceiverInformation } = state;
  const { personalDetails, residentialAddress } = editReceiverInformation || {};

  const { isMobile, isTablet } = useDetectDevice();

  const deliveryMethods = useDeliveryMethods(
    countryConfig ? countryConfig?.countryCode : null
  );

  const { currentBusinessReceiver, resetCurrentBusinessReceiver } =
    useFormAddTransactionStore();

  const { isCreateBusinessReceiver, isCreateIndividualReceiver } =
    useReceiver();

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(null);
  const [isBanksChecked, setBanksChecked] = useState(true);
  const [isOpenFormAddNewBank, setOpenFormAddNewBank] = useState(false);
  const [isOpenFormAddNewEwallet, setOpenFormAddNewEwallet] = useState(false);
  const [bankInformation, setBankInformation] = useState(initialFormBank);
  const [bankValidation, setBankValidation] = useState(initialFormBank);
  const [ewalletInformation, setEwalletInformation] =
    useState(initialFormEwallet);
  const [ewalletValidation, setEwalletValidation] =
    useState(initialFormEwallet);

  const isShowDesc =
    arrayUtils.isArrayEmpty(information?.banks) &&
    arrayUtils.isArrayEmpty(information?.ewallets);

  const isReceiverOfIndividualType =
    personalDetails[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME] === 1;
  const isReceiverOfBusinessType =
    personalDetails[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME] === 2;

  useEffect(() => {
    if (
      deliveryMethods?.includes(METHOD_BANK) &&
      deliveryMethods?.includes(METHOD_EWALLET)
    ) {
      return;
    }

    if (deliveryMethods?.includes(METHOD_BANK)) {
      return;
    }

    if (deliveryMethods?.includes(METHOD_EWALLET)) {
      setBanksChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryMethods]);

  const handleOnToggleFormAddNewBank = () => {
    setBankInformation(initialFormBank);

    setOpenFormAddNewBank(!isOpenFormAddNewBank);

    setValidation(null);
  };

  const handleTriggerOpenFormAddNewBank = () => {
    const { bank } = currentBusinessReceiver || {};

    const isBankExistIntoBanks =
      information?.banks?.find(
        (b) =>
          b?.[ADD_BANK_NAME] === bank?.[ADD_BANK_NAME] &&
          b?.[ADD_BANK_ACCOUNT_NUMBER_NAME] ===
            bank?.[ADD_BANK_ACCOUNT_NUMBER_NAME]
      ) ||
      information?.banks?.length ||
      false;
    if (isBankExistIntoBanks) return;

    setOpenFormAddNewBank(true);

    const newBankInformation = {
      ...initialFormBank,
      [ADD_BANK_NAME]: bank?.[ADD_BANK_NAME] || '',
      [ADD_BANK_ACCOUNT_HOLDER_NAME]:
        bank?.[ADD_BANK_ACCOUNT_HOLDER_NAME] || '',
      [ADD_BANK_ACCOUNT_NUMBER_NAME]:
        bank?.[ADD_BANK_ACCOUNT_NUMBER_NAME] || '',
      [ADD_BANK_SWIFT_NAME]: bank?.[ADD_BANK_SWIFT_NAME] || '',
      [ADD_BANK_BSB_NAME]: bank?.[ADD_BANK_BSB_NAME] || '',
    };
    setBankInformation(newBankInformation);

    setValidation(null);
  };
  useEffect(() => {
    if (
      !isEmpty(currentBusinessReceiver) &&
      (isCreateBusinessReceiver || isCreateIndividualReceiver)
    ) {
      handleTriggerOpenFormAddNewBank();
    }
  }, [
    currentBusinessReceiver,
    isCreateBusinessReceiver,
    isCreateIndividualReceiver,
  ]);

  const handleOnToggleFormAddNewEwallet = () => {
    setEwalletInformation(initialFormEwallet);

    setOpenFormAddNewEwallet(!isOpenFormAddNewEwallet);
  };

  const handleBackStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderAccountTypeReference = {
    [true]: (
      <CustomBanksAccordion
        banks={information?.banks}
        countryConfig={countryConfig}
        information={information}
        setInformation={setInformation}
        bankInformation={bankInformation}
        validation={validation}
        setBankInformation={setBankInformation}
        bankValidation={bankValidation}
        setBankValidation={setBankValidation}
        handleOnToggleFormAddNewBank={handleOnToggleFormAddNewBank}
      />
    ),
    [false]: (
      <CustomEwalletsAccordion
        ewallets={information?.ewallets}
        countryConfig={countryConfig}
        information={information}
        setInformation={setInformation}
        ewalletInformation={ewalletInformation}
        setEwalletInformation={setEwalletInformation}
        ewalletValidation={ewalletValidation}
        setEwalletValidation={setEwalletValidation}
        handleOnToggleFormAddNewEwallet={handleOnToggleFormAddNewEwallet}
      />
    ),
  };

  const handleOnClickDeleteBankPopup = async () => {
    if (isEmpty(currentBusinessReceiver)) return;

    const { receiver, bank } = currentBusinessReceiver || {};

    if (!receiver?.receiverId || !bank?.id) return;

    try {
      await api.deleteBank(token, receiver?.receiverId, bank?.id);
    } catch (error) {
      checkMaintenance(error);
      console.error(error?.message);
      dispatch({ type: SET_SHOW_LOADING, payload: false });
    }
  };

  const banksTransform = (banks) => {
    if (isEmpty(banks)) return [];
    return banks.map((bank) => ({
      ...bank,
      accountNumber: stringUtils.replaceAllCharacter(bank.accountNumber),
    }));
  };

  const handleSave = async () => {
    if (isReceiverOfBusinessType && !information?.banks?.length) {
      setValidation(true);
      return;
    }

    dispatch({ type: SET_SHOW_LOADING, payload: true });

    const isAllowNext =
      Object.values(bankValidation).every((value) => !value) &&
      Object.values(ewalletValidation).every((value) => !value);

    if (isAllowNext) {
      const receiverPayload = {
        firstname: personalDetails[EDIT_RECEIVER_FISTNAME_NAME],
        lastname: personalDetails[EDIT_RECEIVER_LASTNAME_NAME],
        gender: personalDetails[EDIT_RECEIVER_GENDER_NAME],
        relationship: personalDetails[EDIT_RECEIVER_RELATIONSHIP_NAME]?.key,
        dob: null,
        banks: banksTransform(information.banks),
        ewallets: information.ewallets,
        email: null,
        mobile: personalDetails[EDIT_RECEIVER_MOBILE_NAME]
          ? {
              id: 0,
              number: personalDetails[EDIT_RECEIVER_MOBILE_NAME],
            }
          : null,
        phone: personalDetails[EDIT_RECEIVER_PHONE_NAME]
          ? {
              id: 0,
              number: personalDetails[EDIT_RECEIVER_PHONE_NAME],
            }
          : null,
        favorited: false,
        address: {
          id: 0,
          street: residentialAddress[EDIT_RECEIVER_STREET_NAME],
          suburb: residentialAddress[EDIT_RECEIVER_SUBURB_NAME],
          city: residentialAddress[EDIT_RECEIVER_STATE_NAME],
          postcode: residentialAddress[EDIT_RECEIVER_POSTAL_NAME],
          country: personalDetails[EDIT_RECEIVER_COUNTRY_NAME],
          fullAddress:
            residentialAddress[EDIT_RECEIVER_STREET_NAME] +
            ', ' +
            residentialAddress[EDIT_RECEIVER_SUBURB_NAME].name +
            ', ' +
            residentialAddress[EDIT_RECEIVER_STATE_NAME].name +
            ', ' +
            personalDetails[EDIT_RECEIVER_COUNTRY_NAME],
          manual: false,
        },
      };

      try {
        const { data } = await api.createReceiver(token, receiverPayload);

        if (data) {
          await fetchReceiverInformation(token, data?.id);
          await handleOnClickDeleteBankPopup();
          resetCurrentBusinessReceiver();
          setCurrentStep(currentStep + 1);
        }

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      } catch (error) {
        checkMaintenance(error);
        console.error(error?.message);
        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    }
  };

  const renderDesc = () => {
    // if (!isShowDesc) return null;

    if (isReceiverOfIndividualType)
      return <Desc>{t('add_receiver_account_details_2')}</Desc>;

    if (isReceiverOfBusinessType)
      return <Desc>{t('add_receiver_account_details_3')}</Desc>;

    return null;
  };

  const renderFormInputBankEwalletRadio = () => {
    if (isReceiverOfIndividualType)
      return (
        <FormInputBankEwalletRadio
          deliveryMethods={deliveryMethods}
          isBanksChecked={isBanksChecked}
          setBanksChecked={setBanksChecked}
          banksLength={information?.banks?.length}
          ewalletsLength={information?.ewallets?.length}
        />
      );

    return null;
  };

  const renderActionsButton = () => {
    if (isReceiverOfIndividualType)
      return (
        <Fade in appear>
          <NextWrap>
            <ButtonCommon
              value={t('button_back')}
              onClick={handleBackStep}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
            />
            <ButtonCommon
              value={t('button_save')}
              color="var(--c-primary)"
              background="var(--bg-primary)"
              isFill={true}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              onClick={handleSave}
              isUseKeyDown
            />
          </NextWrap>
        </Fade>
      );

    if (isReceiverOfBusinessType)
      return (
        <Fade in appear>
          <NextWrap>
            <ButtonCommon
              value={t('button_back')}
              onClick={handleBackStep}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
            />
            <ButtonCommon
              value={t('button_done')}
              color="var(--c-primary)"
              background="var(--bg-primary)"
              isFill={true}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              onClick={handleSave}
              isDisabled={false}
              isUseKeyDown
            />
          </NextWrap>
        </Fade>
      );

    return null;
  };

  const getCurrentReceiver = () => {
    if (isReceiverOfIndividualType)
      return {
        firstName: personalDetails[EDIT_RECEIVER_FISTNAME_NAME],
        lastName: personalDetails[EDIT_RECEIVER_LASTNAME_NAME],
        gender: personalDetails[EDIT_RECEIVER_GENDER_NAME],
      };

    if (isReceiverOfBusinessType)
      return {
        firstName: personalDetails[EDIT_RECEIVER_FISTNAME_NAME],
        lastName: '',
        gender: personalDetails[EDIT_RECEIVER_GENDER_NAME],
      };

    return {};
  };

  return (
    <>
      {isOpenFormAddNewBank && (
        <FormAddNewBankAccount
          isOpen={isOpenFormAddNewBank}
          countryConfig={countryConfig}
          currentReceiver={getCurrentReceiver()}
          information={information}
          setInformation={setInformation}
          bankInformation={bankInformation}
          setBankInformation={setBankInformation}
          bankValidation={bankValidation}
          setBankValidation={setBankValidation}
          onClose={handleOnToggleFormAddNewBank}
        />
      )}

      {isOpenFormAddNewEwallet && (
        <FormAddNewEwalletAccount
          isOpen={isOpenFormAddNewEwallet}
          countryConfig={countryConfig}
          information={information}
          setInformation={setInformation}
          ewalletInformation={ewalletInformation}
          setEwalletInformation={setEwalletInformation}
          ewalletValidation={ewalletValidation}
          setEwalletValidation={setEwalletValidation}
          onClose={handleOnToggleFormAddNewEwallet}
        />
      )}

      <BodySectionHeader
        titleBodySection={(isMobile || isTablet) && t('add_receiver_title')}
        titleSection={t('label_account_details')}
        onToggleCancelPopup={handleOnToggleCancelPopup}
        isDisabled={false}
      />
      <AccountDetailsStyled>
        {!deliveryMethods?.length ? (
          <Desc style={{ color: 'red' }}>
            {stringUtils.replaceKeyword(t('add_receiver_account_details_1'), [
              {
                key: 'countryName',
                value: personalDetails[EDIT_RECEIVER_COUNTRY_NAME]?.name,
              },
            ])}
          </Desc>
        ) : (
          <>
            {renderDesc()}
            {renderFormInputBankEwalletRadio()}
            {renderAccountTypeReference[isBanksChecked]}
          </>
        )}
        {renderActionsButton()}
      </AccountDetailsStyled>
    </>
  );
};

const AccountDetailsStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 140px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 437px;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

const Desc = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  margin: 0;
  margin-bottom: 24px;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77px;
  width: 100%;
  margin-inline: -16px;
  padding-inline: 16px;
  background: var(--ds-c-white);
  z-index: 1;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #eef2f5;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    width: 437px;
    height: 44px;
    border-top: unset;
  }
`;

export default AccountDetails;
