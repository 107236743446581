import SendIcon from 'assets/icons/send-icon.svg';
import ExchangeRateSectionMobile from 'components/Dashboard/components/ExchangeRateSection/mobile/ver2';
import ExchangeRateSection from 'components/Dashboard/components/ExchangeRateSection/ver2';
import Transactions from 'components/Transactions';
import { fetchTransactionsFiltered } from 'components/Transactions/func';
import TransactionsMobile from 'components/Transactions/mobile';
import ButtonCommon from 'components/common/ButtonCommon';
import CollapsibleSideMenu from 'components/common/CollapsibleSideMenu';
import MobileTabMenu from 'components/common/MobileTabMenu';
import NavbarFooter from 'components/common/NavbarFooter/ver2';
import { fetchCountries } from 'components/common/func';
import {
  DESKTOP_MIN_WIDTH,
  SCREEN_ID_REMITTANCE_HOME,
  TRANSACTION_ADD_PREFIX,
  TRANSACTION_TABLE_SORT_ORDER_INITIAL,
} from 'constants';
import { datePicker } from 'helpers';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useEvent from 'hooks/useEvent';
import useExchangeRates from 'hooks/useExchangeRates';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
// import { arrayUtils } from 'utils';

const SCREEN_ID = SCREEN_ID_REMITTANCE_HOME;

const DashboardPage = () => {
  const { t, locate } = useLang();
  const { state } = useStore();
  const { exchangeRates } = state || {};
  const { token } = useAuth();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDetectDevice();
  const { remittanceRatesData } = useExchangeRates();
  const { isMenuExpand } = state; // For Collapsible Menu

  const [transactions, setTransactions] = useState(null);
  const [countries, setCountries] = useState(null);
  const [sortOrder, setSortOrder] = useState(
    TRANSACTION_TABLE_SORT_ORDER_INITIAL
  );

  // eslint-disable-next-line no-extra-boolean-cast
  const isExchangeRatesEmpty = !Boolean(exchangeRates);

  useEffect(() => {
    fetchTransactionsFiltered({ token, setTransactions });
    fetchCountries((data) => {
      setCountries(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent]);

  if (isMobile) {
    return (
      <DashboardStyled>
        <MobileTabMenu />
        <Title>{t('dashboard_favourite_receiving_countries')}</Title>
        <Wrap>{datePicker.getFullTimeStringTranslate(locate)}</Wrap>
        <ExchangeRateSectionMobile
          remittanceRatesData={remittanceRatesData}
          countries={countries}
        />
        <Title>{t('dashboard_tab_recent_transaction')}</Title>
        <TransactionsMobile
          transactions={transactions}
          setTransactions={setTransactions}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
        <NavbarFooter />
      </DashboardStyled>
    );
  }
  if (isTablet) {
    return (
      <DashboardStyled>
        <MobileTabMenu />
        <Title>{t('dashboard_favourite_receiving_countries')}</Title>
        <Wrap>{datePicker.getFullTimeStringTranslate(locate)}</Wrap>
        <ExchangeRateSection
          remittanceRatesData={remittanceRatesData}
          countries={countries}
        />
        <Title>{t('dashboard_tab_recent_transaction')}</Title>
        <TransactionsMobile
          transactions={transactions}
          setTransactions={setTransactions}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
        <NavbarFooter />
      </DashboardStyled>
    );
  }
  return (
    <>
      <LayoutContainer>
        <CollapsibleSideMenu />

        <OuterContainerStyled isMenuExpand={isMenuExpand}>
          <DashboardStyled>
            {!isExchangeRatesEmpty && (
              <>
                <Title>{t('dashboard_favourite_receiving_countries')}</Title>
                <Wrap>
                  {datePicker.getFullTimeStringTranslate(locate)}
                  <ButtonCommon
                    value={t('button_send_money')}
                    color="var(--c-primary)"
                    background="var(--bg-primary)"
                    isFill={true}
                    styles={{
                      paddingInline: 0,
                    }}
                    buttonIconStyles={{
                      float: 'right',
                    }}
                    iconSrc={SendIcon}
                    onClick={() => {
                      Cookies.remove(MAKE_PAYMENT_KEY);
                      navigate(`${TRANSACTION_ADD_PREFIX}`);
                    }}
                  />
                </Wrap>
                <ExchangeRateSection
                  remittanceRatesData={remittanceRatesData}
                  countries={countries}
                />
              </>
            )}
            <Title>{t('dashboard_tab_recent_transaction')}</Title>
            <Transactions
              transactions={transactions}
              setTransactions={setTransactions}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
            />
          </DashboardStyled>
        </OuterContainerStyled>
      </LayoutContainer>
    </>
  );
};

const LayoutContainer = styled.div`
  display: flex;
  height: 100dvh;
`;

const OuterContainerStyled = styled.div`
  flex-grow: 1;
  margin-left: ${(props) => (props.isMenuExpand ? '300px' : '98px')};
  transition: margin-left 0.3s;
  height: fit-content;
`;

const DashboardStyled = styled.div`
  margin: 70px 0px 0px 0px;
  box-shadow: none;
  height: calc(100dvh - 70px);
  padding: 16px 24px;
  position: relative;
  background-color: var(--ds-c-white);
  border-radius: 12px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: fit-content;
    margin: 96px 50px 47px 0;
    padding: 24px 40px;
  }
`;

const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;

  color: var(--ds-c-blue-dark);
  margin-bottom: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: var(--ds-c-black);
    margin-bottom: 10px;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 24px;
  margin-bottom: 8px;

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-black);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    height: 44px;
    margin-bottom: 24px;
  }
`;

export default DashboardPage;
