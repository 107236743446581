import WarnningIcon from 'assets/icons/alert-animation.gif';
import BusinessIcon from 'assets/icons/business-icon.svg';
import CountryHHMTIcon from 'assets/icons/country-hhmt-icon.svg';
import CountryRemoxIcon from 'assets/icons/country-remox-icon.svg';
import IndividualIcon from 'assets/icons/individual-icon.svg';
import LocationHHMTIcon from 'assets/icons/location-hhmt-icon.svg';
import LocationRemoxIcon from 'assets/icons/location-remox-icon.svg';
import MobileHHMTIcon from 'assets/icons/mobile-hhmt-icon.svg';
import MobileRemoxIcon from 'assets/icons/mobile-remox-icon.svg';
import PhoneHHMTIcon from 'assets/icons/phone-hhmt-icon.svg';
import PhoneRemoxIcon from 'assets/icons/phone-remox-icon.svg';
import CustomBanksAccordionMobile from 'components/FormEditReceiver/components/CustomBanksAccordion/mobile';
import CustomEwalletsAccordionMobile from 'components/FormEditReceiver/components/CustomEwalletsAccordion/mobile';
import {
  getFullNameReceiver,
  getNumberUnmaskStr,
} from 'components/FormEditReceiver/func';
import CustomActionDropdown from 'components/Receivers/components/CustomActionDropdown';
import {
  GENDER_ICON_REFERENCE,
  GENDER_TRANSLATE_REFERENCE,
  METHOD_BANK,
  METHOD_EWALLET,
} from 'constants';
import { formatter } from 'helpers';
import useReceiver from 'hooks/receiver/useReceiver';
import useCountryConfig from 'hooks/useCountryConfig';
import useDeliveryMethods from 'hooks/useDeliveryMethods';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import Modal from 'react-bootstrap/Modal';
import { SET_CURRENT_RECEIVER } from 'store/action';
import styled from 'styled-components';
import ButtonCommon from '../../../../common/ButtonCommon';
import FormInputFavoriteCheckbox from '../../FormInputFavoriteCheckbox';

const FormReceiverDetailsMobile = ({ isOpen, onClose, onClick, onDelete }) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { currentReceiver } = state;
  const {
    receiverId,
    firstName,
    lastName,
    country,
    countryName,
    gender,
    fullAddress,
    mobileNumber,
    phoneNumber,
    banks,
    ewallets,
    favorited,
  } = currentReceiver;
  const { isHHMTTheme } = useTheme();

  const { isReceiverOfBusinessType, isReceiverOfIndividualType } =
    useReceiver();

  const countryConfig = useCountryConfig(country?.code);
  const fullName = getFullNameReceiver({
    isReceiverOfIndividualType,
    isReceiverOfBusinessType,
    lastName,
    firstName,
    countryCode: currentReceiver?.country?.code,
  });

  const deliveryMethods = useDeliveryMethods(country?.code);

  const config = useGetConfig({ countryConfig });
  const { mobile, phone, addressPostcode } = config;

  const mobileFormat = formatter.formatParse(mobile?.format);
  const mobileNumberMask = formatter.mask(
    getNumberUnmaskStr(mobileNumber, mobileFormat),
    mobileFormat
  );

  const phoneFormat = formatter.formatParse(phone?.format);
  const phoneNumberMask = formatter.mask(
    getNumberUnmaskStr(phoneNumber, phoneFormat),
    phoneFormat
  );

  const checkReceiverSelectedMissingInformation = (receiver) => {
    if (
      !receiver.gender ||
      !receiver.fullAddress ||
      (addressPostcode?.visible && !currentReceiver.address?.postcode)
    ) {
      return true;
    }

    return false;
  };

  const renderActionsButton = () => {
    return (
      <>
        <ButtonCommon
          value={t('button_close')}
          onClick={() => {
            onClose();
            dispatch({ type: SET_CURRENT_RECEIVER, payload: null });
          }}
          styles={{
            margin: '0px',
            marginRight: '8px',
            width: 'calc(50% - 8px)',
          }}
          color="var(--c-primary)"
          background="var(--ds-c-white)"
        />
        <ButtonCommon
          value={t('button_send_now')}
          onClick={() => onClick(currentReceiver)}
          styles={{
            margin: '0px',
            marginLeft: '8px',
            width: 'calc(50% - 8px)',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
        />
      </>
    );
  };

  const renderReceiverNameForm = () => {
    if (isReceiverOfBusinessType)
      return (
        <FavoriteNameSection>
          <FormInputFavoriteCheckbox id={receiverId} isChecked={favorited} />
          <NameText>
            {isReceiverOfBusinessType && (
              <img
                src={BusinessIcon}
                width={24}
                height={24}
                style={{ marginRight: '8px' }}
              />
            )}
            {firstName}
          </NameText>
        </FavoriteNameSection>
      );

    return (
      <FavoriteNameSection>
        <FormInputFavoriteCheckbox id={receiverId} isChecked={favorited} />
        <NameText>
          {isReceiverOfIndividualType && (
            <img
              src={IndividualIcon}
              width={24}
              height={24}
              style={{ marginRight: '8px' }}
            />
          )}
          {fullName}
        </NameText>
      </FavoriteNameSection>
    );
  };

  const renderGenderForm = () => {
    if (isReceiverOfBusinessType) return null;

    return (
      <FieldWrap isInline>
        <FieldIcon>
          <img
            src={GENDER_ICON_REFERENCE[gender?.toUpperCase()]}
            width={24}
            height={24}
            alt=""
          />
        </FieldIcon>
        <FieldValue>
          {gender ? t(GENDER_TRANSLATE_REFERENCE[gender?.toUpperCase()]) : '-'}
        </FieldValue>
      </FieldWrap>
    );
  };

  const renderMobileForm = () => {
    if (countryConfig && mobile?.visible)
      return (
        <FieldWrap isInline isFloatLeft>
          <FieldIcon>
            <img
              src={isHHMTTheme ? MobileHHMTIcon : MobileRemoxIcon}
              width={24}
              height={24}
              alt=""
            />
          </FieldIcon>
          <FieldValue>{mobileNumberMask || '-'}</FieldValue>
        </FieldWrap>
      );

    return null;
  };

  const renderBanksSection = () => {
    if (deliveryMethods?.includes(METHOD_BANK))
      return (
        <CustomBanksAccordionMobile
          banks={banks}
          countryConfig={countryConfig}
        />
      );
  };

  const renderEwalletsSection = () => {
    if (isReceiverOfBusinessType) return null;

    if (deliveryMethods?.includes(METHOD_EWALLET))
      return (
        <CustomEwalletsAccordionMobile
          ewallets={ewallets}
          countryConfig={countryConfig}
        />
      );

    return null;
  };

  return (
    <>
      <style>
        {`
          .modal-backdrop {
            display: none;
          }
        `}
      </style>

      <ModalStyled show={isOpen} onHide={() => {}} animation={false}>
        <ModalHeader>
          <SectionTitle>{t('label_receiver_details')}</SectionTitle>
          <FavNameActionWrap>
            {renderReceiverNameForm()}
            <ActionsWrap>
              <CustomActionDropdown
                styles={{
                  height: '24px',
                  width: '24px',
                }}
                onDelete={onDelete}
              />
            </ActionsWrap>
          </FavNameActionWrap>
        </ModalHeader>
        <ModalBody>
          <FieldsWrap>
            {checkReceiverSelectedMissingInformation(currentReceiver) && (
              <FieldWrap>
                <FieldIcon>
                  <img src={WarnningIcon} width={24} height={24} alt="" />
                </FieldIcon>
                <FieldValue
                  style={{
                    textTransform: 'initial',
                    color: 'red',
                  }}
                >
                  {t('receivers_summary_missing_information')}
                </FieldValue>
              </FieldWrap>
            )}
            <FieldWrap isInline isFloatLeft>
              <FieldIcon>
                <img
                  src={isHHMTTheme ? CountryHHMTIcon : CountryRemoxIcon}
                  width={24}
                  height={24}
                  alt=""
                />
              </FieldIcon>
              <FieldValue>{countryName || '-'}</FieldValue>
            </FieldWrap>
            {renderGenderForm()}
            {renderMobileForm()}
            {countryConfig && phone?.visible && (
              <FieldWrap isInline>
                <FieldIcon>
                  <img
                    src={isHHMTTheme ? PhoneHHMTIcon : PhoneRemoxIcon}
                    width={24}
                    height={24}
                    alt=""
                  />
                </FieldIcon>
                <FieldValue>{phoneNumberMask || '-'}</FieldValue>
              </FieldWrap>
            )}
          </FieldsWrap>
          <FieldWrap
            styles={{
              alignItems: 'start',
              padding: '10px 0',
            }}
          >
            <FieldIcon>
              <img
                src={isHHMTTheme ? LocationHHMTIcon : LocationRemoxIcon}
                width={24}
                height={24}
                alt=""
              />
            </FieldIcon>
            <FieldValue>{fullAddress || '-'}</FieldValue>
          </FieldWrap>
          <Divider />
          {renderBanksSection()}
          {renderEwalletsSection()}
        </ModalBody>
        <ModalFooter>{renderActionsButton()}</ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  z-index: 0;
  background-color: #ffffff;

  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  & .modal-dialog {
    position: absolute;
    top: 70px;
    margin: 0;
    height: 100dvh;
    width: 100%;
    max-width: 100%;
    transform: none;
  }

  & .modal-content {
    padding: 24px 24px 0 24px;
    border-radius: 0px;
    box-shadow: none;
    background: var(--ds-c-white);
    width: 100%;
    height: calc(100dvh - 70px);
    margin-inline: auto;
    border: none;
  }
`;
const ModalHeader = styled(Modal.Header)`
  padding: 0;
  margin-bottom: 0;
  display: block;
  padding-bottom: 0px;
  border-bottom: none;
`;
const SectionTitle = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;

  margin: 0;
  margin-bottom: 16px;
  padding: 0;
`;
const FavNameActionWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;
`;
const FavoriteNameSection = styled.div`
  display: flex;
  align-items: flex-start;
`;
const NameText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: var(--ds-c-grey-dark);
  text-align: left;

  margin: 0;
  padding: 0;
`;
const ActionsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ModalBody = styled(Modal.Body)`
  padding: 0;
  padding-bottom: 91px;
`;
const FieldsWrap = styled.div`
  display: table;
  width: 100%;
`;
const FieldWrap = styled.div`
  display: flex;
  align-items: ${(props) => props?.styles?.alignItems || 'center'};
  padding: ${(props) => props?.styles?.padding};
  justify-content: start;
  width: ${(props) => (props.$isInline ? '50%' : '100%')};
  height: fit-content;
  min-height: 40px;
  float: ${(props) => props.$isFloatLeft && 'left'};
  margin-top: 8px;
  margin-right: ${(props) => props.$isFloatLeft && '0px'};
`;
const FieldIcon = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: fit-content;
`;
const FieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-grey-dark);

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 8px;

  & span {
    color: #7a8189;
  }

  & span.payment-credit-card {
    text-transform: initial;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  padding: 16px 0 16px 0;
  border-top: 1px solid #eef2f5;
  margin-inline: -24px;
  padding-inline: 24px;
  background: var(--ds-c-white);
  border-radius: 0px;
  z-index: 100;
}
`;

const Divider = styled.div`
  border-bottom: 1px solid #eef2f5;
  margin: 24px 0;
`;

export default FormReceiverDetailsMobile;
