import { api } from 'api';
import FormInputMobileNumberValidation from 'components/FormRegister/LogInDetails/components/FormInputMobileNumberValidation';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  BODY_PADDING_BOTTOM_MOBILE,
  DESKTOP_MIN_WIDTH,
  FX_LOGIN_PREFIX,
  MOBILE_MIN_WIDTH,
  TABLET_MAX_WIDTH,
} from 'constants';
import {
  FX_CONTACT_DETAIL_EMAIL_FIELD,
  FX_CONTACT_DETAIL_EMAIL_NAME,
  FX_CONTACT_DETAIL_MOBILE_FIELD,
  FX_CONTACT_DETAIL_MOBILE_NAME,
  RESET_FORM_FX_CONTACT_DETAILS,
} from 'constants/fx';
import { checkMaintenance } from 'helpers/maintenance';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useCommonStore from 'hooks/useCommonStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import { useState } from 'react';
import { Fade } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import FormEmailInputValidation from './components/FormEmailInputValidation';
import { checkFormInputValidation } from './func';

const initialFormField = RESET_FORM_FX_CONTACT_DETAILS;

const FormFxContactInformationModal = ({ isOpen, onClose }) => {
  const { t } = useLang();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();
  const navigate = useNavigate();

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [isEmailExisted, setEmailExisted] = useState(false);
  const [numberMaskStr, setNumberMaskStr] = useState(null);

  const { isLoading, setLoading } = useCommonStore();
  const { setAddTransactionCurrentStep, setConfirmInformationEmailMobile } =
    useFxFormAddTransactionStore();

  // eslint-disable-next-line no-useless-escape
  const numberFormat = `[\"#### ### ###\"]`;
  // eslint-disable-next-line no-useless-escape
  const prefixFormat = `[\"04\"]`;

  const isAllowNextStep = Object.values(validation).every((value) => !value);

  const renderBodyContent = () => {
    return (
      <>
        <Desc>{t('form_fx_contact_details')}</Desc>
        <FormEmailInputValidation
          field={FX_CONTACT_DETAIL_EMAIL_FIELD}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          isEmailExisted={isEmailExisted}
          setEmailExisted={setEmailExisted}
        />
        <FormInputMobileNumberValidation
          field={FX_CONTACT_DETAIL_MOBILE_FIELD}
          config={{
            visible: true,
            required: true,
            prefix: prefixFormat,
            format: numberFormat,
            example: '',
            max: 10,
            min: 10,
          }}
          numberMaskStr={numberMaskStr}
          setNumberMaskStr={setNumberMaskStr}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
        />
      </>
    );
  };

  const handleNextStep = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    const isAllowSubmit =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      setLoading(true);
      try {
        const { data } = await api.isUsernameValid(
          information[FX_CONTACT_DETAIL_EMAIL_NAME]
        );

        if (data?.valid) {
          setEmailExisted(false);
          setLoading(false);
          setAddTransactionCurrentStep(1);
          setConfirmInformationEmailMobile(
            information[FX_CONTACT_DETAIL_EMAIL_NAME],
            information[FX_CONTACT_DETAIL_MOBILE_NAME]
          );
        } else {
          setEmailExisted(true);
          setLoading(false);
          setInformation(initialFormField);

          const urlRedirect = `${FX_LOGIN_PREFIX}?email=${information[FX_CONTACT_DETAIL_EMAIL_NAME]}&&mobile=${information[FX_CONTACT_DETAIL_MOBILE_NAME]}`;
          navigate(urlRedirect);
        }
      } catch (error) {
        checkMaintenance(error);
        console.error(error?.message);
        setLoading(false);
      }
      return;
    }

    if (!isAllowSubmit) {
      if (!newValidation[FX_CONTACT_DETAIL_EMAIL_NAME]) {
        newValidation = checkFormInputValidation(
          information[FX_CONTACT_DETAIL_EMAIL_NAME],
          FX_CONTACT_DETAIL_EMAIL_FIELD,
          information,
          newValidation
        );
      }

      if (!newValidation[FX_CONTACT_DETAIL_MOBILE_NAME]) {
        newValidation = checkFormInputValidation(
          information[FX_CONTACT_DETAIL_MOBILE_NAME],
          FX_CONTACT_DETAIL_MOBILE_FIELD,
          information,
          newValidation
        );
      }

      setValidation(newValidation);
    }
  };

  return (
    <>
      <style>
        {`
          @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${
          TABLET_MAX_WIDTH - 1
        }px) {
            .fx-contact-detail-modal-backdrop {
              top: 70px;
            }
          }

          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal-backdrop {
              opacity: 0!important;
              z-index: 1045!important;
            }

            .modal {
              top: 70px;
              padding-left: 0px;
              background: #fff;
            }
          }

          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
            .fx-contact-detail-modal-backdrop {
              background: #212121;
              opacity: 0.7!important;
              display: initial!important;
            }
          }
        `}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby={isDesktop && 'contained-modal-title-vcenter'}
        backdropClassName="fx-contact-detail-modal-backdrop"
        centered={isDesktop}
        animation={isDesktop}
      >
        <ModalHeader>{t('label_contact_details')}</ModalHeader>
        <ModalBody>{renderBodyContent()}</ModalBody>
        <ModalFooter>
          <Fade in appear>
            <NextWrap>
              <ButtonCommon
                value={t('button_back')}
                onClick={onClose}
                styles={{
                  margin: '0px',
                  marginRight: isMobile || isTablet ? 0 : '16px',
                  width: isMobile || isTablet ? '50%' : 'fit-content',
                }}
                color="var(--ds-c-blue)"
                background="var(--ds-c-white)"
              />
              <ButtonCommon
                value={t('button_next')}
                color="var(--c-primary)"
                background="var(--bg-primary)"
                isFill={true}
                styles={{
                  margin: '0px',
                  width: isMobile || isTablet ? '50%' : '70px',
                }}
                onClick={handleNextStep}
                isDisabled={isLoading || !isAllowNextStep}
                isUseKeyDown
              />
            </NextWrap>
          </Fade>
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
  }

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    & .modal-dialog {
      position: absolute;
      top: 0px;
      margin: 0;
      height: 100dvh;
      width: 100%;
      max-width: 100%;

      & .modal-content {
        padding: 24px 24px 0 24px;
        border-radius: 0px;
        box-shadow: none;
        background: var(--ds-c-white);
        width: 100%;
        height: calc(100dvh - 70px);
        margin-inline: auto;
        border: none;
      }

      & .modal {
        top: 70px;
        padding-left: 0px;
        background-color: #fff;
      }
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-content {
      padding: 24px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 470px;
      height: fit-content;
      margin-inline: auto;
    }
  }
`;
const ModalHeader = styled(Modal.Header)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-blue-disabled);

  padding: 0;
  margin-bottom: 0;
  padding-bottom: 16px;
  border: none !important;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--ds-c-green-default);
  }
`;

const ModalBody = styled(Modal.Body)`
  padding: 0;

  @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
    padding-bottom: ${BODY_PADDING_BOTTOM_MOBILE}px;
  }
`;
const Desc = styled.p`
  ${paragraphMixin};
  font-weight: 500;
  margin-bottom: 16px;
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  background: var(--ds-c-white);
  margin-block: 0px;
  position: fixed;
  left: 24px;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-inline: unset;
    padding-inline: unset;
    height: 40px;
    left: unset;
    border-top: none;
  }
`;

export default FormFxContactInformationModal;
