/* eslint-disable no-unused-vars */
import { api } from 'api';
import { getNumberUnmaskStr } from 'components/FormEditReceiver/func';
import { fetchReceiversCountries } from 'components/Receivers/func';
import ButtonCommon from 'components/common/ButtonCommon';
import CustomSearchDropdown from 'components/common/CustomSearchDropdown';
import { checkFormInputValidation } from 'components/common/func';
import {
  DESKTOP_MIN_WIDTH,
  EDIT_RECEIVER_BUSINESS_NAME_FIELD,
  EDIT_RECEIVER_BUSINESS_PHONE_FIELD,
  EDIT_RECEIVER_COUNTRY_FIELD,
  EDIT_RECEIVER_COUNTRY_NAME,
  EDIT_RECEIVER_FISTNAME_NAME,
  EDIT_RECEIVER_FULLNAME_NAME,
  EDIT_RECEIVER_GENDER_NAME,
  EDIT_RECEIVER_GENERAL_DETAILS_NAME_FIELDS,
  EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS,
  EDIT_RECEIVER_LASTNAME_NAME,
  EDIT_RECEIVER_MOBILE_NAME,
  EDIT_RECEIVER_PERSONAL_DETAILS_GENDER_FIELD,
  EDIT_RECEIVER_PHONE_NAME,
  EDIT_RECEIVER_RELATIONSHIP_FIELD,
  EDIT_RECEIVER_RELATIONSHIP_NAME,
  EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME,
  PROPERTIES_NOT_IN_BUSINESS_RECEIVER_TYPE,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
} from 'constants';
import { formatter } from 'helpers';
import { checkMaintenance } from 'helpers/maintenance';
import useReceiver from 'hooks/receiver/useReceiver';
import useAuth from 'hooks/useAuth';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Fade } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { SET_EDIT_RECEIVER_INFORMATION, SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import FormInputGenderRadio from '../FormInputGenderRadio';
import FormInputMobileOrPhoneNumberValidation from '../FormInputMobileOrPhoneNumberValidation';
import FormInputSelectTypeOfReceiverRadio from '../FormInputSelectTypeOfReceiverRadio';
import FormInputValidation from '../FormInputValidation';

const initialFormField = RESET_EDIT_RECEIVER_INFORMATION_STORE.personalDetails;

const PersonalDetails = ({
  countryConfig,
  handleOnToggleCancelPopup,
  handleOnToggleUpdatePopup,
  handleOnToggleCancelCurrentTabPopup,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { currentReceiver, configRelationship, configRelationshipOfBusiness } =
    state;
  const { token } = useAuth();
  const { receiverId } = useParams();
  const query = useQuery();
  const dmCode = query.get('dmCode');
  const config = useGetConfig({ countryConfig, dmCode: dmCode || null });
  const { mobile, phone, relationship } = config;

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [countries, setCountries] = useState(null);
  const [eitherRequiredMsg, setEitherRequiredMsg] = useState('');
  const [mobileMaskStr, setMobileMaskStr] = useState('');
  const [phoneMaskStr, setPhoneMaskStr] = useState('');

  const { isReceiverOfBusinessType, isReceiverOfIndividualType } =
    useReceiver();

  const isShowEitherRequiredMsg =
    eitherRequiredMsg &&
    !validation[EDIT_RECEIVER_MOBILE_NAME] &&
    !validation[EDIT_RECEIVER_PHONE_NAME];

  const isVietNamCountry =
    information[EDIT_RECEIVER_COUNTRY_NAME]?.name === 'VIETNAM';

  useEffect(() => {
    fetchReceiversCountries(token, setCountries, isReceiverOfBusinessType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isReceiverOfBusinessType]);

  useEffect(() => {
    if (currentReceiver && countries) {
      const currentCountry = countries?.find(
        (country) => country.name === currentReceiver?.countryName
      );

      const newInformation = {
        [EDIT_RECEIVER_COUNTRY_NAME]: currentCountry,
        [EDIT_RECEIVER_FISTNAME_NAME]: currentReceiver?.firstName,
        [EDIT_RECEIVER_LASTNAME_NAME]: currentReceiver?.lastName,
        [EDIT_RECEIVER_FULLNAME_NAME]:
          currentReceiver?.lastName + ' ' + currentReceiver?.firstName,
        [EDIT_RECEIVER_GENDER_NAME]: currentReceiver?.gender,
        [EDIT_RECEIVER_MOBILE_NAME]: currentReceiver?.mobileNumber,
        [EDIT_RECEIVER_PHONE_NAME]: currentReceiver?.phoneNumber,
        [EDIT_RECEIVER_RELATIONSHIP_NAME]: {
          key: currentReceiver?.relationship,
          text: currentReceiver?.relationship,
        },
        [EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME]: isReceiverOfIndividualType
          ? 1
          : 2,
      };
      setInformation(newInformation);

      if (isReceiverOfIndividualType) {
        const mobileFormat = formatter.formatParse(mobile?.format);
        const mobileNumberMask = formatter.mask(
          getNumberUnmaskStr(currentReceiver?.mobileNumber, mobileFormat),
          mobileFormat
        );
        setMobileMaskStr(mobileNumberMask);
      }

      const phoneFormat = formatter.formatParse(phone?.format);
      const phoneNumberMask = formatter.mask(
        getNumberUnmaskStr(currentReceiver?.phoneNumber, phoneFormat),
        phoneFormat
      );
      setPhoneMaskStr(phoneNumberMask);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentReceiver,
    countries,
    isReceiverOfIndividualType,
    isReceiverOfBusinessType,
  ]);

  useEffect(() => {
    if (currentReceiver && dmCode) {
      let newValidation = { ...validation };

      if (!currentReceiver?.gender) {
        newValidation = {
          ...newValidation,
          [EDIT_RECEIVER_GENDER_NAME]:
            EDIT_RECEIVER_PERSONAL_DETAILS_GENDER_FIELD.msgRequired,
        };
      }

      if (mobile?.eitherRequired) {
        if (
          !information[EDIT_RECEIVER_MOBILE_NAME] &&
          !information[EDIT_RECEIVER_PHONE_NAME]
        ) {
          setEitherRequiredMsg('form_mobile_phone_invalid_1');
        } else {
          setEitherRequiredMsg('');
        }
      } else {
        if (mobile?.visible && !currentReceiver?.mobileNumber) {
          newValidation = {
            ...newValidation,
            [EDIT_RECEIVER_MOBILE_NAME]:
              EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS[0].msgRequired,
          };
        }

        if (phone?.visible && !currentReceiver?.phoneNumber) {
          newValidation = {
            ...newValidation,
            [EDIT_RECEIVER_PHONE_NAME]:
              EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS[1].msgRequired,
          };
        }
      }

      setValidation(newValidation);
    }
  }, [currentReceiver, mobile, phone, dmCode]);

  useEffect(() => {
    if (
      !isEmpty(currentReceiver) &&
      relationship?.required &&
      isReceiverOfBusinessType
    ) {
      const newValidation = checkFormInputValidation(
        information[EDIT_RECEIVER_RELATIONSHIP_NAME]?.key,
        EDIT_RECEIVER_RELATIONSHIP_FIELD,
        validation
      );
      setValidation(newValidation);
    }
  }, [currentReceiver, relationship, information, isReceiverOfBusinessType]);

  const isNameChanged = (information) => {
    if (
      information[EDIT_RECEIVER_FISTNAME_NAME]?.toUpperCase() !==
      currentReceiver?.firstName?.toUpperCase()
    )
      return true;

    if (
      information[EDIT_RECEIVER_LASTNAME_NAME]?.toUpperCase() !==
      currentReceiver?.lastName?.toUpperCase()
    )
      return true;

    return false;
  };

  const isGenderChanged = (information) => {
    if (
      information[EDIT_RECEIVER_GENDER_NAME]?.toUpperCase() !==
      currentReceiver?.gender?.toUpperCase()
    )
      return true;

    return false;
  };

  const isMobileChanged = (information) => {
    if (
      isReceiverOfIndividualType &&
      ((information[EDIT_RECEIVER_MOBILE_NAME] &&
        information[EDIT_RECEIVER_MOBILE_NAME] !==
          currentReceiver?.mobileNumber) ||
        (!information[EDIT_RECEIVER_MOBILE_NAME] &&
          currentReceiver?.mobileNumber))
    )
      return true;

    return false;
  };

  const isPhoneChanged = (information) => {
    if (
      (information[EDIT_RECEIVER_PHONE_NAME] &&
        information[EDIT_RECEIVER_PHONE_NAME] !==
          currentReceiver?.phoneNumber) ||
      (!information[EDIT_RECEIVER_PHONE_NAME] && currentReceiver?.phoneNumber)
    )
      return true;

    return false;
  };

  const isRelationshipChanged = (information) => {
    if (
      information[EDIT_RECEIVER_RELATIONSHIP_NAME]?.key !==
      currentReceiver?.relationship
    )
      return true;

    return false;
  };

  const checkInformationChanged = (information) => {
    return (
      isNameChanged(information) ||
      isGenderChanged(information) ||
      isMobileChanged(information) ||
      isPhoneChanged(information) ||
      isRelationshipChanged(information)
    );
  };

  const updateReceiverName = () => {
    let payload = {
      firstname: information[EDIT_RECEIVER_FISTNAME_NAME]?.toUpperCase(),
      lastname: isReceiverOfBusinessType
        ? ''
        : information[EDIT_RECEIVER_LASTNAME_NAME]?.toUpperCase(),
      gender: information[EDIT_RECEIVER_GENDER_NAME]?.toUpperCase(),
    };

    return api.updateDetails(token, receiverId, payload);
  };

  const updateReceiverMobilePhone = (type, number) => {
    if (!currentReceiver[type]) {
      const payload = {
        id: 0,
        isValidated: true,
        number,
      };

      return api.addContact(token, receiverId, type, payload);
    }

    const payload = {
      id: currentReceiver[type]?.id,
      isValidated: true,
      number,
    };

    return api.updateContact(
      token,
      receiverId,
      currentReceiver[type]?.id,
      payload
    );
  };

  const deleteReceiverMobilePhone = (contactId) => {
    return api.deleteContact(token, receiverId, contactId);
  };

  const updateReceiverRelationship = () => {
    return api.updateRelationship(
      token,
      receiverId,
      information[EDIT_RECEIVER_RELATIONSHIP_NAME]?.key
    );
  };

  const handleSave = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    if (isReceiverOfBusinessType) {
      PROPERTIES_NOT_IN_BUSINESS_RECEIVER_TYPE.forEach((prop) => {
        delete newInformation[prop];
        delete newValidation[prop];
      });
    }

    if (mobile?.eitherRequired) {
      if (
        information[EDIT_RECEIVER_PHONE_NAME] &&
        !validation[EDIT_RECEIVER_MOBILE_NAME]
      ) {
        delete newInformation[EDIT_RECEIVER_MOBILE_NAME];
        delete newValidation[EDIT_RECEIVER_MOBILE_NAME];

        setEitherRequiredMsg('');
      }

      if (
        information[EDIT_RECEIVER_MOBILE_NAME] &&
        !validation[EDIT_RECEIVER_PHONE_NAME]
      ) {
        delete newInformation[EDIT_RECEIVER_PHONE_NAME];
        delete newValidation[EDIT_RECEIVER_PHONE_NAME];

        setEitherRequiredMsg('');
      }
    } else {
      if (!mobile?.visible) {
        delete newInformation[EDIT_RECEIVER_MOBILE_NAME];
        delete newValidation[EDIT_RECEIVER_PHONE_NAME];
      }

      if (!phone?.visible) {
        delete newInformation[EDIT_RECEIVER_PHONE_NAME];
        delete newValidation[EDIT_RECEIVER_PHONE_NAME];
      }

      if (
        !phone?.required &&
        phone?.visible &&
        !validation[EDIT_RECEIVER_PHONE_NAME]
      ) {
        delete newInformation[EDIT_RECEIVER_PHONE_NAME];
        delete newValidation[EDIT_RECEIVER_PHONE_NAME];
      }

      if (
        !mobile?.required &&
        mobile?.visible &&
        !validation[EDIT_RECEIVER_MOBILE_NAME]
      ) {
        delete newInformation[EDIT_RECEIVER_MOBILE_NAME];
        delete newValidation[EDIT_RECEIVER_MOBILE_NAME];
      }
    }

    if (!relationship?.required) {
      delete newInformation[EDIT_RECEIVER_RELATIONSHIP_NAME];
      delete newValidation[EDIT_RECEIVER_RELATIONSHIP_NAME];
    }

    const isAllowSubmit =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value) &&
      !eitherRequiredMsg;

    if (checkInformationChanged(information)) {
      if (isAllowSubmit) {
        dispatch({ type: SET_SHOW_LOADING, payload: true });

        const promises = [];

        if (isNameChanged(information) || isGenderChanged(information)) {
          promises.push(updateReceiverName());
        }

        if (isMobileChanged(information)) {
          if (!information[EDIT_RECEIVER_MOBILE_NAME]) {
            promises.push(
              deleteReceiverMobilePhone(currentReceiver?.mobile?.id)
            );
          } else {
            promises.push(
              updateReceiverMobilePhone(
                'mobile',
                information[EDIT_RECEIVER_MOBILE_NAME]
              )
            );
          }
        }

        if (isPhoneChanged(information)) {
          if (!information[EDIT_RECEIVER_PHONE_NAME]) {
            promises.push(
              deleteReceiverMobilePhone(currentReceiver?.phone?.id)
            );
          } else {
            promises.push(
              updateReceiverMobilePhone(
                'phone',
                information[EDIT_RECEIVER_PHONE_NAME]
              )
            );
          }
        }

        if (isRelationshipChanged(information)) {
          promises.push(updateReceiverRelationship());
        }

        try {
          await Promise.all(promises);

          const newInformation = {
            ...information,
            [EDIT_RECEIVER_FULLNAME_NAME]:
              information[EDIT_RECEIVER_LASTNAME_NAME] +
              ' ' +
              information[EDIT_RECEIVER_FISTNAME_NAME],
          };
          setInformation(newInformation);

          handleOnToggleUpdatePopup();

          dispatch({
            type: SET_EDIT_RECEIVER_INFORMATION,
            payload: RESET_EDIT_RECEIVER_INFORMATION_STORE,
          });

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        } catch (error) {
          checkMaintenance(error);
          console.error(error);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      }

      if (!isAllowSubmit) {
        newValidation = checkFormInputValidation(
          information[EDIT_RECEIVER_GENDER_NAME],
          EDIT_RECEIVER_PERSONAL_DETAILS_GENDER_FIELD,
          newValidation
        );

        if (mobile?.eitherRequired) {
          if (
            !information[EDIT_RECEIVER_MOBILE_NAME] &&
            !information[EDIT_RECEIVER_PHONE_NAME]
          ) {
            setEitherRequiredMsg('form_mobile_phone_invalid_1');
          } else {
            setEitherRequiredMsg('');
          }
        } else {
          if (mobile?.required && !newValidation[EDIT_RECEIVER_MOBILE_NAME]) {
            newValidation = checkFormInputValidation(
              information[EDIT_RECEIVER_MOBILE_NAME],
              EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS[0],
              newValidation
            );
          }

          if (phone?.required && !newValidation[EDIT_RECEIVER_PHONE_NAME]) {
            newValidation = checkFormInputValidation(
              information[EDIT_RECEIVER_PHONE_NAME],
              EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS[1],
              newValidation
            );
          }
        }

        if (relationship?.required) {
          newValidation = checkFormInputValidation(
            information[EDIT_RECEIVER_RELATIONSHIP_NAME]?.key,
            EDIT_RECEIVER_RELATIONSHIP_FIELD,
            newValidation
          );
        }

        setValidation(newValidation);
      }
    } else {
      if (isAllowSubmit) {
        handleOnToggleCancelCurrentTabPopup();
      }
    }
  };

  const renderSelectTypeOfReceiverForm = () => {
    return (
      <FormInputSelectTypeOfReceiverRadio
        countryConfig={countryConfig}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        isDisabled
        isEdit
      />
    );
  };

  const renderNameForm = () => {
    if (isReceiverOfIndividualType) {
      if (isVietNamCountry)
        return (
          <>
            {EDIT_RECEIVER_GENERAL_DETAILS_NAME_FIELDS.slice(0, 2).map(
              (field) => (
                <FormInputValidation
                  key={field.name}
                  field={field}
                  information={information}
                  setInformation={setInformation}
                  validation={validation}
                  setValidation={setValidation}
                  isEdit={true}
                />
              )
            )}
          </>
        );

      if (!isVietNamCountry)
        return (
          <>
            {EDIT_RECEIVER_GENERAL_DETAILS_NAME_FIELDS.slice(0, 2)
              .reverse()
              .map((field) => (
                <FormInputValidation
                  key={field.name}
                  field={field}
                  information={information}
                  setInformation={setInformation}
                  validation={validation}
                  setValidation={setValidation}
                  isEdit={true}
                />
              ))}
          </>
        );
    }

    if (isReceiverOfBusinessType) {
      return (
        <FormInputValidation
          key={EDIT_RECEIVER_BUSINESS_NAME_FIELD.name}
          field={EDIT_RECEIVER_BUSINESS_NAME_FIELD}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          styles={{
            width: '100%',
            float: 'unset',
            marginLeft: '0px',
            marginRight: '0px',
          }}
          isEdit={true}
        />
      );
    }

    return null;
  };

  const renderFullNameForm = () => {
    if (isVietNamCountry)
      return (
        <FormInputValidation
          field={EDIT_RECEIVER_GENERAL_DETAILS_NAME_FIELDS[2]}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
        />
      );

    return null;
  };

  const renderGenderForm = () => {
    return (
      <FormInputGenderRadio
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        isDisabled={currentReceiver?.gender}
      />
    );
  };

  const renderMobileForm = () => {
    if (mobile?.visible)
      return (
        <FormInputMobileOrPhoneNumberValidation
          field={EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS[0]}
          config={mobile}
          numberMaskStr={mobileMaskStr}
          setNumberMaskStr={setMobileMaskStr}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          setEitherRequiredMsg={setEitherRequiredMsg}
          styles={
            !phone.visible
              ? {
                  float: 'unset',
                  width: '100%',
                }
              : {}
          }
        />
      );

    return null;
  };

  const renderPhoneForm = () => {
    if (phone?.visible && isReceiverOfIndividualType)
      return (
        <FormInputMobileOrPhoneNumberValidation
          field={EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS[1]}
          config={phone}
          numberMaskStr={phoneMaskStr}
          setNumberMaskStr={setPhoneMaskStr}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          setEitherRequiredMsg={setEitherRequiredMsg}
          styles={
            !mobile.visible
              ? {
                  float: 'unset',
                  width: '100%',
                }
              : {}
          }
        />
      );

    if (phone?.visible && isReceiverOfBusinessType)
      return (
        <FormInputMobileOrPhoneNumberValidation
          field={EDIT_RECEIVER_BUSINESS_PHONE_FIELD}
          config={phone}
          numberMaskStr={phoneMaskStr}
          setNumberMaskStr={setPhoneMaskStr}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          setEitherRequiredMsg={setEitherRequiredMsg}
          styles={
            !mobile.visible
              ? {
                  float: 'unset',
                  width: '100%',
                }
              : {}
          }
        />
      );

    return null;
  };

  const renderRelationshipForm = () => {
    if (relationship?.visible && isReceiverOfIndividualType)
      return (
        <CustomSearchDropdown
          field={EDIT_RECEIVER_RELATIONSHIP_FIELD}
          isConfigRequired={relationship?.required}
          data={configRelationship}
          keyFind="key"
          keyTranslate="value"
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          isClearBoth
        />
      );

    if (isReceiverOfBusinessType)
      return (
        <CustomSearchDropdown
          field={EDIT_RECEIVER_RELATIONSHIP_FIELD}
          isConfigRequired={true}
          data={configRelationshipOfBusiness}
          keyFind="key"
          keyTranslate="value"
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          isClearBoth
        />
      );

    return null;
  };

  const isAllowRenderNextSection =
    information[EDIT_RECEIVER_COUNTRY_NAME] &&
    information[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME];
  const renderNextSection = () => {
    if (!isAllowRenderNextSection) return null;
    if (isReceiverOfIndividualType)
      return (
        <>
          {renderNameForm()}
          {renderFullNameForm()}
          {renderGenderForm()}
          {renderMobileForm()}
          {renderPhoneForm()}
          {Boolean(isShowEitherRequiredMsg) && (
            <ControlFeedback>{t(eitherRequiredMsg)}</ControlFeedback>
          )}
          {renderRelationshipForm()}
        </>
      );
    if (isReceiverOfBusinessType)
      return (
        <>
          {renderNameForm()}
          {renderMobileForm()}
          {renderPhoneForm()}
          {renderRelationshipForm()}
        </>
      );
    return null;
  };

  return (
    <PersonalDetailsStyled>
      <Title>{t('label_general_details')}</Title>
      <CustomSearchDropdown
        field={EDIT_RECEIVER_COUNTRY_FIELD}
        data={countries}
        keyFind="name"
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        isDisabled={true}
      />
      {renderSelectTypeOfReceiverForm()}
      {renderNextSection()}
      <Fade in appear>
        <NextWrap>
          <ButtonCommon
            value={t('button_cancel')}
            onClick={handleOnToggleCancelPopup}
            styles={{
              margin: '0px',
              marginRight: '16px',
              width: '211px',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
          <ButtonCommon
            value={t('button_save')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: '211px',
            }}
            onClick={handleSave}
            isUseKeyDown
          />
        </NextWrap>
      </Fade>
    </PersonalDetailsStyled>
  );
};

const PersonalDetailsStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 19px;
  padding-inline: 0px;
  padding-bottom: 140px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 40px;
    padding-inline: 116px;
    padding-bottom: unset;
  }
`;

const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 24px;
  }
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  background: var(--ds-c-white);
  z-index: 1;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #eef2f5;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    height: 40px;
    border-top: unset;
  }
`;

const ControlFeedback = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: relative;
  top: -30px;
  margin: 0;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
    display: table;
  }
`;

export default PersonalDetails;
