import { fetchLookupFee } from 'components/Transactions/func';
import {
  CALCULATOR_FORM_PAYOUT_METHOD_NAME,
  CALCULATOR_FORM_SEND_TO_NAME,
} from 'constants';
import { checkMaintenance } from 'helpers/maintenance';
import { isEmpty } from 'lodash';
import { numberUtils } from 'utils';

const lookupFee = async (amount, information, countryCode) => {
  if (isEmpty(countryCode)) return;

  const { code: dmCode } =
    information[CALCULATOR_FORM_PAYOUT_METHOD_NAME] || {};

  try {
    const newInformation = await fetchLookupFee(
      amount,
      dmCode,
      information,
      true,
      countryCode
    );

    return newInformation;
  } catch (error) {
    checkMaintenance(error);
    console.error(error?.message);
  }
};

export const fetchCurrentFee = async (
  information,
  setInformation,
  countryCode,
  maxSendLimit = 30000
) => {
  if (isEmpty(countryCode)) return null;

  let newInformation = { ...information };

  const sendAmount = numberUtils.mathRound(
    newInformation[CALCULATOR_FORM_SEND_TO_NAME] || 0
  );

  if (parseInt(sendAmount) && parseInt(sendAmount) <= parseInt(maxSendLimit)) {
    newInformation = await lookupFee(sendAmount, newInformation, countryCode);

    if (newInformation) {
      setInformation(newInformation);

      document.getElementById('root-body').style.overflow = 'hidden';

      return;
    }
  }

  newInformation = { ...newInformation, feeAmount: 0 };

  setInformation(newInformation);

  document.getElementById('root-body').style.overflow = 'hidden';
};
