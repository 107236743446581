/* eslint-disable no-unused-vars */
import { api } from 'api';
import GiftAnimation from 'assets/icons/gift.gif';
import CalendarHHMTImg from 'assets/images/events/calendar_giveaway_hhmt_2024.jpg';
import CalendarRemoxImg from 'assets/images/events/calendar_giveaway_remox_2024.jpg';
import FormInputCheckbox from 'components/Events/components/FormInputCheckbox';
import FormEditProfileAddress from 'components/Profile/components/FormEditProfileAddress';
import ButtonCommon from 'components/common/ButtonCommon';
import InformPopup from 'components/common/InformPopup';
import {
  DESKTOP_MIN_WIDTH,
  EN_LANG,
  EVENT_PREFIX,
  HHMT_EMAIL,
  HHMT_PHONE,
  I18N_REDIRECTED,
  REMOX_EMAIL,
  REMOX_PHONE,
  STATUS_CANCELLED,
  STATUS_SUCCESS,
} from 'constants';
import Cookies from 'helpers/cookies';
import { checkMaintenance } from 'helpers/maintenance';
import useAuth from 'hooks/useAuth';
import useCountdownToAction from 'hooks/useCountdownToAction';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  SET_CURRENT_USER,
  SET_EVENT_AVAILABLE,
  SET_SHOW_LOADING,
} from 'store/action';
import styled from 'styled-components';
import { domUtils, stringUtils } from 'utils';

const TYPE_NO = 'no';
const TYPE_YES = 'yes';

const EventPage = ({ setHideEventForm }) => {
  const { locate } = useLang();
  const { state, dispatch } = useStore();
  const { currentUser, eventAvailable } = state || {};
  const { t } = useLang();
  const { token, logout } = useAuth();
  const { isDesktop } = useDetectDevice();
  const query = useQuery();
  const lang = query.get('lang') || locate || EN_LANG;
  const { firstName, lastName } = currentUser || {};
  const { isHHMTTheme } = useTheme();
  const { pathname } = useLocation();
  const isEventPage = pathname === `${EVENT_PREFIX}`;

  const [profileInformation, setProfileInformation] = useState(null);
  const [
    isShowFormEditProfileAddressPopup,
    setShowFormEditProfileAddressPopup,
  ] = useState(false);
  const [
    isUpdateNewResidentialAddressChecked,
    setUpdateNewResidentialAddressChecked,
  ] = useState(false);
  const [isOpenInformPopup, setOpenInformPopup] = useState(false);
  const [isSubmitSuccessfully, setSubmitSuccessfully] = useState(false);
  const [isSubmitCancel, setSubmitCancel] = useState(false);
  const [isSubmitError, setSubmitError] = useState(false);

  const isUpdateNewResidentialAddress =
    profileInformation?.address?.fullAddress !==
    currentUser?.address?.fullAddress;

  useEffect(() => {
    setProfileInformation({
      address: currentUser?.address,
    });
  }, [currentUser]);

  useEffect(() => {
    i18next.changeLanguage(lang);

    Cookies.set(I18N_REDIRECTED, lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const handleSubmitAction = (status) => {
    if (!isEventPage) {
      dispatch({ type: SET_EVENT_AVAILABLE, payload: null });
    } else {
      domUtils.replaceUrlWithoutReload({
        path: `${EVENT_PREFIX}?lang=${lang}&status=${status}`,
      });

      dispatch({ type: SET_EVENT_AVAILABLE, payload: null });
    }
  };

  useCountdownToAction(
    1,
    isSubmitSuccessfully,
    () => {
      handleSubmitAction(STATUS_SUCCESS);

      setSubmitSuccessfully(false);
    },
    [isSubmitSuccessfully]
  );

  useCountdownToAction(
    1,
    isSubmitCancel,
    () => {
      handleSubmitAction(STATUS_CANCELLED);

      dispatch({ type: SET_SHOW_LOADING, payload: false });

      setSubmitCancel(false);
    },
    [isSubmitCancel],
    0.3
  );

  useCountdownToAction(
    1,
    isSubmitError,
    () => {
      handleSubmitAction(STATUS_CANCELLED);

      dispatch({ type: SET_SHOW_LOADING, payload: false });

      setOpenInformPopup(false);

      setSubmitError(false);
    },
    [isSubmitError],
    0.3
  );

  const handleOnOpenFormEditProfileAddressPopup = async () => {
    setShowFormEditProfileAddressPopup(true);

    if (isDesktop) {
      setHideEventForm(true);
    }
  };
  const handleOnCloseFormEditProfileAddressPopup = () => {
    setShowFormEditProfileAddressPopup(false);

    if (isDesktop) {
      setHideEventForm(false);
    }
  };

  const handleFormCheckboxToogle = () => {
    setUpdateNewResidentialAddressChecked(
      !isUpdateNewResidentialAddressChecked
    );
  };

  const updateProfileInformation = async (payload) => {
    if (isUpdateNewResidentialAddressChecked) {
      try {
        const { data: dataUpdate } = await api.updateProfile(token, payload);

        if (dataUpdate) {
          const { data: dataProfile } = await api.getProfile(token);

          if (dataProfile) {
            const authUser = {
              ...dataProfile,
              token,
            };

            dispatch({ type: SET_CURRENT_USER, payload: authUser });
          }
        }
      } catch (error) {
        checkMaintenance(error);
        console.error(error?.message);
      }
    }
  };

  const handleSubmit = async (type) => {
    updateProfileInformation(profileInformation);

    let eventPayload = {
      eventId: eventAvailable?.id,
      data: {
        ...profileInformation?.address,
        country: 'AUSTRALIA',
        recipient: `${firstName} ${lastName}`,
        response: true,
        updatedResidentialAddress: isUpdateNewResidentialAddressChecked,
      },
    };
    delete eventPayload?.data?.manual;
    delete eventPayload?.data?.fullAddress;

    if (type === TYPE_NO) {
      delete eventPayload?.data?.street;
      delete eventPayload?.data?.suburb;
      delete eventPayload?.data?.state;
      delete eventPayload?.data?.postcode;
      delete eventPayload?.data?.country;
      delete eventPayload?.data?.recipient;
      eventPayload = {
        ...eventPayload,
        data: { ...eventPayload.data, response: false },
      };
    }

    if (type === TYPE_YES) {
      eventPayload = {
        ...eventPayload,
        data: { ...eventPayload.data, response: true },
      };
    }

    try {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      const { data } = await api.addEventRecordData(token, eventPayload);

      if (data) {
        if (type === TYPE_NO) {
          setSubmitCancel(true);
        }
        if (type === TYPE_YES) {
          dispatch({ type: SET_SHOW_LOADING, payload: false });

          setSubmitSuccessfully(true);
        }
      }
    } catch (error) {
      checkMaintenance(error);
      console.error(error?.message);

      setOpenInformPopup(true);

      dispatch({ type: SET_SHOW_LOADING, payload: false });
    }
  };

  const handleOnClickSubmitError = () => {
    setSubmitError(true);

    dispatch({ type: SET_SHOW_LOADING, payload: true });
  };

  if (isSubmitSuccessfully) {
    return (
      <SuccessfullySection>
        <GiftImage src={GiftAnimation} width={150} height={150} />
      </SuccessfullySection>
    );
  }
  return (
    <>
      {isOpenInformPopup && (
        <InformPopup
          isOpen={isOpenInformPopup}
          content={stringUtils.replaceKeyword(
            t('events_calendar_giveaway_submit_error'),
            [
              { key: 'email', value: isHHMTTheme ? HHMT_EMAIL : REMOX_EMAIL },
              { key: 'phone', value: isHHMTTheme ? HHMT_PHONE : REMOX_PHONE },
            ]
          )}
          buttonLabel2={t('button_ok')}
          onClick={handleOnClickSubmitError}
          isBackdropOverlap={true}
          zIndexBackdropOverlap={1055}
        />
      )}

      {isShowFormEditProfileAddressPopup && (
        <FormEditProfileAddress
          isOpen={isShowFormEditProfileAddressPopup}
          onClose={handleOnCloseFormEditProfileAddressPopup}
          modalStyles={{
            top: isEventPage ? '0px' : '70px',
          }}
          isEnterAddressManually={false}
          isSubmitUpdateProfileAddress={false}
          profileInformation={profileInformation}
          setProfileInformation={setProfileInformation}
          isBackdropOverlap={isDesktop}
        />
      )}

      <EventPageStyled>
        <Title onClick={() => logout()}>
          {t('events_calendar_giveaway_title')}
        </Title>
        <MainImage
          src={isHHMTTheme ? CalendarHHMTImg : CalendarRemoxImg}
          width="327"
          height="200"
          alt=""
        />
        <Desc
          dangerouslySetInnerHTML={{
            __html: t('events_calendar_giveaway_desc'),
          }}
        />
        {profileInformation?.address?.fullAddress && (
          <InputEditWrap>
            <InputMask>{profileInformation?.address?.fullAddress}</InputMask>
            <EditButton onClick={handleOnOpenFormEditProfileAddressPopup}>
              {t('button_edit')}
            </EditButton>
          </InputEditWrap>
        )}
        {isUpdateNewResidentialAddress && (
          <InputCheckboxWrap onClick={handleFormCheckboxToogle}>
            <FormInputCheckbox
              isChecked={isUpdateNewResidentialAddressChecked}
            />{' '}
            <Label>{t('events_calendar_giveaway_update_address')}</Label>
          </InputCheckboxWrap>
        )}
        <Desc
          dangerouslySetInnerHTML={{
            __html: t('events_calendar_giveaway_additional'),
          }}
        />
        <ButtonActions>
          <ButtonCommon
            value={t('button_no_thanks')}
            onClick={() => handleSubmit(TYPE_NO)}
            styles={{
              margin: '0px',
              width: '50%',
              textTransform: 'uppercase',
            }}
            color="var(--c-primary)"
            background="var(--ds-c-white)"
          />
          <ButtonCommon
            value={t('button_yes')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: '50%',
              textTransform: 'uppercase',
            }}
            onClick={() => handleSubmit(TYPE_YES)}
            isUseKeyDown
          />
        </ButtonActions>
      </EventPageStyled>
    </>
  );
};

const EventPageStyled = styled.div`
  margin: 0px;
  padding: 16px 24px 0px 24px;
  height: calc(100dvh - 70px);
  width: 100%;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding: unset;
    height: fit-content;
  }
`;
const Title = styled.h3`
  font-family: var(--ff-primary);
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  color: var(--ds-c-blue);
`;
const Desc = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  width: 100%;
  color: var(--c-primary);
  margin-bottom: 0px;
`;
const MainImage = styled.img`
  display: block;
  margin-inline: auto;
  margin-block: 16px 10px;
  width: 100%;
  height: fit-content;
  object-fit: contain;
`;

const InputEditWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 10px;
  margin-block: 16px;
  border-radius: 12px;
  border: 1px dashed var(--ds-c-grey-neutral);
`;
const InputMask = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: var(--c-primary);
  margin-bottom: 0px;
`;
const EditButton = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: var(--c-tertiary);
  margin-bottom: 0px;
  margin-left: 10px;
  cursor: pointer;
  min-width: 74px;
  background: #fff;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: right;

  &:hover {
    color: var(--c-tertiary-hover);
  }
`;

const InputCheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
`;
const Label = styled.p`
  font-family: var(--ff-primary);
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  width: 100%;
  color: var(--c-primary);
  margin-bottom: 0px;
  height: 28px;
  padding-top: 7px;
`;

const SuccessfullySection = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  height: calc(100dvh - 75px);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: 438px;
  }
`;
const GiftImage = styled.img``;

const ButtonActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  background: var(--ds-c-white);
  z-index: 1;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #eef2f5;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 24px;
    margin-bottom: 0px;
    margin-inline: unset;
    padding-inline: unset;
    height: 44px;
    border-top: unset;
  }
`;

export default EventPage;
