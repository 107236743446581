import { LogUtil } from 'components/common/LogUtil';
import { NUMBER_OF_DAYS_EXIST } from 'constants';
import { signInWithCustomToken } from 'firebase/auth';
import { off, onValue, orderByChild, query, ref } from 'firebase/database';
import { datePicker } from 'helpers';
import { checkMaintenance } from 'helpers/maintenance';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { SET_NEW_NOTIFICATION, SET_NOTIFICATIONS } from 'store/action';
import { arrayUtils } from 'utils';
import useFirebase, {
  auth,
  getPathNotificationsBySenderId,
  getRealtimeDB,
} from './useFirebase';
import useStore from './useStore';

const TAG = 'useRetriveNotificationsRealtimeDB';

const useRetriveNotificationsRealtimeDB = () => {
  const { state, dispatch } = useStore();
  const { currentUser } = state || {};
  const { firebaseAppDefault } = useFirebase();

  const db = !isEmpty(firebaseAppDefault) && getRealtimeDB(firebaseAppDefault);
  let queryOfData;
  let dataSnapshot;

  const signInAndRetriveDataFromRealtimeDatabase = (currentUser) => {
    const { senderId, firebaseToken } = currentUser || {};

    try {
      signInWithCustomToken(auth(firebaseAppDefault), firebaseToken)
        .then(() => {
          const notificationsBySenderIdRef = ref(
            db,
            getPathNotificationsBySenderId(senderId)
          );

          queryOfData = query(
            notificationsBySenderIdRef,
            orderByChild('createdAt')
          );

          onValue(
            queryOfData,
            (snapshot) => {
              let dataSnapshotSorted = [];

              snapshot.forEach((childSnapshot) => {
                const childSnapshotData = childSnapshot.val();
                const notifyDeleted = childSnapshotData.deleted;
                const notifyExpired =
                  datePicker.getExpiredWithNumberOfDaysToCurrentDate(
                    new Date(childSnapshotData.createdAt),
                    NUMBER_OF_DAYS_EXIST
                  );

                if (!notifyDeleted && !notifyExpired) {
                  dataSnapshotSorted.push(childSnapshot.val());
                }
              });

              dataSnapshotSorted = dataSnapshotSorted.reverse();

              const dataSnapshotFiltered = dataSnapshotSorted.filter(
                (ds) => !ds.read
              );

              dataSnapshotFiltered.forEach((ds) => {
                dataSnapshotSorted = arrayUtils.removeItemInArrayObject(
                  dataSnapshotSorted,
                  ds.read,
                  'read'
                );
              });

              dataSnapshot = [...dataSnapshotFiltered, ...dataSnapshotSorted];

              dispatch({
                type: SET_NOTIFICATIONS,
                payload: dataSnapshot,
              });

              if (dataSnapshotFiltered?.length) {
                dispatch({
                  type: SET_NEW_NOTIFICATION,
                  payload: true,
                });
              }
            },
            {
              onlyOnce: false,
            }
          );
        })
        .catch((error) => {
          LogUtil.error(TAG, error?.message);
        });
    } catch (error) {
      checkMaintenance(error);
      LogUtil.error(TAG, error?.message);
    }
  };

  const closeRealtimeDatabaseConnection = (callback = () => {}) => {
    if (!isEmpty(db) && !isEmpty(queryOfData) && !isEmpty(dataSnapshot)) {
      LogUtil.log(TAG, 'closeRealtimeDatabaseConnection');
      off(queryOfData);
      callback();
    }
  };

  useEffect(() => {
    const isNotificationInit = currentUser && firebaseAppDefault;

    if (isNotificationInit) {
      signInAndRetriveDataFromRealtimeDatabase(currentUser);
    }

    return () => {
      if (isNotificationInit) {
        closeRealtimeDatabaseConnection();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, firebaseAppDefault]);
};

export default useRetriveNotificationsRealtimeDB;
