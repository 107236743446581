import CloseSmallIcon from 'assets/icons/close-small-icon.svg';
import SendIcon from 'assets/icons/send-icon.svg';
import {
  redirectToTransactionDetails,
  updateNotifyToReaded,
} from 'components/Profile/func';
import Transactions from 'components/Transactions';
import FilterTransaction from 'components/Transactions/components/FilterTransaction';
import PaginationLazy from 'components/Transactions/components/Pagination/mobile';
import SearchTransaction from 'components/Transactions/components/SearchTransaction';
import {
  convertFilteredKeyFixed,
  fetchTransactionsFiltered,
  getBodyPayloadFiltered,
} from 'components/Transactions/func';
import TransactionsMobile from 'components/Transactions/mobile';
import ButtonCommon from 'components/common/ButtonCommon';
import CollapsibleSideMenu from 'components/common/CollapsibleSideMenu';
import Endpoint from 'components/common/Endpoint';
import MobileTabMenu from 'components/common/MobileTabMenu';
import NavbarFooter from 'components/common/NavbarFooter/ver2';
import {
  DESKTOP_MIN_WIDTH,
  PAGE_DEFAULT,
  SEARCH_BY_DEFAULT,
  TRANSACTION_ADD_PREFIX,
  TRANSACTION_STATUS_TRANSLATE_REFERENCE,
  TRANSACTION_TABLE_SORT_ORDER_INITIAL,
} from 'constants';
import { datePicker, format } from 'helpers';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { isArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { arrayUtils, objectUtils } from 'utils/func';

const ENDPOINT_ID = 'TransactionsPage-endpoint';

const TransactionsPage = () => {
  const { t } = useLang();
  const { state } = useStore();
  const { currentUser, notifications } = state;
  const { token } = useAuth();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDetectDevice();
  const query = useQuery();
  const tid = query.get('tid');
  const mid = query.get('mid');
  const { refreshTransactionsList } = state;

  const [transactions, setTransactions] = useState(null);
  const [currentPage, setCurrentPage] = useState(PAGE_DEFAULT);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [sortOrder, setSortOrder] = useState(
    TRANSACTION_TABLE_SORT_ORDER_INITIAL
  );
  const [applyFiltered, setApplyFiltered] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [currentSearchBy, setCurrentSearchBy] = useState(SEARCH_BY_DEFAULT);
  const [isFetching, setFetching] = useState(false);
  const [isSearched, setSearched] = useState(false);
  const { isMenuExpand } = state; // For Collapsible Menu

  useEffect(() => {
    if (notifications && Array.isArray(notifications) && tid && mid) {
      const notiData = notifications?.find(
        (noti) => noti?.data?.tranId === tid && noti?.uuid === mid
      );

      if (notiData) {
        if (!notiData?.read) {
          updateNotifyToReaded(currentUser, notiData);
        }

        redirectToTransactionDetails(token, notiData, navigate);
      }
    }
  }, [notifications, tid, mid]);

  // const isIntersecting = useDetectEndpointVisibleOnScreenWithId(ENDPOINT_ID);
  const isFetchTransactionsInitial =
    token &&
    // isIntersecting &&
    !isArray(transactions) &&
    isEmpty(transactions) &&
    !searchValue &&
    isEmpty(applyFiltered) &&
    Object.values(sortOrder).every((value) => !value) &&
    refreshTransactionsList;

  useEffect(() => {
    if (isFetchTransactionsInitial) {
      setTransactions(null);
      fetchTransactionsFiltered({
        token,
        page: PAGE_DEFAULT,
        transactions: [],
        setTransactions,
        setCurrentPage,
        setTotalPages,
        setTotalElements,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchTransactionsInitial]);

  const removeFilterByArray = (key, arr, obj, prop) => {
    const arrayFilter = arrayUtils.removeItemInArrayObject(
      arr,
      obj[prop],
      prop
    );

    if (arrayUtils.isArrayEmpty(arrayFilter)) {
      delete applyFiltered[key];

      return applyFiltered;
    }

    const newApplyFiltered = {
      ...applyFiltered,
      [key]: arrayFilter,
    };
    return newApplyFiltered;
  };
  const removeFilterByDate = () => {
    delete applyFiltered['dateFromSelected'];

    delete applyFiltered['dateToSelected'];

    return applyFiltered;
  };
  const removeFilterByAmount = () => {
    delete applyFiltered['amountFrom'];

    delete applyFiltered['amountTo'];

    return applyFiltered;
  };
  const removeTypeFilteredReference = {
    countriesSelected: (key, array, country) => {
      return removeFilterByArray(key, array, country, 'code');
    },
    statusSelected: (key, array, status) => {
      return removeFilterByArray(key, array, status, 'code');
    },
    dateFromSelected: () => {
      return removeFilterByDate();
    },
    dateToSelected: () => {
      return removeFilterByDate();
    },
    amountFrom: () => {
      return removeFilterByAmount();
    },
    amountTo: () => {
      return removeFilterByAmount();
    },
  };
  const handleRemoveFiltered = (typeFilter, value) => {
    setFetching(true);

    const newApplyFiltered = removeTypeFilteredReference[typeFilter](
      typeFilter,
      applyFiltered[typeFilter],
      value
    );
    setApplyFiltered(newApplyFiltered);

    let bodyPayload = getBodyPayloadFiltered(newApplyFiltered);

    bodyPayload = {
      ...bodyPayload,
      [currentSearchBy]: searchValue,
    };

    setTransactions(null);
    fetchTransactionsFiltered({
      token,
      body: bodyPayload,
      page: PAGE_DEFAULT,
      setTransactions,
      setCurrentPage,
      setTotalPages,
      setTotalElements,
      setFetching,
    });
  };

  const renderFilterByArray = (key, arr, isTranslate) => {
    return arr.map((item, index) => (
      <Pill key={`${key}-${index}`}>
        {isTranslate
          ? t(TRANSACTION_STATUS_TRANSLATE_REFERENCE[item.name])
          : item.name}{' '}
        <img
          src={CloseSmallIcon}
          width={12}
          height={12}
          alt=""
          onClick={() => handleRemoveFiltered(key, item)}
        />
      </Pill>
    ));
  };
  const getDateStr = (key) =>
    datePicker.dateUTCToDateStr(new Date(applyFiltered[key]));
  const renderFilterByDate = (key) => {
    const keyDateFrom = 'dateFromSelected';
    const keyDateTo = 'dateToSelected';

    if (applyFiltered[keyDateFrom] && applyFiltered[keyDateTo]) {
      if (key === keyDateFrom) {
        return (
          <Pill key={`From ${keyDateFrom} To ${keyDateTo}`}>
            {`${getDateStr(keyDateFrom)} - ${getDateStr(keyDateTo)}`}{' '}
            <img
              src={CloseSmallIcon}
              width={12}
              height={12}
              alt=""
              onClick={() => handleRemoveFiltered(keyDateFrom)}
            />
          </Pill>
        );
      }

      return null;
    }

    if (applyFiltered[keyDateFrom] && !applyFiltered[keyDateTo]) {
      return (
        <Pill key={`${keyDateFrom}`}>
          {`From ${getDateStr(keyDateFrom)} To -`}{' '}
          <img
            src={CloseSmallIcon}
            width={12}
            height={12}
            alt=""
            onClick={() => handleRemoveFiltered(keyDateFrom)}
          />
        </Pill>
      );
    }

    if (!applyFiltered[keyDateFrom] && applyFiltered[keyDateTo]) {
      return (
        <Pill key={`${keyDateTo}`}>
          {`From - To ${getDateStr(keyDateTo)}`}{' '}
          <img
            src={CloseSmallIcon}
            width={12}
            height={12}
            alt=""
            onClick={() => handleRemoveFiltered(keyDateTo)}
          />
        </Pill>
      );
    }

    return null;
  };
  const renderFilterByAmount = (key) => {
    const keyAmountFrom = 'amountFrom';
    const keyAmountTo = 'amountTo';

    if (applyFiltered[keyAmountFrom] && !applyFiltered[keyAmountTo]) {
      return (
        <Pill key={`${keyAmountFrom}`}>
          {`AUD: Minimum ${format.toAmountStr(applyFiltered[keyAmountFrom])}`}{' '}
          <img
            src={CloseSmallIcon}
            width={12}
            height={12}
            alt=""
            onClick={() => handleRemoveFiltered(keyAmountFrom)}
          />
        </Pill>
      );
    }

    if (!applyFiltered[keyAmountFrom] && applyFiltered[keyAmountTo]) {
      return (
        <Pill key={`${keyAmountTo}`}>
          {`AUD: Maximum ${format.toAmountStr(applyFiltered[keyAmountTo])}`}{' '}
          <img
            src={CloseSmallIcon}
            width={12}
            height={12}
            alt=""
            onClick={() => handleRemoveFiltered(keyAmountTo)}
          />
        </Pill>
      );
    }

    if (key === keyAmountTo) return null;

    return (
      <Pill key={`${keyAmountFrom}-${keyAmountTo}`}>
        {`AUD: ${format.toAmountStr(
          applyFiltered[keyAmountFrom]
        )} - ${format.toAmountStr(applyFiltered[keyAmountTo])}`}{' '}
        <img
          src={CloseSmallIcon}
          width={12}
          height={12}
          alt=""
          onClick={() => handleRemoveFiltered(keyAmountFrom)}
        />
      </Pill>
    );
  };
  const renderTypeFilteredReference = {
    countriesSelected: (key, array) => {
      return renderFilterByArray(key, array);
    },
    statusSelected: (key, array) => {
      return renderFilterByArray(key, array, true);
    },
    dateFromSelected: (key) => {
      return renderFilterByDate(key);
    },
    dateToSelected: (key) => {
      return renderFilterByDate(key);
    },
    amountFrom: (key) => {
      return renderFilterByAmount(key);
    },
    amountTo: (key) => {
      return renderFilterByAmount(key);
    },
  };
  const renderSearchFilterSection = () => {
    const isShowFilteredPills = !objectUtils.isObjectEmpty(applyFiltered);
    const isShowSearchFilterAction =
      (Array.isArray(transactions) &&
        !arrayUtils.isArrayEmpty(transactions) &&
        searchValue === '' &&
        totalElements > 0) ||
      searchValue !== '' ||
      isShowFilteredPills;

    const isNoTransaction =
      Array.isArray(transactions) && arrayUtils.isArrayEmpty(transactions);

    if (isMobile || isTablet) {
      return (
        <>
          {!isNoTransaction && (
            <>
              <SearchTransaction
                applyFiltered={applyFiltered}
                currentSearchBy={currentSearchBy}
                setCurrentSearchBy={setCurrentSearchBy}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                transactions={transactions}
                setTransactions={setTransactions}
                setTotalPages={setTotalPages}
                setCurrentPage={setCurrentPage}
                setSortOrder={setSortOrder}
                setTotalElements={setTotalElements}
                setFetching={setFetching}
                setSearched={setSearched}
              />
              <FilterTransaction
                applyFiltered={applyFiltered}
                currentSearchBy={currentSearchBy}
                searchValue={searchValue}
                setApplyFiltered={setApplyFiltered}
                setTransactions={setTransactions}
                setTotalPages={setTotalPages}
                setCurrentPage={setCurrentPage}
                setSortOrder={setSortOrder}
                setTotalElements={setTotalElements}
                setFetching={setFetching}
              />
              {Boolean(transactions?.length) && (
                <PaginationLazy
                  applyFiltered={applyFiltered}
                  searchValue={searchValue}
                  currentSearchBy={currentSearchBy}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  transactions={transactions}
                  setTransactions={setTransactions}
                  isSearched={isSearched}
                />
              )}
            </>
          )}
          {isShowFilteredPills && (
            <FilteredWrap>
              {Object.keys(convertFilteredKeyFixed(applyFiltered)).map(
                (key) => {
                  return renderTypeFilteredReference[key](
                    key,
                    applyFiltered[key]
                  );
                }
              )}
            </FilteredWrap>
          )}
        </>
      );
    }

    return (
      <>
        {!isNoTransaction && (
          <>
            <SearchTransaction
              applyFiltered={applyFiltered}
              currentSearchBy={currentSearchBy}
              setCurrentSearchBy={setCurrentSearchBy}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              transactions={transactions}
              setTransactions={setTransactions}
              setTotalPages={setTotalPages}
              setCurrentPage={setCurrentPage}
              setSortOrder={setSortOrder}
              setTotalElements={setTotalElements}
              setFetching={setFetching}
              setSearched={setSearched}
            />
            <FilterTransaction
              applyFiltered={applyFiltered}
              currentSearchBy={currentSearchBy}
              searchValue={searchValue}
              setApplyFiltered={setApplyFiltered}
              setTransactions={setTransactions}
              setTotalPages={setTotalPages}
              setCurrentPage={setCurrentPage}
              setSortOrder={setSortOrder}
              setTotalElements={setTotalElements}
              setFetching={setFetching}
            />
            {Boolean(transactions?.length) && (
              <ButtonCommon
                value={t('button_send_money')}
                color="var(--c-primary)"
                background="var(--bg-primary)"
                isFill={true}
                styles={{
                  paddingInline: 0,
                }}
                buttonIconStyles={{
                  float: 'right',
                }}
                iconSrc={SendIcon}
                onClick={() => {
                  Cookies.remove(MAKE_PAYMENT_KEY);
                  navigate(`${TRANSACTION_ADD_PREFIX}`);
                }}
              />
            )}
          </>
        )}
        {isShowFilteredPills && (
          <FilteredWrap>
            {Object.keys(convertFilteredKeyFixed(applyFiltered)).map((key) => {
              return renderTypeFilteredReference[key](key, applyFiltered[key]);
            })}
          </FilteredWrap>
        )}
      </>
    );
  };

  if (isMobile || isTablet) {
    return (
      <TransactionsStyled>
        <MobileTabMenu />
        {renderSearchFilterSection()}
        <Endpoint id={ENDPOINT_ID} />
        <TransactionsMobile
          transactions={transactions}
          setTransactions={setTransactions}
          searchValue={searchValue}
          applyFiltered={applyFiltered}
          currentSearchBy={currentSearchBy}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setTotalElements={setTotalElements}
          setTotalPages={setTotalPages}
          isFetching={isFetching}
          setFetching={setFetching}
          isSearched={isSearched}
          setSearched={setSearched}
        />
        <NavbarFooter />
      </TransactionsStyled>
    );
  }
  return (
    <>
      <LayoutContainer>
        <CollapsibleSideMenu />

        <OuterContainerStyled isMenuExpand={isMenuExpand}>
          <TransactionsStyled>
            <Title>{t('transactions_title')}</Title>
            {renderSearchFilterSection()}
            <Endpoint id={ENDPOINT_ID} />
            <Transactions
              currentPage={currentPage}
              totalPages={totalPages}
              transactions={transactions}
              setTransactions={setTransactions}
              applyFiltered={applyFiltered}
              searchValue={searchValue}
              currentSearchBy={currentSearchBy}
              setTotalPages={setTotalPages}
              setCurrentPage={setCurrentPage}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              totalElements={totalElements}
              setTotalElements={setTotalElements}
              isFetching={isFetching}
              setFetching={setFetching}
              isSearched={isSearched}
              setSearched={setSearched}
            />
          </TransactionsStyled>
        </OuterContainerStyled>
      </LayoutContainer>
    </>
  );
};

const LayoutContainer = styled.div`
  display: flex;
  height: calc(100dvh - 70px);
`;

const OuterContainerStyled = styled.div`
  flex-grow: 1;
  margin-left: ${(props) => (props.isMenuExpand ? '300px' : '98px')};
  transition: margin-left 0.3s;
  height: fit-content;
`;

const TransactionsStyled = styled.div`
  margin: 70px 0px 0px 0px;
  box-shadow: none;
  height: calc(100dvh - 70px);
  padding: 16px 24px;
  position: relative;
  background-color: var(--ds-c-white);
  border-radius: 12px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: fit-content;
    margin: 96px 50px 47px 0;
    padding: 24px 40px;
  }
`;

const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;

  color: var(--ds-c-blue-dark);
  margin-bottom: 10px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: var(--ds-c-black);
  }
`;

const FilteredWrap = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: 4px;
  padding-top: 16px;
  margin-bottom: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 8px;
    margin-top: 66px;
    padding-top: unset;
  }
`;
const Pill = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  padding: 4px 8px;
  min-width: fit-content;
  height: 24px;
  background: #1c3f88;
  border-radius: 100px;

  &:last-child {
    margin-right: 0px;
  }

  & img {
    margin-top: -2px;
    margin-left: 2px;
    cursor: pointer;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default TransactionsPage;
