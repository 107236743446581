import {
  checkEwalletExpiryDateChange,
  getBanksAdd,
  getBanksDelete,
  getBanksUpdate,
  getEwalletsAdd,
  getEwalletsDelete,
  getEwalletsUpdate,
} from 'components/FormEditReceiver/func';
import {
  addNewBankAccount,
  addNewEwalletAccount,
  deleteBankAccount,
  deleteEwalletAccount,
  fetchReceiverInformation,
  updateBankAccount,
  updateEwalletAccount,
} from 'components/Receivers/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  DESKTOP_MIN_WIDTH,
  METHOD_BANK,
  METHOD_EWALLET,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useDeliveryMethods from 'hooks/useDeliveryMethods';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { SET_EDIT_RECEIVER_INFORMATION, SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import { arrayUtils, stringUtils } from 'utils';
import CustomBanksAccordion from '../CustomBanksAccordion';
import CustomEwalletsAccordion from '../CustomEwalletsAccordion';
import FormInputBankEwalletRadio from '../FormInputBankEwalletRadio';

import { checkMaintenance } from 'helpers/maintenance';
import useReceiver from 'hooks/receiver/useReceiver';
import { Fade } from 'react-bootstrap';
import FormAddNewBankAccount from '../FormAddNewBankAccount';
import FormAddNewEwalletAccount from '../FormAddNewEwalletAccount';

const initialFormBank =
  RESET_EDIT_RECEIVER_INFORMATION_STORE.accountDetails.bank;
const initialFormEwallet =
  RESET_EDIT_RECEIVER_INFORMATION_STORE.accountDetails.ewallet;

const AccountDetails = ({
  countryConfig,
  handleOnToggleCancelPopup,
  handleOnToggleUpdatePopup,
  handleOnToggleCancelCurrentTabPopup,
}) => {
  const { t } = useLang();

  const { state, dispatch } = useStore();
  const { currentReceiver } = state;
  const { token } = useAuth();
  const deliveryMethods = useDeliveryMethods(countryConfig?.countryCode);

  const [information, setInformation] = useState(null);
  const [validation, setValidation] = useState(null);
  const [isBanksChecked, setBanksChecked] = useState(true);
  const [isOpenFormAddNewBank, setOpenFormAddNewBank] = useState(false);
  const [isOpenFormAddNewEwallet, setOpenFormAddNewEwallet] = useState(false);
  const [bankInformation, setBankInformation] = useState(initialFormBank);
  const [bankValidation, setBankValidation] = useState(initialFormBank);
  const [ewalletInformation, setEwalletInformation] =
    useState(initialFormEwallet);
  const [ewalletValidation, setEwalletValidation] =
    useState(initialFormEwallet);

  const { isReceiverOfBusinessType, isReceiverOfIndividualType } =
    useReceiver();

  const isShowDesc =
    arrayUtils.isArrayEmpty(currentReceiver?.banks) &&
    arrayUtils.isArrayEmpty(currentReceiver?.ewallets);

  useEffect(() => {
    if (currentReceiver) {
      setInformation(currentReceiver);
    }
  }, [currentReceiver]);

  useEffect(() => {
    if (
      deliveryMethods?.includes(METHOD_BANK) &&
      deliveryMethods?.includes(METHOD_EWALLET)
    ) {
      return;
    }

    if (deliveryMethods?.includes(METHOD_BANK)) {
      return;
    }

    if (deliveryMethods?.includes(METHOD_EWALLET)) {
      setBanksChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryMethods]);

  const handleOnToggleFormAddNewBank = () => {
    setBankInformation(initialFormBank);

    setOpenFormAddNewBank(!isOpenFormAddNewBank);

    setValidation(null);
  };

  const handleOnToggleFormAddNewEwallet = () => {
    setEwalletInformation(initialFormEwallet);

    setOpenFormAddNewEwallet(!isOpenFormAddNewEwallet);
  };

  const renderAccountTypeReference = {
    [true]: (
      <CustomBanksAccordion
        banks={information ? information?.banks : currentReceiver?.banks}
        countryConfig={countryConfig}
        information={information}
        validation={validation}
        setInformation={setInformation}
        bankInformation={bankInformation}
        setBankInformation={setBankInformation}
        bankValidation={bankValidation}
        setBankValidation={setBankValidation}
        handleOnToggleFormAddNewBank={handleOnToggleFormAddNewBank}
      />
    ),
    [false]: (
      <CustomEwalletsAccordion
        ewallets={
          information ? information?.ewallets : currentReceiver?.ewallets
        }
        countryConfig={countryConfig}
        information={information}
        setInformation={setInformation}
        ewalletInformation={ewalletInformation}
        setEwalletInformation={setEwalletInformation}
        ewalletValidation={ewalletValidation}
        setEwalletValidation={setEwalletValidation}
        handleOnToggleFormAddNewEwallet={handleOnToggleFormAddNewEwallet}
      />
    ),
  };

  const isBanksChanged = (information) => {
    const rootBanks = currentReceiver?.banks;
    const otherBanks = information?.banks;

    if (rootBanks?.length !== otherBanks?.length) return true;

    for (let i = 0; i < rootBanks?.length; i++) {
      if (
        otherBanks[i].bankName !== rootBanks[i].bankName ||
        otherBanks[i].accountName !== rootBanks[i].accountName ||
        otherBanks[i].accountNumber !== rootBanks[i].accountNumber ||
        otherBanks[i].swiftCode !== rootBanks[i].swiftCode ||
        otherBanks[i].branchCode !== rootBanks[i].branchCode
      )
        return true;
    }

    return false;
  };

  const isEwalletsChanged = (information) => {
    const rootEwallets = currentReceiver?.ewallets;
    const otherEwallets = information?.ewallets;

    if (rootEwallets?.length !== otherEwallets?.length) return true;

    for (let i = 0; i < rootEwallets?.length; i++) {
      if (
        otherEwallets[i].ewalletProviderName !==
          rootEwallets[i].ewalletProviderName ||
        otherEwallets[i].ewalletNumber !== rootEwallets[i].ewalletNumber ||
        checkEwalletExpiryDateChange(
          otherEwallets[i].expiryDate,
          rootEwallets[i].expiryDate
        )
      )
        return true;
    }

    return false;
  };

  const checkInformationChanged = (information) => {
    return isBanksChanged(information) || isEwalletsChanged(information);
  };

  const handleSave = async () => {
    if (isReceiverOfBusinessType && !information?.banks?.length) {
      setValidation(true);
      return;
    }

    if (checkInformationChanged(information)) {
      const isAllowSubmit =
        Object.values(bankValidation).every((value) => !value) &&
        Object.values(ewalletValidation).every((value) => !value);

      if (isAllowSubmit) {
        dispatch({ type: SET_SHOW_LOADING, payload: true });

        const banksDelete = getBanksDelete(
          currentReceiver?.banks,
          information?.banks
        );

        const banksAdd = getBanksAdd(information.banks, currentReceiver?.banks);

        const banksUpdate = getBanksUpdate(
          information.banks,
          currentReceiver?.banks
        );

        const ewalletsDelete = getEwalletsDelete(
          currentReceiver?.ewallets,
          information?.ewallets
        );

        const ewalletsAdd = getEwalletsAdd(
          information?.ewallets,
          currentReceiver?.ewallets
        );

        const ewalletsUpdate = getEwalletsUpdate(
          information?.ewallets,
          currentReceiver?.ewallets
        );

        try {
          await Promise.all([
            ...banksAdd.map((bank) =>
              addNewBankAccount(token, currentReceiver?.receiverId, bank)
            ),
            ...banksDelete.map((bank) =>
              deleteBankAccount(token, currentReceiver?.receiverId, bank.id)
            ),
            ...banksUpdate.map((bank) =>
              updateBankAccount(token, currentReceiver?.receiverId, bank)
            ),
            ...ewalletsAdd.map((ewallet) =>
              addNewEwalletAccount(token, currentReceiver?.receiverId, ewallet)
            ),
            ...ewalletsDelete.map((ewallet) =>
              deleteEwalletAccount(
                token,
                currentReceiver?.receiverId,
                ewallet.id
              )
            ),
            ...ewalletsUpdate.map((ewallet) =>
              updateEwalletAccount(token, currentReceiver?.receiverId, ewallet)
            ),
          ]);

          fetchReceiverInformation(token, currentReceiver?.receiverId);

          handleOnToggleUpdatePopup();

          dispatch({
            type: SET_EDIT_RECEIVER_INFORMATION,
            payload: RESET_EDIT_RECEIVER_INFORMATION_STORE,
          });

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        } catch (error) {
          checkMaintenance(error);
          console.error(error?.message);
          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      }
    } else {
      handleOnToggleCancelCurrentTabPopup();
    }
  };

  const renderDesc = () => {
    if (!isShowDesc) return null;

    if (isReceiverOfIndividualType)
      return <Desc>{t('add_receiver_account_details_2')}</Desc>;

    if (isReceiverOfBusinessType)
      return <Desc>{t('add_receiver_account_details_3')}</Desc>;

    return null;
  };

  const renderFormInputBankEwalletRadio = () => {
    if (isReceiverOfIndividualType)
      return (
        <FormInputBankEwalletRadio
          deliveryMethods={deliveryMethods}
          isBanksChecked={isBanksChecked}
          setBanksChecked={setBanksChecked}
          banksLength={
            information
              ? information?.banks?.length
              : currentReceiver?.banks?.length
          }
          ewalletsLength={
            information
              ? information?.ewallets?.length
              : currentReceiver?.ewallets?.length
          }
        />
      );

    return null;
  };

  return (
    <>
      {isOpenFormAddNewBank && (
        <FormAddNewBankAccount
          isOpen={isOpenFormAddNewBank}
          countryConfig={countryConfig}
          currentReceiver={currentReceiver}
          information={information}
          setInformation={setInformation}
          bankInformation={bankInformation}
          setBankInformation={setBankInformation}
          bankValidation={bankValidation}
          setBankValidation={setBankValidation}
          onClose={handleOnToggleFormAddNewBank}
        />
      )}

      {isOpenFormAddNewEwallet && (
        <FormAddNewEwalletAccount
          isOpen={isOpenFormAddNewEwallet}
          countryConfig={countryConfig}
          information={information}
          setInformation={setInformation}
          ewalletInformation={ewalletInformation}
          setEwalletInformation={setEwalletInformation}
          ewalletValidation={ewalletValidation}
          setEwalletValidation={setEwalletValidation}
          onClose={handleOnToggleFormAddNewEwallet}
        />
      )}

      <AccountDetailsStyled>
        <Title>{t('label_account_details')}</Title>
        {!deliveryMethods?.length && currentReceiver ? (
          <Desc style={{ color: 'red' }}>
            {stringUtils.replaceKeyword(t('add_receiver_account_details_1'), [
              {
                key: 'countryName',
                value: currentReceiver?.country?.name,
              },
            ])}
          </Desc>
        ) : (
          <>
            {renderDesc()}
            {renderFormInputBankEwalletRadio()}
            {renderAccountTypeReference[isBanksChecked]}
          </>
        )}
        <Fade in appear>
          <NextWrap>
            <ButtonCommon
              value={t('button_cancel')}
              onClick={handleOnToggleCancelPopup}
              styles={{
                margin: '0px',
                marginRight: '16px',
                width: '211px',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
            />
            <ButtonCommon
              value={t('button_save')}
              color="var(--c-primary)"
              background="var(--bg-primary)"
              styles={{
                margin: '0px',
                width: '211px',
              }}
              onClick={handleSave}
              isUseKeyDown
            />
          </NextWrap>
        </Fade>
      </AccountDetailsStyled>
    </>
  );
};

const AccountDetailsStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 19px;
  padding-inline: 0px;
  padding-bottom: 70px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 40px;
    padding-inline: 116px;
    padding-bottom: unset;
  }
`;

const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 24px;
  }
`;

const Desc = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7a8189;

  margin: 0;
  margin-bottom: 24px;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  background: var(--ds-c-white);
  z-index: 1;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #eef2f5;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    height: 40px;
    border-top: unset;
  }
`;

export default AccountDetails;
