import { api } from 'api';
import SendIcon from 'assets/icons/send-icon.svg';
import FormInputAmountValidation from 'components/CalculatorForm/components/FormInputAmountValidation';
import TotalPayForm from 'components/CalculatorForm/components/TotalPayForm';
import { fetchCurrentFee } from 'components/CalculatorForm/func';
import ButtonCommon from 'components/common/ButtonCommon';
import CustomSearchDropdown from 'components/common/CustomSearchDropdown';
import { fetchCountries, getItemsConfig } from 'components/common/func';
import {
  COUNTRIES,
  COUNTRY_NAME_DOMAIN_MAPPING,
  EN_LANG,
  I18N_REDIRECTED,
  METHOD_TRANSLATE_REFERECES,
  PAYOUT_METHOD_REFERENCE,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import {
  CALCULATOR_DESKTOP_WIDTH,
  CALCULATOR_FORM_AMOUNT_FIELDS,
  CALCULATOR_FORM_COUNTRY_FIELD,
  CALCULATOR_FORM_COUNTRY_NAME,
  CALCULATOR_FORM_PAYOUT_METHOD_FIELD,
  CALCULATOR_FORM_PAYOUT_METHOD_NAME,
  CALCULATOR_FORM_RECEIVER_GET_NAME,
  CALCULATOR_FORM_SEND_TO_NAME,
} from 'constants/calculator-form';
import { format } from 'helpers';
import Cookies from 'helpers/cookies';
import { checkMaintenance } from 'helpers/maintenance';
import numberHelper from 'helpers/numberHelper';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useTheme from 'hooks/useTheme';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { arrayUtils } from 'utils';

const initialCalculatorForm = {
  [CALCULATOR_FORM_COUNTRY_NAME]: null,
  [CALCULATOR_FORM_PAYOUT_METHOD_NAME]: null,
  [CALCULATOR_FORM_SEND_TO_NAME]: 0,
  [CALCULATOR_FORM_RECEIVER_GET_NAME]: 0,
  currentCurrencySelected: null,
  feeAmount: 0,
};

const CalculatorFormPage = () => {
  const { t, locate } = useLang();
  const query = useQuery();

  const [countryConfig, setCountryConfig] = useState(null);
  const { receiveLimits, dmList } = countryConfig || {};
  const { isHHMTTheme } = useTheme();

  const [information, setInformation] = useState(initialCalculatorForm);
  const [validation, setValidation] = useState(initialCalculatorForm);
  const [countries, setCountries] = useState(null);
  const [payoutMethods, setPayoutMethods] = useState(null);
  const [rate, setRate] = useState(0);

  const { countryCode } = countryConfig || {};
  const { code: dmCode } =
    information[CALCULATOR_FORM_PAYOUT_METHOD_NAME] || {};
  const sendLimitConfig =
    countryConfig &&
    getItemsConfig(
      countryConfig?.sendLimits,
      countryConfig?.countryCode,
      null,
      true,
      null
    );
  const maxSendLimit = sendLimitConfig?.max || 30000;
  const { currencyCode } = information['currentCurrencySelected'] || {};

  const fetchCountryConfig = async (countryCode) => {
    try {
      const { data } = await api.getCountryConfig(countryCode);

      return data;
    } catch (error) {
      checkMaintenance(error);
      console.error(error?.message);
    }
  };
  const fetchRate = async ({ countryCode, currencyCode, dmCode }) => {
    try {
      const { data } = await api.lookupRate(countryCode, dmCode, currencyCode);

      return data?.rateValue;
    } catch (error) {
      checkMaintenance(error);
      console.error(error?.message);
    }
  };
  const getRate = async (countryCode, dmCode, currencyCode) => {
    const rate = await fetchRate({
      countryCode,
      dmCode,
      currencyCode,
    });
    setRate(rate);

    return rate;
  };

  const getCountrySelected = (countryName, countries) => {
    return countries?.find((c) => c.name === countryName);
  };

  const getPayoutMethodSelected = (dmCode, dmList) => {
    return dmList?.find((c) => c.code === dmCode);
  };
  const getPayoutMethods = (dmList) => {
    return dmList?.map((dm) => ({
      ...dm,
      name: PAYOUT_METHOD_REFERENCE[dm.code],
      label: METHOD_TRANSLATE_REFERECES[PAYOUT_METHOD_REFERENCE[dm.code]],
    }));
  };

  const getCurrencies = (receiveLimits, dmCode) => {
    receiveLimits = receiveLimits?.filter((rl) => rl.dmCode === dmCode);

    receiveLimits = arrayUtils.removeDuplicateInArrayObject(
      receiveLimits,
      'currencyCode'
    );

    return [receiveLimits, receiveLimits];
  };

  const getCalculatorFormInitial = async (countryCode) => {
    let countries;

    fetchCountries((data) => {
      countries = data;
      setCountries(data);
    });

    const currentCountry = COUNTRY_NAME_DOMAIN_MAPPING.find(
      (c) => c.countryCode === countryCode
    );

    const countryConfig = await fetchCountryConfig(currentCountry.countryCode);
    setCountryConfig(countryConfig);
    const { dmList, receiveLimits } = countryConfig || {};

    const currentRate = await getRate(
      currentCountry.countryCode,
      currentCountry.dmCode,
      currentCountry.currencyCode
    );

    const payoutMethods = getPayoutMethods(dmList);
    setPayoutMethods(payoutMethods);

    const sendAmount = 100;
    let receiveAmount = 100 * currentRate;

    if (currentCountry.currencyCode === 'VND') {
      receiveAmount = numberHelper.roundVndAmount(receiveAmount);
    }

    const newInformation = {
      ...initialCalculatorForm,
      [CALCULATOR_FORM_SEND_TO_NAME]: sendAmount,
      [CALCULATOR_FORM_RECEIVER_GET_NAME]: receiveAmount,
      [CALCULATOR_FORM_COUNTRY_NAME]: getCountrySelected(
        currentCountry.countryName,
        countries
      ),
      [CALCULATOR_FORM_PAYOUT_METHOD_NAME]: getPayoutMethodSelected(
        currentCountry.dmCode,
        payoutMethods
      ),
      currentCurrencySelected: getCurrencies(
        receiveLimits,
        currentCountry.dmCode
      )[0][0],
    };

    setInformation(newInformation);
  };

  useEffect(() => {
    const sendAmount = Number(information[CALCULATOR_FORM_SEND_TO_NAME] || 0);
    const receiverAmount = Number(
      information[CALCULATOR_FORM_RECEIVER_GET_NAME] || 0
    );

    document.getElementsByName(CALCULATOR_FORM_SEND_TO_NAME)[0].value =
      sendAmount ? format.toAmountStr(sendAmount) : sendAmount;
    document.getElementsByName(CALCULATOR_FORM_RECEIVER_GET_NAME)[0].value =
      receiverAmount ? format.toAmountStr(receiverAmount) : receiverAmount;
  }, [information]);

  useEffect(() => {
    const countryCode = query.get('countryCode');

    getCalculatorFormInitial(countryCode || (isHHMTTheme ? 'VNM' : 'PHL'));
  }, [query]);

  useEffect(() => {
    const lang = query.get('lang') || EN_LANG;

    i18next.changeLanguage(lang);

    Cookies.set(I18N_REDIRECTED, lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (dmCode && currencyCode) {
      getRate(countryCode, dmCode, currencyCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyCode, dmCode]);

  const handleCountryOnChange = async (country) => {
    const countryConfig = await fetchCountryConfig(country?.code);
    setCountryConfig(countryConfig);

    const { dmList, countryCode, receiveLimits } = countryConfig || {};
    const countryName = COUNTRIES.find((c) => c.value === countryCode)?.label;

    const payoutMethods = getPayoutMethods(dmList);
    setPayoutMethods(payoutMethods);

    const dmCode = payoutMethods[0]?.code;

    let newInformation = {
      ...information,
      [CALCULATOR_FORM_COUNTRY_NAME]: getCountrySelected(
        countryName,
        countries
      ),
      [CALCULATOR_FORM_PAYOUT_METHOD_NAME]: getPayoutMethodSelected(
        dmCode,
        payoutMethods
      ),
      currentCurrencySelected: getCurrencies(receiveLimits, dmCode)[0][0],
    };

    await fetchCurrentFee(
      newInformation,
      setInformation,
      countryCode,
      maxSendLimit
    );

    await getRate(
      countryCode,
      dmCode,
      getCurrencies(receiveLimits, dmCode)[0][0]?.currencyCode
    );
  };

  const handlePayoutMethodOnChange = async (payout) => {
    const payoutMethods = getPayoutMethods(dmList);
    setPayoutMethods(payoutMethods);

    const dmCode = payout?.code;

    let newInformation = {
      ...information,
      [CALCULATOR_FORM_PAYOUT_METHOD_NAME]: payout,
      currentCurrencySelected: getCurrencies(receiveLimits, dmCode)[0][0],
    };

    await fetchCurrentFee(
      newInformation,
      setInformation,
      countryCode,
      maxSendLimit
    );

    await getRate(
      countryCode,
      dmCode,
      getCurrencies(receiveLimits, dmCode)[0][0]?.currencyCode
    );
  };

  return (
    <CalculatorFormStyled>
      <FormStyled>
        <FormHeader>
          <HeaderLabel>{t('label_today_exchange_rate')}</HeaderLabel>
          <HeaderRate>
            <RateValue>1 AUD =</RateValue>&nbsp;
            <RateValue>
              <span>{`${format.toAmountStr2(rate)}`}</span>&nbsp;
              {`${currencyCode || ''}`}
            </RateValue>
          </HeaderRate>
        </FormHeader>
        <FormBody>
          <BodySection>
            <CustomSearchDropdown
              field={CALCULATOR_FORM_COUNTRY_FIELD}
              data={countries}
              keyFind="name"
              information={information}
              setInformation={setInformation}
              validation={validation}
              setValidation={setValidation}
              onChange={handleCountryOnChange}
              styles={{
                width: 'calc(50% - 8px)',
                marginBottom: 0,
              }}
              toggleStyles={{
                fontFamily: 'var(--ff-primary)',
                fontSize: '16px',
                height: '50px',
                paddingBlock: 0,
                outline: '1px solid var(--ds-c-grey-dark) !important',
              }}
              inputStyles={{
                transform: 'translate(0px, 52px) !important',
              }}
            />
            <CustomSearchDropdown
              field={CALCULATOR_FORM_PAYOUT_METHOD_FIELD}
              data={payoutMethods}
              keyFind="name"
              keyTranslate="label"
              information={information}
              setInformation={setInformation}
              validation={validation}
              setValidation={setValidation}
              onChange={handlePayoutMethodOnChange}
              styles={{
                width: 'calc(50% - 8px)',
                marginBottom: 0,
              }}
              toggleStyles={{
                fontFamily: 'var(--ff-primary)',
                fontSize: '16px',
                height: '50px',
                paddingBlock: 0,
                outline: '1px solid var(--ds-c-grey-dark) !important',
              }}
              inputStyles={{
                height: '0px',
                transform: 'translate(0px, 8px) !important',
              }}
              isSearchHidden={true}
            />
          </BodySection>
          <BodySection style={{ display: 'block' }}>
            {CALCULATOR_FORM_AMOUNT_FIELDS.map((field) => (
              <FormInputAmountValidation
                key={field.name}
                field={field}
                countryConfig={countryConfig}
                receiveLimits={
                  countryConfig && getCurrencies(receiveLimits, dmCode)[0]
                }
                information={information}
                setInformation={setInformation}
                validation={validation}
                setValidation={setValidation}
                currentRateValue={rate}
                currentCurrencySelected={information['currentCurrencySelected']}
              />
            ))}
          </BodySection>
          <BodySection style={{ marginTop: '0px', marginBottom: '8px' }}>
            <TotalPayForm
              information={information}
              countryConfig={countryConfig}
            />
          </BodySection>
        </FormBody>
        <FormFooter>
          <ButtonCommon
            value={t('button_send_now_2')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              paddingInline: 0,
            }}
            iconSrc={SendIcon}
            onClick={() => {
              Cookies.remove(MAKE_PAYMENT_KEY);
              window.parent.location.href = `${TRANSACTION_ADD_PREFIX}?lang=${locate}`;
            }}
          />
        </FormFooter>
      </FormStyled>
    </CalculatorFormStyled>
  );
};

const CalculatorFormStyled = styled.div`
  width: 100%;
  border-radius: 0px;

  @media screen and (width: ${CALCULATOR_DESKTOP_WIDTH}px) {
    width: 376px;
    border-radius: 24px;
  }
`;

const FormStyled = styled.div``;
const FormHeader = styled.div`
  font-family: var(--ff-primary);
  height: auto;
  padding-top: 5.5px;
  padding-bottom: 5.5px;
  padding-right: 0;
  display: block;

  background-color: var(--c-tertiary);
  border-radius: 0;

  @media screen and (width: ${CALCULATOR_DESKTOP_WIDTH}px) {
    border-radius: 24px 24px 0 0;
    background-color: #212121;
  }
`;
const HeaderLabel = styled.label`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-weight: 500;
  color: var(--c-calculator-rate2);
  text-align: center;
  width: 100%;
  color: var(--c-calculator-rate2);

  @media screen and (width: ${CALCULATOR_DESKTOP_WIDTH}px) {
    color: #fff;
  }
`;
const HeaderRate = styled.div`
  justify-content: center;
  align-items: center;
  padding-top: 0;
  display: flex;
`;
const RateValue = styled.p`
  color: var(--c-calculator-rate2);
  margin-bottom: 0;
  font-family: var(--ff-primary);
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  color: var(--c-calculator-rate2);

  & span {
    color: var(--c-calculator-rate3);
  }

  @media screen and (width: ${CALCULATOR_DESKTOP_WIDTH}px) {
    color: #fff;

    & span {
      color: var(--c-calculator-rate);
    }
  }
`;

const FormBody = styled.div`
  padding: 16px 24px;

  @media screen and (width: ${CALCULATOR_DESKTOP_WIDTH}px) {
    padding: 0px 16px 2px 16px;
  }
`;
const BodySection = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-top: 0;
  display: flex;
`;

const FormFooter = styled.div`
  border: none;
  padding: 0;
  display: flex;
  padding: 0 16px 16px 16px;
  justify-content: center;

  @media screen and (width: ${CALCULATOR_DESKTOP_WIDTH}px) {
    justify-content: flex-end;
  }
`;

export default CalculatorFormPage;
